'use strict';

var helper = require('../../helper');

var paymentData;

/**
 * Process Apple Pay on the Checkout page
 */
function processCheckoutFlow() {
    document.querySelector(('#braintreeApplePayNonce')).value = paymentData.nonce;

    // Updating Storefront Billing Form data with ApplePay Billing
    var storeFrontBillingData = helper.getUpdatedStoreFrontBillingData(paymentData.billingAddress);

    helper.updateBillingFormValues(storeFrontBillingData);
}

/**
 * Inits Apple Pay checkout components
 * @param {Object} data Apple Pay payment data
 */
function init(data) {
    paymentData = data;

    processCheckoutFlow();
}

module.exports = {
    init
};
