'use strict';

var ErrorHandlingBaseModel = require('./errorHandlingBaseModel');

/**
 * Error Handling Checkout model constructor
 * @param {Object} errorMessages object with error messages
 */
function ErrorHandlingCheckoutModel(errorMessages) {
    ErrorHandlingBaseModel.call(this, errorMessages);

    this.$topErrorMessageText = document.querySelector('.error-message-text');
    this.$topErrorMessageWrapper = document.querySelector('.error-message');

    this.errorMessages = errorMessages;
}

/**
 * ES5 inheritance
 */
ErrorHandlingCheckoutModel.prototype = Object.create(ErrorHandlingBaseModel.prototype);

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCheckoutModel.prototype.hideError = function () {
    this.$topErrorMessageWrapper.style.display = 'none';
    this.$topErrorMessageText.innerHTML = '';
};

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCheckoutModel.prototype.createErrorNotification = function (message) {
    this.$topErrorMessageText.textContent = '';
    this.$topErrorMessageText.append(message);

    this.$topErrorMessageWrapper.style.display = 'block';
    window.scrollTo(0, 0);
};

/**
 * Show "Billing Address is not Supported" error
 */
ErrorHandlingCheckoutModel.prototype.showBillingAddressIsNotSupported = function () {
    this.showErrorByMessage(this.errorMessages.PAYPAL_BILLING_ADDRESS_NOT_SUPPORTED);
};

/**
 * Show "Zero Amount" on the Checkout Page error
 */
ErrorHandlingCheckoutModel.prototype.showZeroAmountOnCheckout = function () {
    this.showErrorByMessage(this.errorMessages.CUSTOM_PAYPAL_ZERO_AMOUNT_ERROR);
};


module.exports = ErrorHandlingCheckoutModel;
