'use strict';

var helper = require('../../helper');
var payPalSessionAccountHelper = require('../helpers/payPalSessionAccountHelper');

/**
 * Function which remove session nonce and related to the session account data
 */
function removeSessionNonce() {
    var $braintreePayPalNonce = document.querySelector('#braintreePayPalNonce');
    var $braintreePaypalAccount = document.querySelector('#braintreePaypalAccount');

    var sessionOption = helper.getSessionAccountOption({
        querySelector: '#braintreePaypalAccountsList',
        id: 'braintreePaypalAccount'
    });

    if (sessionOption) {
        $braintreePayPalNonce.value = '';
        $braintreePaypalAccount.selected = false;

        sessionOption.classList.add('used-paypal-account-hide');
        sessionOption.classList.remove('used-paypal-account');
        sessionOption.value = '';
        sessionOption.text = '';
        sessionOption.setAttribute('data-session-account', false);
        sessionOption.setAttribute('data-save', false);

        // Handles a behavior inside PayPal tab, when session PayPal account was deleted
        payPalSessionAccountHelper.handlePaypalTabBehavior();
    }
}

module.exports = {
    removeSessionNonce
};
