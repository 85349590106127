'use strict';

var helper = require('../../helper');
var venmoAccount = require('./venmoAccount');
var venmoCheckout = require('./venmoCheckout');
var venmoConstants = require('../constants/venmoConstants');
var venmoProcessingHelper = require('../helpers/venmoProcessingHelper');
var venmoErrorHandler = require('../errorhandler/venmoErrorHandler');

/**
 * Process Venmo flow for Account/Billing Page flows
 * @param {Object} venmoData Venmo data received from tokenize
 */
function processVenmo(venmoData) {
    venmoErrorHandler.clearVenmoError();

    var venmoFlowName = venmoProcessingHelper.getVenmoFlowName();

    switch (venmoFlowName) {
        case venmoConstants.FLOW_NAME_ACCOUNT:

            venmoAccount.storeNewVenmoAccount(venmoData);
            break;

        case venmoConstants.FLOW_NAME_BILLING_CHECKOUT:
            helper.removeActiveSessionPayment();
            venmoCheckout.processCheckoutFlow(venmoData);
            break;

        default:
            break;
    }
}

module.exports = {
    processVenmo
};
