'use strict';

var helper = require('../../helper');
var venmoHelper = require('../helpers/venmoHelper');

var $venmoButton = document.querySelector('.js_braintree_venmo_button');
var $braintreeVenmoSessionAccount = document.getElementById('braintreeVenmoAccount');
var $venmoAccountBlock = document.querySelector('.js_braintree_used_venmo_account');
var $braintreeVenmoUserId = document.querySelector('#braintreeVenmoUserId');

/**
 * Handles showing Venmo session account
 */
function showVenmoAccount() {
    var btVenmoAccountsList = document.querySelector('#braintreeVenmoAccountsList');

    if (!btVenmoAccountsList) {
        $venmoAccountBlock.classList.remove('used-venmo-account-hide');
        $braintreeVenmoSessionAccount.options[0].text = $braintreeVenmoUserId.value;
    }

    $venmoButton.style.display = 'none';

    helper.continueButtonToggle(false);
}

/**
 * Removes method was used and change appearance of Venmo tab
 */
function removeSessionNonce() {
    var defaultOption = helper.getOptionByDataDefault('#braintreeVenmoAccountsList');
    var braintreeVenmoNonce = document.querySelector('#braintreeVenmoNonce');
    var braintreeVenmoBilling = document.querySelector('#braintreeVenmoBilling');

    if (defaultOption) {
        defaultOption.selected = true;

        venmoHelper.venmoContentTabHandler();
    } else {
        $venmoAccountBlock.classList.remove('used-venmo-account');
        $venmoAccountBlock.classList.add('used-venmo-account-hide');
        $venmoButton.style.display = 'block';
    }

    braintreeVenmoNonce.value = '';
    braintreeVenmoBilling.value = '';
}

module.exports = {
    showVenmoAccount,
    removeSessionNonce
};
