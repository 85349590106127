'use strict';

var srcHelper = require('../helpers/srcHelper');
var loaderInstance = require('../../loaderHelper');

// Models
var SrcCheckoutModel = require('../models/srcCheckoutModel');
var ErrorHandlingCheckoutModel = require('../errorhandler/errorHandlingCheckoutModel');

var $braintreeBillingPageSrcButton = document.querySelector('.braintree-billingpage-src-button');

/**
 * Inits SRC account functionality on the Checkout Page
 * @param {Promise} clientInstancePromise client instance promise
 */
function init(clientInstancePromise) {
    var $braintreeSrcLoader = document.querySelector('.braintreeSrcLoader');
    var $totalAmount = document.querySelector('.grand-total-sum');
    var srcLoader = loaderInstance($braintreeSrcLoader);
    var srcButtonConfigurations = srcHelper.getBraintreeSRCButtonConfigs();
    var $braintreeSrcDeviceDataInput = document.querySelector('input[name=braintreeSrcDeviceDataInput]');
    var errorHandlingCheckoutModel = new ErrorHandlingCheckoutModel(srcButtonConfigurations.messages);


    var SrcCheckoutModelInstance = new SrcCheckoutModel(
        clientInstancePromise,
        srcButtonConfigurations,
        $braintreeSrcDeviceDataInput,
        srcLoader,
        errorHandlingCheckoutModel
    );

    // Hides errors on the Checkout/Billing Page
    $braintreeBillingPageSrcButton.addEventListener('click', errorHandlingCheckoutModel.hideError);

    SrcCheckoutModelInstance.initSrcButton();

    // Tracks cases when total amount equal zero and throw an error
    srcHelper.zeroAmountSrcButtonBehavior($totalAmount, errorHandlingCheckoutModel);

    // Click SRC tab if session payment ID is SRC and session account value is filled
    // in case of checkout through saved SRC card session payment id will be SRC
    // but session account value will be empty
    var sessionPaymentMethodId = srcButtonConfigurations.sessionPaymentMethodId;
    var isSrcSessionAccountExist = document.querySelector('#sessionSrcAccount').value !== '';

    if (sessionPaymentMethodId === 'SRC' && isSrcSessionAccountExist) {
        document.querySelector('.src-tab').click();
    }
}

module.exports = {
    init
};
