'use strict';

var creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');

/**
 * Clears Credit Card fields of session account
 */
function clearCreditCardFields() {
    var creditCardFieldsArray = creditCardFormFieldHelper.getCCFields().asArray;

    creditCardFieldsArray.forEach(function (el) {
        el.value = '';
    });
}

/**
 * Clears Credit Card nonce of session account
 */
function clearCreditCardNonceField() {
    document.querySelector('#braintreeCreditCardNonce').value = '';
}

module.exports = {
    clearCreditCardFields,
    clearCreditCardNonceField
};
