'use strict';

/* global Promise braintree $ */

var applePayErrorMessages = require('../constants/errorMessages');

var braintreeApplePaySDK = require('./braintreeApplePaySDK');
var braintreeGeneral = require('../../braintreeGeneral');

// Global variables
// Is Froud tools enabled
var isFtEnabled;
var btClientInstancePromise;
var apInstance;
var amount;

/**
 * Init braintree Apple Pay model
 * @param {Promise} braintreeClientInstancePromise Braintreee client instance promise
 * @param {boolean} isFraudToolsEnabled Is fraud tools enabled value
 */
function init(braintreeClientInstancePromise, isFraudToolsEnabled) {
    braintreeApplePaySDK.init(braintreeClientInstancePromise);

    btClientInstancePromise = braintreeClientInstancePromise;
    isFtEnabled = isFraudToolsEnabled;
}

/**
 * Returns fraud data from dataCollector payload
 * @returns {Object} Payload with fraud data
 */
function collectFraudData() {
    var response;

    if (isFtEnabled) {
        response = braintreeGeneral.collectFraudData(btClientInstancePromise);
    } else {
        response = Promise.resolve({
            customMessage: applePayErrorMessages.FRAUD_DATA_CELLECTION_ISNOT_ENABLED
        });
    }

    return response;
}

/**
 * Preparing Apple Pay payment
 * @param {Object} ApplePaySession Apple Pay session
 * @returns {Promise} Promise with 'canMakePayments' boolean value
 */
function initApplePay(ApplePaySession) {
    return braintreeApplePaySDK.createApplePayPayment()
        .then(function (applePayInstance) {
            apInstance = applePayInstance;

            return ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier);
        });
}

/**
 * Merges a payment request with Braintree defaults to return an {external:ApplePayPaymentRequest}
 * @param {Object} applePayConfigs Apple Pay config object
 * @returns {Object} Payment request object
 */
function createPaymentDataRequest(applePayConfigs) {
    return braintreeApplePaySDK.createPaymentDataRequest(apInstance, applePayConfigs, amount);
}

/**
 * Validates merchant website, as required by ApplePaySession before payment can be authorized
 * @param {Object} sessionEvent Apple Pay session event
 * @param {Object} applePayConfigs Apple Pay config object
 * @returns {Promise} Promise with merchant session
 */
function onValidateMerchant(sessionEvent, applePayConfigs) {
    return braintreeApplePaySDK.performValidation(apInstance, sessionEvent, applePayConfigs);
}

/**
 * Tokenizes an Apple Pay payment
 * @param {Object} sessionEvent Apple Pay session event
 * @returns {Promise} Promise with tokenized payload
 */
function onPaymentAuthorized(sessionEvent) {
    return braintreeApplePaySDK.tokenize(apInstance, sessionEvent);
}

/**
 * Update order amount
 * @param {Object} applePayConfigs Apple Pay config object
 */
function updateApplePayAmount(applePayConfigs) {
    $.ajax({
        url: applePayConfigs.getOrderInfoUrl,
        async: false,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            amount = data.amount;
        },
        error: function () {
            window.location.reload();
        }
    });
}


module.exports = {
    init,
    collectFraudData,
    initApplePay,
    createPaymentDataRequest,
    onValidateMerchant,
    onPaymentAuthorized,
    updateApplePayAmount
};
