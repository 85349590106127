'use strict';

var helper = require('../../helper');

/**
 * Create shipping address data
 * @param {Object} payloadDetails BT payload details
 * @returns {Object} Shipping address
 */
function createShippingAddressData(payloadDetails) {
    var shippingAddressPayPalData = payloadDetails.shippingAddress;
    var shippingAddressData = {
        line1: shippingAddressPayPalData.line1,
        streetAddress: shippingAddressPayPalData.line1,
        city: shippingAddressPayPalData.city,
        locality: shippingAddressPayPalData.city,
        state: shippingAddressPayPalData.state,
        region: shippingAddressPayPalData.state,
        countryCode: shippingAddressPayPalData.countryCode,
        countryCodeAlpha2: shippingAddressPayPalData.countryCode,
        postalCode: decodeURIComponent(shippingAddressPayPalData.postalCode),
        email: payloadDetails.email,
        phone: payloadDetails.phone
    };

    if (!shippingAddressPayPalData.recipientName) {
        shippingAddressData.firstName = payloadDetails.firstName;
        shippingAddressData.lastName = payloadDetails.lastName;
        shippingAddressData.recipientName = payloadDetails.firstName + ' ' + payloadDetails.lastName;
    } else {
        shippingAddressData.recipientName = shippingAddressPayPalData.recipientName;
    }

    return shippingAddressData;
}

/**
 * Append Shipping data as a String to the FormData
 * @param {FormData} billingFromData Billing FormData
 * @param {Object} shippingData Shipping data
 */
function appendShippingDataAsStringToFormData(billingFromData, shippingData) {
    billingFromData.append('braintreePaypalShippingAddress', JSON.stringify(shippingData));
}

/**
 * Creates customer form with email to submit it to CheckoutServices-SubmitCustomer endpoint
 * @param {string} email Email from payment method's response
 * @returns {Object} Customer form data
 */
function createCustomerFormData(email) {
    var data = {
        email: email
    };

    var csrfToken = document.querySelector('.braintree-cart-paypal-buttons-wrap #csrf_token');
    var checkoutCustomerFormFields = document.querySelector('.braintree-cart-paypal-buttons-wrap').getAttribute('data-checkout-customer-form-fields');
    var customerFormData = helper.createPaymentFormData(checkoutCustomerFormFields, data);

    customerFormData.append(csrfToken.name, csrfToken.value);

    return customerFormData;
}

/**
 * Submits customer form with email to CheckoutServices-SubmitCustomer endpoint
 * Call is triggered only in case if email in basket is empty (guest first checkout from cart)
 * @param {string} email Email from payment method's response
 * @returns {Ajax} Ajax call to CheckoutServices-SubmitCustomer endpoint
 */
function submitCustomerForm(email) {
    var paypalButtonsWrapper = document.querySelector('.braintree-cart-paypal-buttons-wrap');
    var isCustomerEmailEmpty = JSON.parse(paypalButtonsWrapper.getAttribute('data-is-customer-email-empty'));
    // Could be true only in case if email in basket is empty (first guest checkout from cart)
    if (isCustomerEmailEmpty) {
        var submitCustomerUrl = paypalButtonsWrapper.getAttribute('data-submit-customer-url');
        var customerFormData = createCustomerFormData(email);

        return $.ajax({
            type: 'POST',
            url: submitCustomerUrl,
            data: customerFormData,
            contentType: false,
            processData: false
        });
    }
}

module.exports = {
    createShippingAddressData,
    appendShippingDataAsStringToFormData,
    submitCustomerForm
};
