'use strict';

var PayPalBaseModel = require('./payPalBaseModel');
var helper = require('../../helper');
var payPalAccountModelHelper = require('../helpers/payPalAccountModelHelper');
var billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');

function PayPalAccountModel(
    errorHandlingModel,
    btClientInstancePromise,
    payPalButtonSelector,
    payPalConfigurations,
    isVaultMode,
    requestBillingAgreement,
    isFraudToolsEnabled,
    $csrfToken,
    addPayPalAccountUrl,
    $loaderContainer
) {
    PayPalBaseModel.call(
        this,
        errorHandlingModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        isVaultMode,
        requestBillingAgreement,
        isFraudToolsEnabled,
        $loaderContainer
    );

    this.$csrfToken = $csrfToken;
    this.addPayPalAccountUrl = addPayPalAccountUrl;
}

// ES5 inheritance
PayPalAccountModel.prototype = Object.create(PayPalBaseModel.prototype);

/**
 * For whole description open "payPalBaseModel".
 * We don't generate and send fraud data from Account. That's why "generateFraudData" isn't used
 * @param {Object} payload Buyer data from PayPal (like address, nonce, etc.)
 */
PayPalAccountModel.prototype.onApprovePaymentCallback = function (payload) {
    // Calling parrent method to proceed with PayPal payload validation
    var baseResponse = PayPalBaseModel.prototype.onApprovePaymentCallback.call(this, payload);

    // Error will be handled on BaseModel level
    if (baseResponse && baseResponse.error) {
        return;
    }

    this.loader.show();

    var payPalAccountModelInstance = this;
    var btDetails = payload.details;
    var nonce = payload.nonce;
    var email = payload.details.email;
    var $payPalButton = document.querySelector(this.payPalButtonSelector);
    var paypalAccountFormFields = $payPalButton.getAttribute('data-paypal-account-form-fields');
    var payPalBraintreeAddressData;
    var paypalAccountFormData;

    // Append Shippig/Billing data to FormData
    payPalBraintreeAddressData = billingFormHelper.createBillingAddressData(btDetails);
    paypalAccountFormData = helper.createPaymentFormData(paypalAccountFormFields, {
        nonce: nonce,
        email: email,
        addresses: JSON.stringify(payPalBraintreeAddressData),
        shippingAddress: JSON.stringify(payload.details.shippingAddress)
    });
    // Apped CSRF token
    billingFormHelper.appendCsrfTokenToFormData(paypalAccountFormData, this.$csrfToken);

    payPalAccountModelHelper.savePayPalAccount(this.addPayPalAccountUrl, paypalAccountFormData)
        .done(function (paymentData) {
            var renderAccountsUrl = paymentData.renderAccountsUrl;

            payPalAccountModelHelper.hidePaypalAccountBtn();

            payPalAccountModelHelper.renderPayPalAccounts(renderAccountsUrl)
                .done(function (accountListTemplate) {
                    payPalAccountModelHelper.addPayPalAccountsToTheTemplate(accountListTemplate);
                    payPalAccountModelHelper.initRemovePayPalAccountButtonsListners();
                    payPalAccountModelHelper.throwCartUpade();

                    payPalAccountModelInstance.loader.hide();
                });
        })
        .fail(function (errorPayload) {
            var error = errorPayload.responseJSON.error;

            payPalAccountModelInstance.errorHandlingModel.showErrorByMessage(error);
            payPalAccountModelInstance.loader.hide();
        });
};

/**
 * Triggers when buyer clicked on the PayPal button and PayPal modal started loading
 * @returns {Object} Object which will be passed directly into Braintree PayPal SDK as an order data
 */
PayPalAccountModel.prototype.onOrderCreateCallback = function () {
    var payPalButtonConfigs = this.payPalConfigurations;
    var orderCreateParams = payPalButtonConfigs.options;

    // Hide custom error on buyer PayPal button click.
    this.errorHandlingModel.hideError();

    return {
        flow: orderCreateParams.flow,
        enableShippingAddress: orderCreateParams.enableShippingAddress,
        displayName: orderCreateParams.displayName,
        billingAgreementDescription: orderCreateParams.billingAgreementDescription
    };
};

module.exports = PayPalAccountModel;
