'use strict';

var billingFormHelper = require('./payPalBillingFormHelperGlobal');

/**
 * Remove product from Cart
 * @param {string} removeFromCartUrl Remove from Cart URL
 * @param {Object} productRepresentationObj Object with represent the product to remove from Cart
 */
function removeProductFromCart(removeFromCartUrl, productRepresentationObj) {
    $.ajax({
        url: billingFormHelper.appendToUrl(removeFromCartUrl, productRepresentationObj),
        type: 'get',
        dataType: 'json'
    });
}

module.exports = {
    removeProductFromCart
};
