'use strict';

// Helpers
var googlePayHelper = require('../helpers/googlePayHelper');
var loaderInstance = require('../../loaderHelper');
var googlePayPaymentProcessingHelper = require('../helpers/googlePayPaymentProcessingHelper');
var helper = require('../../helper');

// Components
var googlePayCart = require('../components/googlePayCart');
var googlePayCheckout = require('../components/googlePayCheckout');
var googlePaySessionAccount = require('../components/googlePaySessionAccount');

var googlePayPaymentProcessingConstants = require('../constants/googlePayPaymentProcessingConstants');

// Global varibales
var googlePayModel;
var paymentsClient;
var googlePayConfig;
var errHandlerModel;

var $googlepayButton = document.querySelector('.js_braintree_googlepay_button');
var $braintreeGooglePayLoader = document.querySelector('.braintreeGooglepayLoader');
var $continueButton = document.querySelector('button.submit-payment');

var loader;

/**
 * Functionality over tokenized payment
 * @param {Objet} data data object
 * @param {Object} result data object
 */
function onTokenizePayment(data, result) {
    // General functionality for Cart and Billing Page
    var googlepayNonce = result.nonce;
    var googlepayPaymentType = result.type;
    var gpCardDescription = result.details.cardType + ' ' + result.details.lastFour;
    var billingAddressData = googlePayPaymentProcessingHelper.createGooglepayBillingAddressData(data);
    var googlePayFlow = googlePayPaymentProcessingHelper.getGooglePayFlowId();

    helper.removeActiveSessionPayment();

    switch (googlePayFlow) {
        case googlePayPaymentProcessingConstants.FLOW__CART_NAME:
            var shippingAddressData = googlePayPaymentProcessingHelper.createGooglepayShippingAddressData(data.shippingAddress);

            googlePayCart.init(billingAddressData, shippingAddressData, googlepayNonce, googlepayPaymentType, gpCardDescription, errHandlerModel);
            break;
        case googlePayPaymentProcessingConstants.FLOW_CHECKOUT_NAME:
            googlePayCheckout.init(googlepayNonce, gpCardDescription, googlepayPaymentType, billingAddressData);
            googlePaySessionAccount.showGooglepayAccount(gpCardDescription);
            $continueButton.click();
            break;
        default:
            break;
    }
}

/**
 * Call back function to be resolved when on Google Pay payment button is clicked
 * @returns {void}
 */
function onGooglePaymentButtonClicked() { // eslint-disable-line no-inner-declarations
    var options = googlePayConfig.options;
    var amount = options.amount;

    errHandlerModel.hideError();

    try {
        if (amount === 0) {
            errHandlerModel.showOrderTotalError();
        }

        loader.show();

        // Updates order amount for paymentDataRequest
        googlePayModel.googlePayUpdateAmount();

        // Create a configuration object for use in the loadPaymentData method.
        var paymentDataRequest = googlePayModel.createPaymentDataRequest(options);

        // Update card payment methods to require billing address
        var cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
        cardPaymentMethod.parameters.billingAddressRequired = true;
        cardPaymentMethod.parameters.billingAddressParameters = {
            format: 'FULL',
            phoneNumberRequired: true
        };

        return paymentsClient.loadPaymentData(paymentDataRequest)
            .then(function (paymentData) {
                googlePayModel.parseResponse(paymentData)
                    .then(function (tokenizePayload) {
                        onTokenizePayment(paymentData, tokenizePayload);
                    });
                loader.hide();
            })
            .catch(function (error) {
                loader.hide();
                errHandlerModel.googlePayErrorHandler(error);
            });
    } catch (error) {
        errHandlerModel.googlePayErrorHandler(error);
    }
}

/**
 * Init and add Google Pay on the Cart or Billing Page
 */
function initGooglePay() {
    var result = googlePayModel.collectFraudData()
        .then(function (fraudDataPayload) {
            googlePayHelper.setGooglePayDeviceData(fraudDataPayload);
            loader.hide();

            return googlePayModel.initGooglePay(googlePayConfig.googleMerchantId)
                .then(function (response) {
                    if (response.result) {
                          // Creates 'Google Pay Button' and append to the DOM
                        const button = paymentsClient.createButton({ onClick: onGooglePaymentButtonClicked });
                        $googlepayButton.appendChild(button);
                    }
                });
        });

    result
        .catch(function (error) {
            loader.hide();
            errHandlerModel.googlePayErrorHandler(error);
        });
}

/**
 * Inits Google Pay payment processing components
 * @param {Obbject} braintreeGooglePayModel Google Pay model
 * @param {Constructor} errorHandlerModel Error handler model
 */
function init(braintreeGooglePayModel, errorHandlerModel) {
    loader = loaderInstance($braintreeGooglePayLoader);
    googlePayModel = braintreeGooglePayModel;
    paymentsClient = braintreeGooglePayModel.getPaymentsClient();
    googlePayConfig = JSON.parse($googlepayButton.getAttribute('data-braintree-config'));
    errHandlerModel = errorHandlerModel;

    initGooglePay();
}

module.exports = {
    init
};
