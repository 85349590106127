'use strict';

var helper = require('../../helper');

/**
 * Creates tokenization options for 'tokenize' function.
 * @returns {Object} Object with tokenization options.
 */
function createTokenizationOptions() {
    var billingData = helper.getBillingAddressFormValues();

    return {
        billingAddress: {
            firstName: billingData.firstName,
            lastName: billingData.lastName,
            streetAddress: billingData.address1.split('%20').join(' '),
            extendedAddress: billingData.address2 ? billingData.address2.split('%20').join(' ') : billingData.address2,
            locality: billingData.city.includes('%20') ? billingData.city.split('%20').join(' ') : billingData.city,
            region: billingData.stateCode,
            postalCode: decodeURIComponent(billingData.postalCode),
            // eslint-disable-next-line no-nested-ternary
            countryCodeAlpha2: billingData.country ? billingData.country.includes('$20') ? billingData.country.split('%20').join(' ') : billingData.country : ''
        }
    };
}


module.exports = {
    createTokenizationOptions
};
