'use strict';

var PayPalBaseModel = require('./payPalBaseModel');
var helper = require('../../helper');
var payPalCheckoutModelHelper = require('../helpers/payPalCheckoutModelHelper');
var billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');
var paypalListHelper = require('../helpers/payPalListHelper');

/**
 * PayPal checkout model constructor
 * @param {Object} errorHandlingModel error handling model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {boolean} isVaultMode "true" in case if vault mode is enabled
 * @param {boolean} requestBillingAgreement "true" in case if billing agreement should be requested
 * @param {boolean} isFraudToolsEnabled "true" in case if fraud tools is enabled
 * @param {Object} $continueButton continue button js container
 * @param {number} totalAmount total basket amount
 * @param {Object} $loaderContainer loader js container
 */
function PayPalCheckoutPaymentModel(
    errorHandlingModel,
    btClientInstancePromise,
    payPalButtonSelector,
    payPalConfigurations,
    isVaultMode,
    requestBillingAgreement,
    isFraudToolsEnabled,
    $continueButton,
    totalAmount,
    $loaderContainer
) {
    PayPalBaseModel.call(
        this,
        errorHandlingModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        isVaultMode,
        requestBillingAgreement,
        isFraudToolsEnabled,
        $loaderContainer
    );

    this.$continueButton = $continueButton;
    this.totalAmount = totalAmount;
    this.changePMButtonEnabled = payPalConfigurations.changePMButtonEnabled;
}

// ES5 inheritance
PayPalCheckoutPaymentModel.prototype = Object.create(PayPalBaseModel.prototype);

// For more details see same method from "PayPalBaseModel"
// Add additional configs which are needed for "Change Payment Method Button" feature
PayPalCheckoutPaymentModel.prototype.payPalCheckoutInstanceConfigs = function (btClientInstance) {
    var checkoutInstanceConfigs = PayPalBaseModel.prototype.payPalCheckoutInstanceConfigs.call(this, btClientInstance);

    if (this.changePMButtonEnabled) {
        checkoutInstanceConfigs.autoSetDataUserIdToken = true;
    }

    return checkoutInstanceConfigs;
};

// For more details see same method from "PayPalBaseModel"
// Add additional configs which are needed for "Change Payment Method Button" feature
PayPalCheckoutPaymentModel.prototype.payPalCheckoutSdkConfigs = function () {
    var payPalCheckoutSdkConfigs = PayPalBaseModel.prototype.payPalCheckoutSdkConfigs.call(this);

    if (this.changePMButtonEnabled) {
        payPalCheckoutSdkConfigs.dataAttributes = {
            amount: this.totalAmount
        };
    }

    return payPalCheckoutSdkConfigs;
};

// For more details see same method from "PayPalBaseModel"
// Show error in case if current basket has zero total price
PayPalCheckoutPaymentModel.prototype.onPayPalButtonClickCallback = function (_, actions) {
    PayPalBaseModel.prototype.onPayPalButtonClickCallback.call(this, _, actions);

    if (this.basketData.amount === 0) {
        this.errorHandlingModel.showZeroAmountOnCheckout();

        return actions.reject();
    }
};

// For more details see same method from "PayPalBaseModel"
// Mark new account as the session one, fill billing form with data from paypal
PayPalCheckoutPaymentModel.prototype.onApprovePaymentCallback = function (payload) {
    // Calling parrent method to proceed with PayPal payload validation
    var baseResponse = PayPalBaseModel.prototype.onApprovePaymentCallback.call(this, payload);

    // Error will be handled on BaseModel level
    if (baseResponse && baseResponse.error) {
        return;
    }

    this.loader.show();

    var PayPalCheckoutPaymentModelInstance = this;
    var isBuyerAuthenificated = PayPalCheckoutPaymentModelInstance.payPalConfigurations.isBuyerAuthenticated;
    var btDetails = payload.details;
    var nonce = payload.nonce;
    var email = payload.details.email;
    var btBillingAddress = btDetails ? btDetails.billingAddress : null;

    // Remove all other Payments session payments
    helper.removeActiveSessionPayment();

    // Hide PayPal buttons block
    payPalCheckoutModelHelper.hidePayPalButtons();

    // Fill just PayPal related form fields
    billingFormHelper.fillPayPalPaymentFields(btDetails);

    // Copy billing address from PayPal to DW billing address form fields
    if (btBillingAddress && btDetails) {
        billingFormHelper.copyPayPalBillingAddressToDw(btBillingAddress, btDetails);
    }

    // Make current PayPal account as a session account
    billingFormHelper.storePaymentAsSessionPayment(nonce, email);

    billingFormHelper.setSessionAccountOptionDefault();

    // Add billing address on session payment option level
    payPalCheckoutModelHelper.addBillingAddressOnPaymentOptionLevel(btDetails);

    // Check whether buyer has proceeded with the same email which is already stored
    // if "yes" we hide stored account with the same email in dropdown
    // if "no" we show all existent PP accounts in dropdown (stored + new session account)
    paypalListHelper.handleStoredAccountsShowing(isBuyerAuthenificated);


    // Gennerate and add fraud data in to the billing form
    PayPalCheckoutPaymentModelInstance.fraudDataPromise
        .then(function (data) {
            // We have "fraudDataCollectionDisabled" flag only in case if we have fraud data collection disabled
            if (!data.fraudDataCollectionDisabled) {
                billingFormHelper.fillFraudDataInput(data);
            }
        })
        .then(function () {
            // Submit payment to server
            PayPalCheckoutPaymentModelInstance.$continueButton.click();

            PayPalCheckoutPaymentModelInstance.loader.hide();
        });
};

module.exports = PayPalCheckoutPaymentModel;
