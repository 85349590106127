'use strict';

var payPalProductPage = require('./components/payPalProductPage');
var payPalMiniCartPage = require('./components/payPalMiniCartPage');
var payPalCartPage = require('./components/payPalCartPage');
var payPalAccountPage = require('./components/payPalAccountPage');
var payPalCheckoutPage = require('./components/payPalCheckoutPage');

var btClientInstancePromise;

/**
 * Init PayPal functionality on PDP
 */
function initProduct() {
    payPalProductPage.init(btClientInstancePromise);
}

/**
 * Init PayPal functionality on MiniCart
 */
function initMiniCart() {
    // For MiniCart we generate client instance inside component.
    // This is, since general code which generate client instance executes before client token appear
    // This is only applicable for MiniCart
    payPalMiniCartPage.init();
}

/**
 * Init PayPal functionality on Cart
 */
function initCart() {
    payPalCartPage.init(btClientInstancePromise);
}

/**
 * Init PayPal functionality on Checkout
 */
function initCheckout() {
    payPalCheckoutPage.init(btClientInstancePromise);
}

/**
 * Init PayPal functionality on Account
 */
function initAccount() {
    payPalAccountPage.init(btClientInstancePromise);
}

/**
 * Init general variables which are used on all pages
 * @param {Object} clientInstancePromise client instance promise
 */
function init(clientInstancePromise) {
    btClientInstancePromise = clientInstancePromise;
}

module.exports = {
    init,
    initCheckout,
    initProduct,
    initMiniCart,
    initCart,
    initAccount
};
