'use strict';

var venmoConstants = require('../constants/venmoConstants');

/**
 * Gets Venmo flow name (corresponding page)
 * @returns {string} Flow ID (page) Account/Billing
 */
function getVenmoFlowName() {
    var venmoFlowName;
    var isVenmoEnabledOnAccountPage = Boolean(document.querySelector('.js_braintree_accountVenmoButton'));
    var isVenmoEnabledOnBillingPage = Boolean(document.querySelector('.js_braintree_venmoContent'));

    if (isVenmoEnabledOnAccountPage) {
        venmoFlowName = venmoConstants.FLOW_NAME_ACCOUNT;
    } else if (isVenmoEnabledOnBillingPage) {
        venmoFlowName = venmoConstants.FLOW_NAME_BILLING_CHECKOUT;
    }

    return venmoFlowName;
}

module.exports = {
    getVenmoFlowName
};
