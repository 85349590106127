'use strict';

var PayPalExpressModel = require('./payPalExpressModel');

/**
 * PayPal cart model constructor
 * @param {Object} errorHandlingModel error handling model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {boolean} isVaultMode "true" in case if vault mode is enabled
 * @param {boolean} requestBillingAgreement "true" in case if billing agreement should be requested
 * @param {boolean} isFraudToolsEnabled "true" in case if fraud tools is enabled
 * @param {Object} $csrfToken CSRF token js container
 * @param {Object} $loaderContainer loader js container
 */
function PayPalCartModel(
    errorHandlingModel,
    btClientInstancePromise,
    payPalButtonSelector,
    payPalConfigurations,
    isVaultMode,
    requestBillingAgreement,
    isFraudToolsEnabled,
    $csrfToken,
    $loaderContainer
) {
    // Init PayPalExpressModel constructor
    PayPalExpressModel.call(
        this,
        errorHandlingModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        isVaultMode,
        requestBillingAgreement,
        isFraudToolsEnabled,
        $csrfToken,
        $loaderContainer
    );
}

// ES5 inheritance
PayPalCartModel.prototype = Object.create(PayPalExpressModel.prototype);

// Show error in case if current basket has zero total price
PayPalCartModel.prototype.onPayPalButtonClickCallback = function (_, actions) {
    PayPalExpressModel.prototype.onPayPalButtonClickCallback.call(this, _, actions);

    if (this.basketData.amount === 0) {
        this.errorHandlingModel.showZeroAmountOnCart();

        return actions.reject();
    }
};

module.exports = PayPalCartModel;
