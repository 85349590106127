'use strict';

var payPalStaticImageHelper = require('../helpers/payPalStaticImageHelper');
var payPalButtonDisplayBehavior = require('./payPalButtonDisplayBehavior');
var loaderInstance = require('../../loaderHelper');
var ErrorHandlingBaseModel = require('../errorhandler/errorHandlingBaseModel');
var ErrorHandlingCartModel = require('../errorhandler/errorHandlingCartModel');
var ErrorHandlingMiniCartModel = require('../errorhandler/errorHandlingMiniCartModel');
var billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');
var requestsHelper = require('../helpers/payPalRequestsHelperGlobal');
var payPalExpressModelHelper = require('../helpers/payPalExpressModelHelper');

var $btPoductPagePayPalButton = document.querySelector('.braintree_pdp_button');

/**
 * On PayPal Static button click handler. Implement the logic which should happen when buyer clicked on this button
 * @param {Object} loader Loader
 * @param {Object} ErrorHandlingInstance Error handling instance
 */
function onStaticImageClickHandlerBase(loader, ErrorHandlingInstance) {
    loader.show();

    // URLs
    var checkoutFromCartUrl = this.getAttribute('data-checkout-from-cart-url');
    var placeOrderUrl = this.getAttribute('data-paypal-placeorder-url');

    // PayPal Account data
    var payPalAccountData = JSON.parse(this.getAttribute('data-paypal-account-data'));
    var billingAddressData = Object.assign(payPalAccountData, JSON.parse(payPalAccountData.address));

    // Process of payment FromData creation/filling
    var $payPalButtonWrapper = document.querySelector('.braintree-cart-paypal-buttons-wrap');
    var payPalCheckoutFormFieldNames = $payPalButtonWrapper.getAttribute('data-paypal-checkout-form-fields');
    var $csrfToken = document.querySelector('.braintree-cart-paypal-buttons-wrap #csrf_token');
    var billingFromData;

    billingFromData = billingFormHelper.createBillingAddressFormData(payPalCheckoutFormFieldNames, billingAddressData);

    billingFormHelper.appendCsrfTokenToFormData(billingFromData, $csrfToken);
    payPalStaticImageHelper.appendSavePayPalAccount(billingFromData);
    payPalStaticImageHelper.appendPaymentDataToFormData(billingFromData, payPalAccountData);

    // Submit customer form with email (CheckoutServices-SubmitCustomer)
    // as we skip step "login via guest/registered user" while express checkout
    // email is set only in case of guest checkout and if email is not already set
    payPalExpressModelHelper.submitCustomerForm(payPalAccountData.email);

    // Submit billing data to server (CheckoutSrvice-SubmitPayment)
    // In case of succesfull call, redirect buyer to Order Review Page
    requestsHelper.submitPaymentDataToServerPromise(checkoutFromCartUrl, billingFromData, placeOrderUrl, loader, ErrorHandlingInstance);
}

/**
 * Code which is configure and manage Static PayPal button on PDP
 */
function onStaticImageClickHandlerPdp() {
    var isBtPoductPagePayPalButtonVisible = $btPoductPagePayPalButton.style.display === '';
    var addProductToCartOnClick = Boolean($btPoductPagePayPalButton) && isBtPoductPagePayPalButtonVisible;
    var ErrorHandlingProductModelInstance = new ErrorHandlingBaseModel({});
    var that = this;

    var $loaderContainter = document.querySelector('.product-detail .braintreePayPalLoader');
    var loader = loaderInstance($loaderContainter);

    loader.show();
    // On click on PayPal Static image add product to the Cart (only on PDP)
    if (addProductToCartOnClick) {
        var res = requestsHelper.addProductToCart();

        if (res.error) {
            // eslint-disable-next-line no-unused-expressions
            window.location.reload;
            loader.hide();
        } else {
            onStaticImageClickHandlerBase.call(that, loader, ErrorHandlingProductModelInstance);
        }
    }
}

/**
 * Code which is configure and manage Static PayPal button on the Cart Page
 */
function onStaticImageClickHandlerCart() {
    var ErrorHandlingCartModelInstance = new ErrorHandlingCartModel({});
    var $loaderContainter = document.querySelector('.braintree-cart-buttons-wrap .braintreePayPalLoader');
    var loader = loaderInstance($loaderContainter);

    onStaticImageClickHandlerBase.call(this, loader, ErrorHandlingCartModelInstance);
}

/**
 * Code which is configure and manage Static PayPal button on the MiniCart Page

 */
function onStaticImageClickHandlerMiniCart() {
    var ErrorHandlingMiniCartModelInstance = new ErrorHandlingMiniCartModel({});
    var $loaderContainter = document.querySelector('.paypalMinicartButton .braintreePayPalLoader');
    var loader = loaderInstance($loaderContainter);

    onStaticImageClickHandlerBase.call(this, loader, ErrorHandlingMiniCartModelInstance);
}

/**
 * Init on click listner on Static PayPal button on PDP
 * @param {Promise} clientInstancePromise Client Instance Promise
 */
function initOnPdp() {
    var $pdpStaticPaypalButton = document.querySelector('.braintree_pdp_button .braintree-static-paypal-button');

    // Init behavior for hide/show PayPal Static button
    payPalButtonDisplayBehavior.initProductPage($pdpStaticPaypalButton);

    if ($pdpStaticPaypalButton) {
        $pdpStaticPaypalButton.addEventListener('click', onStaticImageClickHandlerPdp);
    }
}

/**
 * Init on click listner on Static PayPal button on the MiniCart Page
 * @param {Promise} clientInstancePromise Client Instance Promise
 */
function initOnMiniCart() {
    var $miniCartStaticPaypalButton = document.querySelector('.paypalMinicartButton .braintree-static-paypal-button');

    if ($miniCartStaticPaypalButton) {
        $miniCartStaticPaypalButton.addEventListener('click', onStaticImageClickHandlerMiniCart);
    }
}

/**
 * Init on click listner on Static PayPal button on the Cart Page
 * @param {Promise} clientInstancePromise Client Instance Promise
 */
function initOnCart() {
    var $cartStaticPaypalButton = document.querySelector('.cart-page .braintree-static-paypal-button');

    if ($cartStaticPaypalButton) {
        $cartStaticPaypalButton.addEventListener('click', onStaticImageClickHandlerCart);
    }
}

module.exports = {
    initOnMiniCart,
    initOnPdp,
    initOnCart
};
