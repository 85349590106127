
'use strict';

var ErrorHandlingBaseModel = require('./errorHandlingBaseModel');

/**
 * Error Handling constructor
 * @param {Object} errorMessages Braintree Credit Card error messages
 */
function ErrorHandlingCheckoutModel(errorMessages) {
    ErrorHandlingBaseModel.call(this, errorMessages);

    this.$topErrorMessageText = document.querySelector('.error-message-text');
    this.$topErrorMessageWrapper = document.querySelector('.error-message');
}

/**
 * ES5 inheritance
 */
ErrorHandlingCheckoutModel.prototype = Object.create(ErrorHandlingBaseModel.prototype);

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCheckoutModel.prototype.createErrorNotification = function (message) {
    this.$topErrorMessageText.textContent = '';
    this.$topErrorMessageText.append(message);
    this.$topErrorMessageWrapper.style.display = 'block';

    window.scrollTo(0, 0);
};

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCheckoutModel.prototype.hideError = function () {
    this.$topErrorMessageWrapper.style.display = 'none';
    this.$topErrorMessageText.innerHTML = '';
};

module.exports = ErrorHandlingCheckoutModel;
