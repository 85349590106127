'use strict';

var googlePayPaymentProcessingConstants = require('../constants/googlePayPaymentProcessingConstants');

/**
 * Returns current Google Pay flow
 * @returns {string} Flow id checkout/cart/newGpAccount
 */
function getGooglePayFlowId() {
    var googlePayEnabledOnCart = Boolean(document.querySelector('.braintree-cart-google-button'));
    var googlePayEnabledOnCheckout = Boolean(document.querySelector('.js_braintree_googlepayContent'));
    var googlePayFlowId = null;

    var flows = {
        checkout: googlePayPaymentProcessingConstants.FLOW_CHECKOUT_NAME,
        cart: googlePayPaymentProcessingConstants.FLOW__CART_NAME,
        newGpAccount: googlePayPaymentProcessingConstants.FLOW_NEW_GP_ACCOUNT_NAME
    };

    if (googlePayEnabledOnCart) {
        googlePayFlowId = flows.cart;
    } else if (googlePayEnabledOnCheckout) {
        googlePayFlowId = flows.checkout;
    } else {
        googlePayFlowId = flows.newGpAccount;
    }

    return googlePayFlowId;
}

/**
 * Creates Google Pay billing address from payload data
 * @param {Object} data Data object
 * @returns {Objcet} billing address data object
 */
function createGooglepayBillingAddressData(data) {
    var billingData = data.paymentMethodData.info.billingAddress;
    var billingAddress = {};
    var recipientName = billingData.name.split(' ');

    billingAddress.firstName = recipientName[0];
    billingAddress.lastName = recipientName[1];
    billingAddress.phone = billingData.phoneNumber;
    billingAddress.countryCodeAlpha2 = billingData.countryCode;
    billingAddress.streetAddress = billingData.address1;
    billingAddress.extendedAddress = billingData.address2;
    billingAddress.locality = billingData.locality;
    billingAddress.stateCode = billingData.administrativeArea;
    billingAddress.postalCode = decodeURIComponent(billingData.postalCode);
    billingAddress.email = data.email;

    return billingAddress;
}

/**
 * Creates shipping address object
 * @param {Object} shippingData Shipping data object
 * @returns {Object} Shipping data object in required format
 */
function createGooglepayShippingAddressData(shippingData) {
    var shippingAddress = {};
    var recipientName = shippingData.name.split(' ');

    shippingAddress.firstName = recipientName[0];
    shippingAddress.lastName = recipientName[1];
    shippingAddress.phone = shippingData.phoneNumber;
    shippingAddress.countryCodeAlpha2 = shippingData.countryCode;
    shippingAddress.streetAddress = shippingData.address1;
    shippingAddress.extendedAddress = shippingData.address2;
    shippingAddress.locality = shippingData.locality;
    shippingAddress.region = shippingData.administrativeArea;
    shippingAddress.postalCode = decodeURIComponent(shippingData.postalCode);

    return shippingAddress;
}

module.exports = {
    getGooglePayFlowId,
    createGooglepayBillingAddressData,
    createGooglepayShippingAddressData
};
