'use strict';

/**
 * Show PayPal Account button
 */
function showPayPalAccountBtn() {
    var $addPaypalAccountBtn = document.querySelector('.add-paypal-account');
    var $paypalAccountWrapper = document.querySelector('.js_braintree_accountPaypalButton_wrapper');

    $addPaypalAccountBtn.style.display = 'none';
    $paypalAccountWrapper.style.display = 'block';
}

module.exports = {
    showPayPalAccountBtn
};
