'use strict';

var braintreeGooglePayModelBuilder = require('./braintreesdk/braintreeGooglePayModelBuilder');

var googlePayHelper = require('./helpers/googlePayHelper');

// Components
var googlePayList = require('./components/googlePayList');
var googlePayPaymentProcessing = require('./components/googlePayPaymentProcessing');

// Models
var ErrorHandlingBaseModel = require('./errorhandler/errorHandlingBaseModel');
var ErrorHandlingCheckoutModel = require('./errorhandler/errorHandlingCheckoutModel');

var googlePayErrorMessages = require('./constants/errorMessages');

var braintreeGooglePayModelResponse;
var googlePayMessages;

/**
 * Inits Google Pay on the Cart Page
 */
function initCart() {
    var errorHandlerBaseModel = new ErrorHandlingBaseModel(googlePayMessages);

    if (!braintreeGooglePayModelResponse || braintreeGooglePayModelResponse.error) {
        var error = braintreeGooglePayModelResponse.error || googlePayErrorMessages.NO_GOOGLE_PAY_BRAINTREE_CONFIGURATIONS;
        errorHandlerBaseModel.googlePayErrorHandler(error);
    }

    // Initialize a Google Pay 'PaymentsClient' object
    braintreeGooglePayModelResponse.btGooglePayModel.initPaymentsClient(
        braintreeGooglePayModelResponse.btGooglePayConfigs.instanceType
    );

    googlePayPaymentProcessing.init(
        braintreeGooglePayModelResponse.btGooglePayModel,
        errorHandlerBaseModel
    );
}

/**
 * Inits Google Pay on the Checkout Page
 */
function initCheckout() {
    var errorHandlerCheckoutModel = new ErrorHandlingCheckoutModel(googlePayMessages);

    if (!braintreeGooglePayModelResponse || braintreeGooglePayModelResponse.error) {
        var error = braintreeGooglePayModelResponse.error || googlePayErrorMessages.NO_GOOGLE_PAY_BRAINTREE_CONFIGURATIONS;
        errorHandlerCheckoutModel.googlePayErrorHandler(error);
    }

    // Triggers the Google Pay tab if Google Pay session account uses
    googlePayHelper.triggerGooglePayTab(braintreeGooglePayModelResponse.btGooglePayConfigs);

    // Initialize a Google Pay 'PaymentsClient' object
    braintreeGooglePayModelResponse.btGooglePayModel.initPaymentsClient(
        braintreeGooglePayModelResponse.btGooglePayConfigs.instanceType
    );

    googlePayPaymentProcessing.init(
        braintreeGooglePayModelResponse.btGooglePayModel,
        errorHandlerCheckoutModel
    );

    googlePayList.init();
}

/**
 * Inits Google Pay components
 */
function init() {
    braintreeGooglePayModelResponse = braintreeGooglePayModelBuilder.init();

    if (!braintreeGooglePayModelResponse.error) {
        googlePayMessages = braintreeGooglePayModelResponse.btGooglePayConfigs.messages;
    }
}

module.exports = {
    init,
    initCart,
    initCheckout
};
