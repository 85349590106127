'use strict';

var ErrorHandlingBaseModel = require('./errorHandlingBaseModel');

/**
 * Error Handling Checkout model constructor
 * @param {Object} errorMessages object with error messages
 */
function ErrorHandlingCheckoutModel(errorMessages) {
    ErrorHandlingBaseModel.call(this, errorMessages);

    this.errorMessages = errorMessages;
}

/**
 * ES5 inheritance
 */
ErrorHandlingCheckoutModel.prototype = Object.create(ErrorHandlingBaseModel.prototype);

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCheckoutModel.prototype.hideError = function () {
    var $topErrorMessageText = document.querySelector('.error-message-text');
    var $topErrorMessageWrapper = document.querySelector('.error-message');

    $topErrorMessageWrapper.style.display = 'none';
    $topErrorMessageText.innerHTML = '';
};

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCheckoutModel.prototype.createErrorNotification = function (message) {
    var $topErrorMessageText = document.querySelector('.error-message-text');
    var $topErrorMessageWrapper = document.querySelector('.error-message');

    $topErrorMessageText.textContent = '';
    $topErrorMessageText.append(message);

    $topErrorMessageWrapper.style.display = 'block';
    window.scrollTo(0, 0);
};

// Shows an error when the value of the total amount on the Checkout is 0
ErrorHandlingCheckoutModel.prototype.showZeroAmountOnCheckout = function () {
    ErrorHandlingCheckoutModel.prototype.createErrorNotification(this.errorMessages.CUSTOM_SRC_ZERO_AMOUNT_ERROR);
};

module.exports = ErrorHandlingCheckoutModel;
