var helper = require('../../helper');

var $srcAccountList = document.querySelector('#braintreeSrcAccountsList');
var $srcButton = document.querySelector('.js_braintree_src_button');
var $accoutnListBlock = document.querySelector('.braintree_used_src_account');
var $alertInfo = document.querySelector('.billingAddressInfoAlert');
var $saveSRCAccountCheckbox = document.querySelector('#braintreeSaveSRCAccount');
var $saveSRCAccountCheckboxContainter = document.querySelector('#braintreeSaveSRCContainer');

/**
 * Sets value to data-paypal-is-hide-continue-button attribute
 * @param {boolean} value true/false
 */
function setIsHideContinueButtonAttr(value) {
    var $srcContent = document.querySelector('.js_braintree_srcContent');

    $srcContent.setAttribute('data-paypal-is-hide-continue-button', value);
}

/**
 * Handles actions based on selected value in SRC dropdown for checkout view
 */
function srsListStateHandler() {
    var srcOptionId = $srcAccountList.value;
    switch (srcOptionId) {
        case 'newaccount':

            if ($alertInfo) {
                $alertInfo.style.display = 'block';
            }

            $accoutnListBlock.style.display = 'none';
            break;
        default:
            $srcButton.style.display = 'none';

            if ($alertInfo) {
                $alertInfo.style.display = 'none';
            }

            helper.continueButtonToggle(false);
            setIsHideContinueButtonAttr(false);
    }
}

/**
 * Get stored card by card type and last four digits
 * @param {string} cardType card type
 * @param {string} lastFour last four card digits
 * @returns {Object} jquery object of found stored card
 */
function getStoredCardByData(cardType, lastFour) {
    var $foundCard;

    $('.js_stored_card').each(function (_, $el) {
        var $card = $($el);
        var storedCardType = $card.data('type').replace(/\s/g, '').toLowerCase();
        var storedCardLastFour = $card.data('last-four').toString();

        if (storedCardType === cardType && storedCardLastFour === lastFour) {
            $foundCard = $card;
        }
    });

    return $foundCard;
}

/**
 * Checks whether SRC session card is already stored
 * @param {string} cardType card type
 * @param {string} lastFour last four card digits
 * @returns {boolean} "true/false" value whether session card is already stored
 */
function isSessionCardAlreadyStored(cardType, lastFour) {
    var $storedCard = getStoredCardByData(cardType, lastFour);
    var flag = false;

    if ($storedCard) {
        flag = true;
    }

    return flag;
}

/**
 * Sets value to "Save SRC account" checkbox
 * @param {Element} srcSessionAccount element from which we recognize session account existence
 * @param {boolean} value true/false
 */
function handleSaveSrcAccountCheckbox(srcSessionAccount, value) {
    var isSrcSessionCardExist = srcSessionAccount.value !== '';
    var isAddNewOptionSelected = $srcAccountList.value === 'newaccount';

    if ($saveSRCAccountCheckbox) {
        // In case if session account exists and is chosen in dropdown
        // we check whether used SRC card is already stored
        // if card is already stored "save" checkbox is hidden and unchecked
        if (isSrcSessionCardExist && !isAddNewOptionSelected) {
            var $braintreeSrcCardType = document.querySelector('#braintreeSrcCardType');
            var $braintreeSrcLastFourDigits = document.querySelector('#braintreeSrcLastFourDigits');
            var sessionCardType = $braintreeSrcCardType.value;
            var sessionCardLastFour = $braintreeSrcLastFourDigits.value;
            sessionCardType = sessionCardType.replace(/\s/g, '').toLowerCase();

            var isSrcSessionCardAlreadyStored = isSessionCardAlreadyStored(sessionCardType, sessionCardLastFour);

            if (isSrcSessionCardAlreadyStored) {
                $saveSRCAccountCheckbox.checked = false;
                $saveSRCAccountCheckboxContainter.style.display = 'none';
            }

            return;
        }
        // In case if session card does not exist/user chooses option "Add New"
        // or session card is not stored yet
        // checkbox is checked and shown
        $saveSRCAccountCheckboxContainter.style.display = 'block';
        $saveSRCAccountCheckbox.checked = value;
    }
}

module.exports = {
    setIsHideContinueButtonAttr,
    srsListStateHandler,
    handleSaveSrcAccountCheckbox
};
