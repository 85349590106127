'use strict';

var srcHelper = require('../helpers/srcHelper');
var srcListHelper = require('../helpers/srcListHelper');

/**
 * Inits SRC list functionality
 */
function init() {
    var $srcAccountList = document.querySelector('#braintreeSrcAccountsList');
    var srcListExist = Boolean($srcAccountList);

    if (srcListExist) {
        srcListHelper.srsListStateHandler();

        $srcAccountList.addEventListener('change', function () {
            srcHelper.srcContentTabHandler(true);
        });
    }
}

module.exports = {
    init
};
