'use strict';

var PayPalAccountModel = require('../models/payPalAccountModel');
var ErrorHandlingBaseModel = require('../errorhandler/errorHandlingBaseModel');
var payPalAccountPageHelper = require('../helpers/payPalAccountPageHelper');
var helper = require('../../helper');
var loaderInstance = require('../../loaderHelper');

/**
 * Init add new PayPal account button
 * @param {Object} PayPalAccount PayPal Account model instance
 */
function initAddNewPayPalAccountEvent(PayPalAccount) {
    var $addNewPayPalAccountButton = document.querySelector('.add-paypal-account');

    $addNewPayPalAccountButton.addEventListener('click', function () {
        payPalAccountPageHelper.showPayPalAccountBtn();

        if (!window.accountPayPalButtonInited) {
            window.accountPayPalButtonInited = true;
            PayPalAccount.initPayment();
        }
    });
}

/**
 * Init remove PayPal account button
 */
function initRemovePayPalAccountEvent() {
    $(document).on('click', '.paypal-accounts .remove-bt-payment', function (e) {
        helper.removeBtPayment(e);
    });
}

/**
 * Init Make Default PayPal account button
 */
function initMakeDefaultPayPalAccountEvent() {
    $(document).on('click', '.paypal-accounts .braintree-make-default-card', function () {
        var $this = $(this);
        var makeDefaultUrl = $this.data('make-default-url');
        var paymentMehtodId = $this.data('id');
        var paymentProcessorId = $('.paypal-accounts').data('payment-method-id');
        var loaderContainer = document.querySelector('#paypalAccountBtLoader');
        var loader = loaderInstance(loaderContainer);

        loader.show();

        helper.setDefaultProperty({
            url: makeDefaultUrl,
            id: paymentMehtodId,
            paymentMethodID: paymentProcessorId,
            loader
        });
    });
}

/**
 * Function which initiate PayPal functionality on the Account Page
 * @param {Object} btClientInstancePromise BT Client Instance promise
 */
function init(btClientInstancePromise) {
    var payPalButtonSelector = '.js_braintree_accountPaypalButton_wrapper .js_braintree_accountPaypalButton';
    var $payPalButton = document.querySelector(payPalButtonSelector);

    if ($payPalButton) {
        var payPalConfigurations = JSON.parse($payPalButton.getAttribute('data-braintree-config'));
        var addPayPalAccountUrl = $payPalButton.getAttribute('data-paypal-add-account-handler');

        var isVaultMode = payPalConfigurations.options.flow !== 'checkout';
        var requestBillingAgreement = Boolean(payPalConfigurations.options.requestBillingAgreement);
        var isFraudToolsEnabled = payPalConfigurations.isFraudToolsEnabled;
        let $csrfToken = document.querySelector('.js_braintree_accountPaypalButton_wrapper #csrf_token');
        var $loaderContainer = document.querySelector('#braintreePayPalAccLoader');

        // Init params for AccountErrorHandling model
        var errorMessages = payPalConfigurations.messages;

        var ErrorHandling = new ErrorHandlingBaseModel(errorMessages);
        var PayPalAccount = new PayPalAccountModel(
            ErrorHandling,
            btClientInstancePromise,
            payPalButtonSelector,
            payPalConfigurations,
            isVaultMode,
            requestBillingAgreement,
            isFraudToolsEnabled,
            $csrfToken,
            addPayPalAccountUrl,
            $loaderContainer
        );

        initAddNewPayPalAccountEvent(PayPalAccount);
        initRemovePayPalAccountEvent();
        initMakeDefaultPayPalAccountEvent();

        return;
    }

    var isVenmoAccountBlockExists = Boolean(document.querySelector('.paypal-accounts'));

    if (isVenmoAccountBlockExists) {
        initRemovePayPalAccountEvent();
        initMakeDefaultPayPalAccountEvent();
    }
}

module.exports = {
    init
};
