'use strict';

var braintreeGeneral = require('../../braintreeGeneral');
var braintreeGooglePaySDK = require('../braintreesdk/braintreeGooglePaySDK');

/* global Promise braintree $ */

// Global variables
// Is Froud tools enabled
var isFtEnabled;
var btClientInstancePromise;
var googlePaymentInstance;
var paymentsClient;

var amount;

/**
 * Init Baintree Credit Card model
 * @param {Promise} braintreeClientInstancePromise Braintreee client instance promise
 * @param {boolean} isFraudToolsEnabled Is fraud tools enabled value
 */
function init(braintreeClientInstancePromise, isFraudToolsEnabled) {
    braintreeGooglePaySDK.init(braintreeClientInstancePromise);

    btClientInstancePromise = braintreeClientInstancePromise;
    isFtEnabled = isFraudToolsEnabled;
}

/**
 * Returns fraud data from dataCollector payload
 * @returns {Object} Payload with fraud data
 */
function collectFraudData() {
    var response;

    if (isFtEnabled) {
        response = braintreeGeneral.collectFraudData(btClientInstancePromise);
    } else {
        response = Promise.resolve({
            customMessage: 'Fraud Data collection isn\t enabled via Custom Site Preference'
        });
    }

    return response;
}

/**
 * Preparing googlePay payment
 * @param {string} googleMerchantId Google Pay merchant identifier
 * @returns {Promise} Promise with resolve 'true' if ready to pay
 */
function initGooglePay(googleMerchantId) {
    return braintreeGooglePaySDK.createGooglePayment(googleMerchantId)
        .then(function (gpInstance) {
            var allowedPaymentMethods = gpInstance.createPaymentDataRequest().allowedPaymentMethods;
            googlePaymentInstance = gpInstance;

            return paymentsClient.isReadyToPay({
                // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: allowedPaymentMethods
            });
        });
}

/**
 * Creates a configuration object for use in the loadPaymentData method.
 * @param {Object} options Object with payment options
 * @returns {Object} Configuration object
 */
function createPaymentDataRequest(options) {
    return braintreeGooglePaySDK.createPaymentDataRequest(googlePaymentInstance, options, amount);
}

/**
 * Parse the response from the tokenization.
 * @param {Object} paymentData The response back from the Google Pay tokenization.
 * @returns {Promise} Promise that resolve with a tokenizePayload
 */
function parseResponse(paymentData) {
    return braintreeGooglePaySDK.parseResponse(paymentData, googlePaymentInstance);
}

/**
 * Update order amount
 */
function googlePayUpdateAmount() {
    var config = JSON.parse(document.querySelector('.js_braintree_googlepay_button').getAttribute('data-braintree-config'));

    $.ajax({
        url: config.getOrderInfoUrl,
        async: false,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            amount = data.amount;
        },
        error: function () {
            window.location.reload();
        }
    });
}

/**
 * Inits 'Google Pay' payment client object into braintreeGooglePayModel
 * @param {string} instanceType The type of the instance TEST/PRODUCTION
 */
function initPaymentsClient(instanceType) {
    paymentsClient = new google.payments.api.PaymentsClient({ // eslint-disable-line no-undef
        environment: instanceType // TEST/PRODUCTION
    });
}

/**
 * Gets 'Google Pay' payment client object
 * @returns {Object}'Google Pay' payment client object
 */
function getPaymentsClient() {
    return paymentsClient;
}

module.exports = {
    init,
    collectFraudData,
    initGooglePay,
    createPaymentDataRequest,
    parseResponse,
    googlePayUpdateAmount,
    // initialize a Google Pay 'PaymentsClient' object
    initPaymentsClient,
    getPaymentsClient
};
