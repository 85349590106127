'use strict';

var helper = require('../../helper');
var loaderInstance = require('../../loaderHelper');
var paymentMethodGeneral = require('../../paymentMethodGeneral');

// Global variables
var googlePayConfigs;
var googlePayBillingAddressData;
var googlePayShippingAddressData;
var googlePayNonce;
var googlepayPaymentType;
var googlePayCardDescription;
var googlepayCheckoutFormData;
var errHandlerModel;

var $braintreeGooglePayLoader = document.querySelector('.braintreeGooglepayLoader');

var loader;

/**
 * Expends Google Pay checkout form with required data
 */
function expandGooglePayCheckoutFormData() {
    var deviceData = document.querySelector('input[name=braintreeGooglePayDeviceDataInput]').value;
    var csrfToken = document.querySelector('.braintree-cart-buttons-wrap  #csrf_token');

    googlepayCheckoutFormData.append(csrfToken.name, csrfToken.value);
    googlepayCheckoutFormData.append('braintreeGooglePayNonce', googlePayNonce);
    googlepayCheckoutFormData.append('braintreeGooglePayDeviceData', deviceData);
    googlepayCheckoutFormData.append('braintreeGooglePayCardDescription', googlePayCardDescription);
    googlepayCheckoutFormData.append('braintreeGooglepayPaymentType', googlepayPaymentType);
    googlepayCheckoutFormData.append('braintreeGooglePayShippingAddress', JSON.stringify(googlePayShippingAddressData) || '{}');
}

/**
 * Process Google Pay on the Cart Page
 */
function processCartFlow() {
    var $braintreeCartButtons = document.querySelector('.braintree-cart-buttons-wrap');
    var placeOrderUrl = $braintreeCartButtons.getAttribute('data-checkout-placeorder-url');
    var checkoutFromCartUrl = $braintreeCartButtons.getAttribute('data-checkout-from-cart-url');
    var checkoutFormFields = $braintreeCartButtons.getAttribute('data-checkout-form-fields');
    var paymentFieldsData = helper.getPaymentFieldsData(googlePayBillingAddressData, googlePayConfigs.paymentMethodName);

    // Creates and updates Google Pay checkout form for service side
    googlepayCheckoutFormData = helper.createPaymentFormData(checkoutFormFields, paymentFieldsData);
    expandGooglePayCheckoutFormData();
    // submit customer form with email (CheckoutServices-SubmitCustomer)
    // as we skip step "login via guest/registered user" while express checkout
    // email is set only in case of guest checkout and if email is not already set
    paymentMethodGeneral.submitCustomerForm(googlePayBillingAddressData.email);

    $.ajax({
        type: 'POST',
        url: checkoutFromCartUrl,
        data: googlepayCheckoutFormData,
        contentType: false,
        processData: false,
        success: function (res) {
            if (res.error) {
                var errorMessage = '';

                if (res.fieldErrors.length) {
                    res.fieldErrors.forEach(function (error, index) {
                        var keys = Object.keys(error);

                        if (keys.length) {
                            errorMessage += `${keys[index].replace('dwfrm_billing_', '').replace('_', ' ')} ${res.fieldErrors[index][keys[index]]}. `;
                        }
                    });

                    errHandlerModel.showErrorByMessage(errorMessage);
                }

                if (res.serverErrors.length) {
                    res.serverErrors.forEach(function (error) {
                        errorMessage += `${error}. `;
                    });
                    errHandlerModel.showErrorByMessage(errorMessage);
                }

                if (res.cartError) {
                    window.location.href = res.redirectUrl;
                }

                return;
            }

            sessionStorage.setItem('pageState', 'cart');
            loader.hide();
            window.location.href = placeOrderUrl;
        },
        error: function (err) {
            if (err && err.redirectUrl) {
                window.location.href = err.redirectUrl;
            }
        }
    });
}

/**
 * Inits Google Pay cart components
 * @param {Object} billingAddressData Billing address data object
 * @param {Object} shippingAddressData Shipping address data object
 * @param {string} nonce Payment method nonce
 * @param {string} paymentType Payment method type
 * @param {string} description Google Pay card description
 * @param {Constructor} errorHandlerModel Error handler Model
 */
function init(billingAddressData, shippingAddressData, nonce, paymentType, description, errorHandlerModel) {
    var $googlepayButton = document.querySelector('.js_braintree_googlepay_button');

    googlePayConfigs = JSON.parse($googlepayButton.getAttribute('data-braintree-config'));
    googlePayBillingAddressData = billingAddressData;
    googlePayShippingAddressData = shippingAddressData;
    googlePayNonce = nonce;
    googlepayPaymentType = paymentType;
    googlePayCardDescription = description;
    loader = loaderInstance($braintreeGooglePayLoader);
    errHandlerModel = errorHandlerModel;

    processCartFlow();
}

module.exports = {
    init
};
