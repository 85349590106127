'use string';

// Helpers
var lpmHelper = require('../helpers/lpmHelper');
var loaderInstance = require('../../loaderHelper');
var lpmPaymentProcessingHelper = require('../helpers/lpmPaymentProcessorHelper');

var lpmErrorHandler = require('../errorhandler/lpmErrorHandler');

var $braintreeLpmLoader = document.querySelector('.lpmLoader');

// Global variables
var lpmConfigs;
var loader;
var lpmModel;

/**
 * Opens LPM page and process a payment
 * @param {Object} reqData Options object
 * @param {string} paymentMethodName Payment method name
 */
function startLpmPayment(reqData, paymentMethodName) {
    lpmModel.startPayment(reqData)
        .then(function (startPaymentPayload) {
            // Handles LPM processing messages on the Billing-Checkout Page
            lpmPaymentProcessingHelper.hideProcessingMsg();
            lpmPaymentProcessingHelper.showFinalProcessingMsg();

            // Sends payment details to the backend
            return lpmPaymentProcessingHelper.confirmPayment(
                lpmConfigs,
                paymentMethodName,
                reqData.email,
                startPaymentPayload
            );
        })
        .then(function (response) {
            loader.hide();
            lpmPaymentProcessingHelper.processLpmConfirmForm(response.redirectUrl);
        })
        .catch(function (err) {
            loader.hide();
            lpmErrorHandler.processStartLpmPaymentError(err);
        });
}

/**
 * Prepares and checks all necessary options for start LPM payment
 * @param {Object} event On LPM button click event
 */
function onLpmButtonClick(event) {
    var $email = document.querySelector('.customer-summary-email');
    var paymentMethodName = this.dataset.localPaymentMethodName;
    var reqData;

    lpmErrorHandler.hideError();

    event.preventDefault();

    try {
        if (lpmConfigs.options.amount === 0) {
            loader.hide();
            lpmErrorHandler.handleZeroAmountOnCheckout(paymentMethodName);
        }

        $.getJSON(lpmConfigs.getOrderInfoUrl)
            .then(function (data) {
                loader.show();
                lpmPaymentProcessingHelper.showProcessingMsg();

                // Updates shipping address and amount of order from server
                lpmModel.updateLpmOrderData(data.shippingAddress, data.amount);

                // Creates all options for initiating the local payment payment flow.
                reqData = lpmModel.createRequestData(paymentMethodName, $email.textContent);

                startLpmPayment(reqData, paymentMethodName);
            })
            // Handles errors form Braintree-GetOrderInfo endpoint
            .catch(function (err) {
                loader.hide();
                lpmErrorHandler.showErrorByMessage(err.responseJSON.message);
            });
        loader.hide();
    } catch (err) {
        lpmErrorHandler.showErrorByMessage(err);
    }
}

/**
 * Inits LPM functionality on the Checkout-Billing Page
 */
function initLpm() {
    lpmModel.initLpm()
        .then(function (response) {
            if (response.createLpmClickListener) {
                $('.tab-content').on('click', '.js_braintree_lpm_button', onLpmButtonClick);
            }
        })
        .catch(function (err) {
            lpmErrorHandler.showErrorByMessage(err.message);
        });
}

/**
 * Inits LPM payment processing components
 * @param {Object} braintreeLpmModel LPM model
 */
function init(braintreeLpmModel) {
    loader = loaderInstance($braintreeLpmLoader);
    lpmConfigs = lpmHelper.getBraintreeLpmConfigs();
    lpmModel = braintreeLpmModel;

    initLpm();
}

module.exports = {
    init
};
