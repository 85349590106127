'use strict';

/* global braintreeUtils braintree $ */

var btClientInstancePromise;

/**
 * Creates Lpm payment instance
 * @returns {Promise} A promise resolved with the Lpm instance
 */

function createLpmPayment() {
    return btClientInstancePromise
        .then(function (btClientInstance) {
            return braintree.localPayment.create({
                client: btClientInstance
            });
        });
}

/**
 * Creates and returns object with all options for initiating the LPM payment flow
 * @param {Object} shippingData Order Shipping address
 * @param {Object} lpmConfigs LPM configs object
 * @param {string} paymentMethodName Payment method name
 * @param {string} email Email address
 * @param {number} amount Order total amount
 * @returns {Object} Options object
 */
function createRequestData(shippingData, lpmConfigs, paymentMethodName, email, amount) {
    var recipientName = shippingData.recipientName.split(' ');
    shippingData.firstName = recipientName[0];
    shippingData.lastName = recipientName[1];

    return {
        paymentType: paymentMethodName,
        amount: amount,
        fallback: {
            url: lpmConfigs.fallbackUrl + '?lpmName=' + paymentMethodName + '&email=' + email,
            buttonText: 'Complete Payment'
        },
        currencyCode: lpmConfigs.options.currency,
        shippingAddressRequired: false,
        email: email,
        phone: shippingData && shippingData.phone,
        givenName: shippingData && shippingData.firstName,
        surname: shippingData && shippingData.lastName,
        address: {
            countryCode: shippingData && shippingData.countryCode
        }
    };
}

/**
 *
 * @param {lpmInstance} localPaymentInstance LPM instance
 * @param {Object} reqData Options object
 * @returns {Promise} Promise resolved with a startPaymentPayload
 */
function startPayment(localPaymentInstance, reqData) {
    return localPaymentInstance.startPayment(reqData);
}

/**
 * Inits braintreeLpmSDK with client instance promise
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 */
function init(braintreeClientInstancePromise) {
    btClientInstancePromise = braintreeClientInstancePromise;
}

module.exports = {
    init,
    createLpmPayment,
    createRequestData,
    startPayment
};
