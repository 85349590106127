'use strict';

var ErrorHandlingBaseModel = require('./errorHandlingBaseModel');

/**
 * Error Handling Cart model constructor
 * @param {Object} errorMessages Object with error messages
 */
function ErrorHandlingCartModel(errorMessages) {
    ErrorHandlingBaseModel.call(this, errorMessages);

    this.$errorContainer = $('.cart-error-messaging');
    this.errorMessages = errorMessages;
}

/**
 * ES5 inheritance
 */
ErrorHandlingCartModel.prototype = Object.create(ErrorHandlingBaseModel.prototype);

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCartModel.prototype.createErrorNotification = function (message) {
    $('.checkout-btn').addClass('disabled');
    this.$errorContainer.append(
        `<div class="alert alert-danger alert-dismissible valid-cart-error fade show cartError custom-cart-error" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            ${message}
        </div>`
    );

    this.$errorContainer.show();
    window.scrollTo(0, 0);
};

// See corresponding method inside "ErrorHandlingBaseModel" model
ErrorHandlingCartModel.prototype.hideError = function () {
    this.$errorContainer.hide();
    this.$errorContainer.empty();
};

/**
 * Show "Zero Amount" error on Cart page
 */
ErrorHandlingCartModel.prototype.showZeroAmountOnCart = function () {
    this.showErrorByMessage(this.errorMessages.CUSTOM_PAYPAL_ZERO_AMOUNT_ERROR);
};


module.exports = ErrorHandlingCartModel;
