'use strict';
var braintreeCreditCardModel = require('./braintreeCreditCardModel');
var braintreeGeneral = require('../../braintreeGeneral');
var creditCardHelper = require('../helpers/creditCardHelper');

/**
 * Initialize Braintree Credit Card Model. Pattern Builder is used
 * @returns {Object} object with booleam "error" flag and "message" attribute with error message
 */
function init() {
    var braintreeCreditCardConfigs = creditCardHelper.getCheckoutBraintreeCreditCardConfigs();
    var clientToken;
    var btClientInstancePromise;
    var isSkip3dSecureLiabilityResult;
    var is3dSecureEnabled;
    var isFraudToolsEnabled;
    var response = {
        error: false,
        message: '',
        btCreditCardModel: null,
        btCreditCardConfigs: null
    };

    if (braintreeCreditCardConfigs) {
        clientToken = braintreeCreditCardConfigs.clientToken;
        btClientInstancePromise = braintreeGeneral.createClientInstance(clientToken);
        isSkip3dSecureLiabilityResult = braintreeCreditCardConfigs.isSkip3dSecureLiabilityResult;
        is3dSecureEnabled = braintreeCreditCardConfigs.is3dSecureEnabled;
        isFraudToolsEnabled = braintreeCreditCardConfigs.isFraudToolsEnabled;

        braintreeCreditCardModel.init(
            btClientInstancePromise,
            isSkip3dSecureLiabilityResult,
            is3dSecureEnabled,
            isFraudToolsEnabled
        );

        response.btCreditCardModel = braintreeCreditCardModel;
        response.btCreditCardConfigs = braintreeCreditCardConfigs;
    } else {
        response.error = true;
        response.message = 'No Credit Card Braintree Configurations';
    }

    return response;
}

module.exports = {
    init
};
