'use strict';

var creditCardFormFieldValidation = require('./creditCardFormFieldsValidator');

var ALL_HOSTED_FIELDS_FIELDS_EMPTY = 'HOSTED_FIELDS_FIELDS_EMPTY';
var HOSTED_FIELDS_FIELDS_INVALID = 'HOSTED_FIELDS_FIELDS_INVALID';

/**
 * Error Handling constructor
 * @param {Object} errorMessages Braintree Credit Card error messages
 */
function ErrorHandlingBaseModel(errorMessages) {
    this.errorMessages = errorMessages;
}

// General method which should be used for cases when error came from Braintree
ErrorHandlingBaseModel.prototype.showErrorByObject = function (error) {
    var msg = error.message;
    var code = error.code;
    var errorMessage = this.errorMessages[code] || msg || this.errorMessages.CLIENT_GATEWAY_NETWORK;

    this.createErrorNotification(errorMessage);
};

ErrorHandlingBaseModel.prototype.showErrorByMessage = function (message) {
    this.createErrorNotification(message);
};

/**
 * ---- Method which should hide error container and make it empty. On Account we use this one ----
 * ---- For all other cases this method will be overwritten by corresponding one ----
 *
 * Hide error notification
 */
ErrorHandlingBaseModel.prototype.hideError = function () {
    var $errorContainer = $('.error-messaging');

    $errorContainer.hide();
    $errorContainer.empty();
};

/**
 * ---- Error container which will be used on Account ----
 * ---- For all other cases this method will be overwritten by corresponding one ----
 *
 * Method responsible for Error Notification generation
 * @param {string} message Message to display inside error notification
 */
ErrorHandlingBaseModel.prototype.createErrorNotification = function (message) {
    var $errorContainer = $('.error-messaging');

    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
    'fade show" role="alert">' +
    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
    '<span aria-hidden="true">&times;</span>' +
    '</button>' + message + '</div>';

    $errorContainer.append(errorHtml);
    $errorContainer.show();
    window.scrollTo(0, 0);
};

/**
 * Converts Braintree fields
 * @param {Array} arrayOfBtFormFields Credit Cards hosted fileds in array
 * @returns {Array} Converted array
 */
ErrorHandlingBaseModel.prototype.convertBtFromFieldsToDw = function (arrayOfBtFormFields) {
    var creditCardFieldsMap = {
        cardholderName: 'braintreeCardOwner',
        number: 'braintreeCardNumber',
        cvv: 'braintreeCvv',
        expirationDate: 'braintreeExpirationDate'
    };
    var braintreeFieldName;
    var convertedFeeldArray = [];

    arrayOfBtFormFields.forEach(function (fieldName) {
        braintreeFieldName = creditCardFieldsMap[fieldName];
        convertedFeeldArray.push(braintreeFieldName);
    });

    return convertedFeeldArray;
};

/**
 * Handles all fields are empty behavior
 */
ErrorHandlingBaseModel.prototype.allFieldsAreEmptyErrorBehavior = function () {
    creditCardFormFieldValidation.makeAllFormFieldsInvalid();
};

/**
 * Handles some fileds are empty behavior
 * @param {Array} btInvalidHostedFields Invalid fileds
 */
ErrorHandlingBaseModel.prototype.someFieldsAreInvalidErrorBehavior = function (btInvalidHostedFields) {
    var convertedFormFields = this.convertBtFromFieldsToDw(btInvalidHostedFields);

    creditCardFormFieldValidation.makeFormFieldsInvalid(convertedFormFields);
};

/**
 * Process and validates case when buyer left hosted feieds empty
 * @param {Object} errorPayload Payload with error information
 * @param {string} braintreeErrorCode Error code
 */
ErrorHandlingBaseModel.prototype.processBraintreeHostedFiledsErrors = function (errorPayload, braintreeErrorCode) {
    switch (braintreeErrorCode) {
        case ALL_HOSTED_FIELDS_FIELDS_EMPTY:
            this.allFieldsAreEmptyErrorBehavior();
            break;

        case HOSTED_FIELDS_FIELDS_INVALID:
            var invalidFields = errorPayload.details.invalidFieldKeys;

            this.someFieldsAreInvalidErrorBehavior(invalidFields);
            break;
        default:
            break;
    }
};

/**
 * Handle "Credit Card" error cases.
 * Cases can be slpited on Custom (errors trigered via "Promise.reject" from our side)
 * and on Braintree (errors from Braintree Client SDK)
 * @param {Object} error Error payload from ".catch()" block
 */
ErrorHandlingBaseModel.prototype.creditCardErrorHandler = function (error) {
    switch (error.name) {
        case 'BraintreeError':
            // Puts error on the top of the Credit Page
            this.showErrorByObject(error);
            this.processBraintreeHostedFiledsErrors(error, error.code);
            break;
        default:
            var errorMsg;

            // Handles the cases when 3ds secure failed
            if (error.customError) {
                errorMsg = this.errorMessages.secure3DFailed;
            // Not braintree errors
            } else {
                errorMsg = error.message ? error.message : error;
            }

            this.showErrorByMessage(errorMsg);
            break;
    }
};

module.exports = ErrorHandlingBaseModel;

