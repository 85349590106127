'use strict';

var braintreeGeneral = require('../../braintreeGeneral');

/**
 * Constructor of VaultMgr model
 * @param {Promise} personalizedClientTokenPromise BT clietn instance promise
 */
function CreditCardVaultManagerModel(personalizedClientTokenPromise) {
    this.customerPaymentMethodsFromVaultPromise = braintreeGeneral.getBtCustomerPaymentMethods(personalizedClientTokenPromise);
}

/**
 * Ger specific payment methods from VaultMgr (both CC and VisaCheckout cards)
 * @param {Promise} customerPaymentMethodsFromVaultPromise Promise with payment methods of buyer taken from VaultMgr
 * @returns {Promise} Promise with array of Cards
 */
function getCardsFromVaultMgrPromise(customerPaymentMethodsFromVaultPromise) {
    return customerPaymentMethodsFromVaultPromise
        .then(function (vaultMgrPaymentMethods) {
            var cardPmArray = [];

            vaultMgrPaymentMethods.forEach(function (paymentMethod) {
                var isCardType = paymentMethod.type === 'CreditCard' || paymentMethod.type === 'VisaCheckoutCard';

                if (isCardType) {
                    cardPmArray.push(paymentMethod);
                }
            });

            return cardPmArray;
        });
}

/**
 * Find CC by data within VaultMgr
 * @param {Promise} customerPaymentMethodsFromVaultPromise Array with Payment Methods from VaultMgr
 * @param {string} lastFour Last four credit card digits
 * @param {string} cardType Credit Card type (Visa, Mastercard)
 * @returns {Promise} Promise with found Credit Card
 */
function getCardFromVaultMgrByData(customerPaymentMethodsFromVaultPromise, lastFour, cardType) {
    return customerPaymentMethodsFromVaultPromise
        .then(function (vaultMgrPaymentMethods) {
            return vaultMgrPaymentMethods.find(function (paymentMethod) {
                var isCardType = paymentMethod.type === 'CreditCard' || paymentMethod.type === 'VisaCheckoutCard';
                var pmDetails = paymentMethod.details;

                return isCardType && pmDetails.cardType.toLowerCase() === cardType && pmDetails.lastFour === lastFour;
            });
        });
}

/**
 * Function which look for CC by passed data inside Storefront CC list
 * @param {Array} storefrontCardList Array with CC from Storefront
 * @param {string} lastFour Last four credit card digits
 * @param {string} cardType Credit Card type (Visa, Mastercard)
 * @returns {Promise} Promise with found credit card
 */
function getCardFromStorefrontByData(storefrontCardList, lastFour, cardType) {
    return storefrontCardList.find(function (storedStorefrontCard) {
        return storedStorefrontCard.lastFour.toString() === lastFour.toString() &&
            storedStorefrontCard.cardType.toLocaleLowerCase() === cardType.toLocaleLowerCase();
    });
}

/**
 * Check is CC exists on the Storefront but not in the Vault
 * @param {Array} storefrontCardList Array with CC from storefront
 * @returns {Promise} Promise with true/false. True in case if CC exists on the Storefront but not in the Vault
 */
CreditCardVaultManagerModel.prototype.getCardsWhichAreNotInVault = function (storefrontCardList) {
    return getCardsFromVaultMgrPromise(this.customerPaymentMethodsFromVaultPromise)
        .then(function (cardPaymentMethods) {
            var arrayOfNotInVaultCards = [];

            storefrontCardList.forEach(function (storefrontCard) {
                var sfCardType = storefrontCard.cardType.toLocaleLowerCase();
                var sfLastFour = storefrontCard.lastFour.toString();
                var foundCard = cardPaymentMethods.find(function (vaultMgrCard) {
                    var pmDetails = vaultMgrCard.details;

                    return pmDetails.cardType.toLocaleLowerCase() === sfCardType && pmDetails.lastFour === sfLastFour;
                });

                if (!foundCard) {
                    arrayOfNotInVaultCards.push(storefrontCard);
                }
            });

            return arrayOfNotInVaultCards;
        });
};

/**
 * Check is CC exists on the Storefront and in the Vault
 * @param {Array} storefrontCardList Array with CC from Storefront
 * @param {string} lastFour Last four credit card digits which buyer wants to add
 * @param {string} cardType Credit Card type (Visa, Mastercard) which buyer wants to add
 * @returns {Promise} Promise with true/fase. True in case if CC is exists in the Vault and on the Storefront
 */
CreditCardVaultManagerModel.prototype.isCreditCardExistOnStorefrontAndVault = function (storefrontCardList, lastFour, cardType) {
    var storefrontCard = getCardFromStorefrontByData(storefrontCardList, lastFour, cardType);
    var vaultMgrCardPromise = getCardFromVaultMgrByData(
        this.customerPaymentMethodsFromVaultPromise,
        lastFour,
        cardType
    );

    return vaultMgrCardPromise
        .then(function (cardFromVaultMgr) {
            return cardFromVaultMgr && storefrontCard;
        });
};

/**
 * Return CC from VaultManager by passed data
 * @param {string} lastFour Last four credit card digits
 * @param {string} cardType Credit Card type (Visa, Mastercard)
 * @returns {Promise} Promise with nonce or empty string
 */
CreditCardVaultManagerModel.prototype.getNonceByCardData = function (lastFour, cardType) {
    return getCardFromVaultMgrByData(
        this.customerPaymentMethodsFromVaultPromise,
        lastFour,
        cardType
    );
};

module.exports = CreditCardVaultManagerModel;
