'use strict';

/**
 * Error Handling constructor
 * @param {Object} errorMessages object with error messages
 */
function ErrorHandlingBaseModel(errorMessages) {
    this.errorMessages = errorMessages;
}

/**
 * ---- General method which should be used for cases when error came from Braintree ----
 * Show error notification by error Object from BT
 * @param {Object} error error object from Bt
 */
ErrorHandlingBaseModel.prototype.showErrorByObject = function (error) {
    var msg = error.message;
    var code = error.code;
    var errorMessage = this.errorMessages[code] || msg || this.errorMessages.CLIENT_GATEWAY_NETWORK;

    this.createErrorNotification(errorMessage);
};

/**
 * ---- General method which should be used for cases when error came from Braintree ----
 * Show error notification by message
 * @param {string} message message to display inside error notification
 */
ErrorHandlingBaseModel.prototype.showErrorByMessage = function (message) {
    this.createErrorNotification(message);
};

/**
 * ---- Error container which will be used on Account ----
 * ---- For all other cases this method will be overwritten by corresponding one ----
 *
 * Method responsible for Error Notification generation
 * @param {string} message message to display inside error notification
 */
ErrorHandlingBaseModel.prototype.createErrorNotification = function (message) {
    var $errorContainer = $('.error-messaging');

    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
    'fade show" role="alert">' +
    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
    '<span aria-hidden="true">&times;</span>' +
    '</button>' + message + '</div>';

    $errorContainer.append(errorHtml);
    $errorContainer.show();
    window.scrollTo(0, 0);
};

/**
 * ---- Method which should hide error container and make it empty. On Account we use this one ----
 * ---- For all other cases this method will be overwritten by corresponding one ----
 *
 * Hide error notification
 */
ErrorHandlingBaseModel.prototype.hideError = function () {
    var $errorContainer = $('.error-messaging');

    $errorContainer.hide();
    $errorContainer.empty();
};

/**
 * Handles SRC error by types
 * @param {Object} error Error object or error message
 */
ErrorHandlingBaseModel.prototype.srcErrorHandler = function (error) {
    if (error.name === 'BraintreeError') {
        this.showErrorByObject(error);
    } else {
        this.showErrorByMessage(error.message || error);
    }
};

module.exports = ErrorHandlingBaseModel;
