'use strict';

/**
 * Sets value of credit card hidden fields needed for server side
 * @param {Object} tokenizePayload Tokenize payload
 */
function setCardHiddenFields(tokenizePayload) {
    var tokenizePayloadDetails = tokenizePayload.details;

    document.querySelector('#braintreeCreditCardNonce').value = tokenizePayload.nonce;
    document.querySelector('#braintreeCardType').value = tokenizePayloadDetails.cardType;
    document.querySelector('#braintreeCardMaskNumber').value = tokenizePayloadDetails.lastFour;
    document.querySelector('#braintreeCardHolder').value = tokenizePayloadDetails.cardholderName;
}

module.exports = {
    setCardHiddenFields
};
