'use strict';

var braintreeGeneral = require('../braintreeGeneral');
var srcHelper = require('./helpers/srcHelper');

// Components
var srcAccount = require('./components/srcAccount');
var srcCardList = require('./components/srcList');
var checkoutComponent = require('./components/srcCheckout');
var cartComponent = require('./components/srcCart');


var btClientInstancePromise;

/**
 * Inits SRC functionality on the Account Page
 */
function initAccount() {
    srcAccount.init(btClientInstancePromise);
}

/**
 * Inits SRC functionality on the Cart Page
 */
function initCartCheckout() {
    cartComponent.init(btClientInstancePromise);
}

/**
 * Inits SRC functionality on the Billing Page
 */
function initBillingCheckout() {
    checkoutComponent.init(btClientInstancePromise);
    srcCardList.init();
}

/**
 * Inits braintreeSrcModelBuilder on SRC level
 */
function init() {
    var braintreeSrcConfigs = srcHelper.getBraintreeSRCButtonConfigs();
    var clientToken;

    if (braintreeSrcConfigs) {
        clientToken = braintreeSrcConfigs.clientToken;
        btClientInstancePromise = braintreeGeneral.createClientInstance(clientToken);
    }
}

module.exports = {
    initAccount,
    initCartCheckout,
    initBillingCheckout,
    init
};
