'use strict';

var braintreeLpmModelBuilder = require('./braintreesdk/braintreeLpmModelBuilder');

var lpmErrorHandler = require('./errorhandler/lpmErrorHandler');
var lpmPaymentProcessing = require('./components/lpmPaymentProcessing');

/**
 * Inits LPM on the Billing-Checkout Page
 */
function init() {
    var braintreeLpmModelResponse = braintreeLpmModelBuilder.init();

    lpmErrorHandler.init();

    lpmPaymentProcessing.init(braintreeLpmModelResponse.btLpmModel);
}

module.exports = {
    init
};
