'use strict';

var PayPalExpressModel = require('../models/payPalExpressModel');
var ErrorHandlingMiniCartModel = require('../errorhandler/errorHandlingMiniCartModel');
var paymentMethodGeneral = require('../../paymentMethodGeneral');
var payPalStaticButtonComponent = require('./payPalStaticImage');
var paypalMinicartButtonBehavior = require('./payPalButtonDisplayBehavior');

// eslint-disable-next-line valid-jsdoc
/**
 * Init PayPal on the MiniCart Page
 * @returns stop function execution in case if PayPal button wasn't rendered
 */
function initPayPalOnMiniCart() {
    var $paypalMinicartButton = document.querySelector('.paypalMinicartButton');
    var $payPalStaticButton = document.querySelector('.paypalMinicartButton .braintree-static-paypal-button');
    var payPalButtonSelector = '.paypalMinicartButton .js_braintree_paypal_cart_button';
    var $payPalButton = document.querySelector(payPalButtonSelector);

    // Case when buyer remove product from the Cart
    $('body').on('cart:update', function () {
        paypalMinicartButtonBehavior.paypalMinicartButtonBehavior($paypalMinicartButton);
    });

    // PayPal Statick button rendering
    if (!$payPalButton && $payPalStaticButton) {
        paypalMinicartButtonBehavior.paypalMinicartButtonBehavior($paypalMinicartButton);
        // Init PayPal Static Button component
        payPalStaticButtonComponent.initOnMiniCart();

        return;
        // Do not init PayPal button in case if it was disabled via Site Pref config
    } else if (!$payPalButton) {
        return;
    }

    paypalMinicartButtonBehavior.paypalMinicartButtonBehavior($paypalMinicartButton);

    var payPalConfigurations = JSON.parse($payPalButton.getAttribute('data-braintree-config'));
    var isPayPalButtonWasInited = JSON.parse($payPalButton.getAttribute('data-is-inited'));

    // Do not init PayPal button if it was already inited or PayPal configs are empty
    if (isPayPalButtonWasInited || typeof payPalConfigurations !== 'object' || payPalConfigurations === null) {
        return;
    }

    var isVaultMode = payPalConfigurations.options.flow !== 'checkout';
    var requestBillingAgreement = Boolean(payPalConfigurations.options.requestBillingAgreement);
    var isFraudToolsEnabled = payPalConfigurations.isFraudToolsEnabled;
    var $csrfToken = document.querySelector('.braintree-cart-paypal-buttons-wrap #csrf_token');
    var $loaderContainer = document.querySelector('.braintreePayPalLoader');

    var clientInstancePromise = paymentMethodGeneral.createGeneralClientInstancePromise();

    var ErrorHandling = new ErrorHandlingMiniCartModel(payPalConfigurations.messages);
    var PayPalExpress = new PayPalExpressModel(
        ErrorHandling,
        clientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        isVaultMode,
        requestBillingAgreement,
        isFraudToolsEnabled,
        $csrfToken,
        $loaderContainer
    );

    // Generate Fraud Data if enabled
    PayPalExpress.generateFraudData();

    // Init PayPal button
    PayPalExpress.initPayment();

    // Indicate that PayPal button was already inited.
    $payPalButton.setAttribute('data-is-inited', true);
}

/**
 * Init PayPal button on the MiniCart Page
 */
function init() {
    var $minicartPopover = document.querySelector('.minicart .popover');
    var observer;

    // To avoid executino of this code on checkout page
    if ($minicartPopover) {
        observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.addedNodes.length < 2) {
                    return;
                }

                initPayPalOnMiniCart();
            });
        });

        observer.observe($minicartPopover, { childList: true });
    }
}

module.exports = {
    init
};
