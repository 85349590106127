'use strict';

var srcHelper = require('../helpers/srcHelper');
var loaderInstance = require('../../loaderHelper');

// Models
var SrcCartModel = require('../models/srcCartModel');
var ErrorHandlingCartModel = require('../errorhandler/errorHandlingCartModel');

var $braintreeCartSrcButton = document.querySelector('.braintree-cart-src-button');

/**
 * Inits SRC account functionality on the Cart Page
 * @param {Promise} clientInstancePromise client instance promise
 */
function init(clientInstancePromise) {
    var $braintreeSrcLoader = document.querySelector('.braintreeSrcLoader');
    var $totalAmount = document.querySelector('.grand-total');
    var srcLoader = loaderInstance($braintreeSrcLoader);
    var srcButtonConfigurations = srcHelper.getBraintreeSRCButtonConfigs();
    var $braintreeSrcDeviceDataInput = document.querySelector('input[name=braintreeSrcDeviceDataInput]');
    var errorHandlingCartModel = new ErrorHandlingCartModel(srcButtonConfigurations.messages);

    var SrcCheckoutModelInstance = new SrcCartModel(
        clientInstancePromise,
        srcButtonConfigurations,
        $braintreeSrcDeviceDataInput,
        srcLoader,
        errorHandlingCartModel
    );

    // Hides errors on the Cart Page
    $braintreeCartSrcButton.addEventListener('click', errorHandlingCartModel.hideError);

    SrcCheckoutModelInstance.initSrcButton();

    // Tracks cases when total amount equal zero and throw an error
    srcHelper.zeroAmountSrcButtonBehavior($totalAmount, errorHandlingCartModel);
}

module.exports = {
    init
};
