'use strict';

var braintreeApplePayModelBuilder = require('./braintreesdk/braintreeApplePayModelBuilder');

var applePayPaymentProcessing = require('./components/applePayPaymentProcessing');

// Models
var ErrorHandlingBaseModel = require('./errorhandler/errorHandlingBaseModel');
var ErrorHandlingCheckoutModel = require('./errorhandler/errorHandlingCheckoutModel');

var applePayErrorMessages = require('./constants/errorMessages');

var braintreeApplePayModelResponse;
var applePayMessages;

/**
 * Inits Apple Pay on the Checkout Page
 */
function initCheckout() {
    var errorHandlingCheckoutModel = new ErrorHandlingCheckoutModel(applePayMessages);

    if (!braintreeApplePayModelResponse || braintreeApplePayModelResponse.error) {
        var error = braintreeApplePayModelResponse.error || applePayErrorMessages.NO_APPLE_PAY_BRAINTREE_CONFIGURATIONS;
        errorHandlingCheckoutModel.applePayErrorHandler(error);
    }

    applePayPaymentProcessing.init(
        braintreeApplePayModelResponse.btApplePayModel,
        errorHandlingCheckoutModel
    );
}

/**
 * Inits Apple Pay on the Cart Page
 */
function initCart() {
    var errorHandlingBaseModel = new ErrorHandlingBaseModel(applePayMessages);

    if (!braintreeApplePayModelResponse || braintreeApplePayModelResponse.error) {
        var error = braintreeApplePayModelResponse.error || applePayErrorMessages.NO_APPLE_PAY_BRAINTREE_CONFIGURATIONS;
        errorHandlingBaseModel.applePayErrorHandler(error);
    }

    applePayPaymentProcessing.init(
        braintreeApplePayModelResponse.btApplePayModel,
        errorHandlingBaseModel
    );
}

/**
 * Inits Apple Pay on the Cart/Billing-Checkout Pages
 */
function init() {
    braintreeApplePayModelResponse = braintreeApplePayModelBuilder.init();

    if (!braintreeApplePayModelResponse.error) {
        applePayMessages = braintreeApplePayModelResponse.btApplePayConfigs.messages;
    }
}

module.exports = {
    init,
    initCart,
    initCheckout
};
