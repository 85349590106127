'use strict';

/**
 * Uncheck Save Credit Card checkbox
 * @param {boolean} save true/false
 */
function saveCreditCard(save) {
    var isSave = save ? true : false; // eslint-disable-line

    document.querySelector('#braintreeSaveCreditCard').checked = isSave;
}

/**
 * Hides or Display 'braintreeSaveCardContainer' container
 * @param {boolean} hide true/false
 */
function hideSaveCreditCardBlock(hide) {
    var isHide = hide ? true : false; // eslint-disable-line

    document.querySelector('#braintreeSaveCardContainer').style.display = isHide ? 'none' : 'block';
}

/**
 * Returns Braintree Credit Card configs
 * @returns {Object} Braintree Credit Card configs
 */
function getCheckoutBraintreeCreditCardConfigs() {
    var braintreeCreditCardFieldsConfigs = null;

    try {
        braintreeCreditCardFieldsConfigs = JSON.parse(document.querySelector('.js_braintree_creditCardFields').getAttribute('data-braintree-config'));
    } catch (error) {
        return braintreeCreditCardFieldsConfigs;
    }

    return braintreeCreditCardFieldsConfigs;
}

/**
 * Converts Credit Card type to one style: first letter in upper case
 * to make session card type look like saved CC type
 * ex: MasterCard -> Mastercard
 * @param {string} type Card type
 * @returns {string} Converted card type
 */
function convertCreditCardType(type) {
    var cardType = type.toLowerCase();

    return cardType.replace(/_/g, ' ').replace(cardType.charAt(0), (cardType.charAt(0)).toUpperCase());
}

module.exports = {
    getCheckoutBraintreeCreditCardConfigs,
    saveCreditCard,
    hideSaveCreditCardBlock,
    convertCreditCardType
};
