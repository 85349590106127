'use strict';

var lpmErrorMessages = require('../constants/errorMessages');
var lpmHelper = require('../helpers/lpmHelper');

var $topErrorMessageText;
var $topErrorMessageWrapper;
var braintreeErrorMessages;

/**
 * Shows error message on the top of the Checkout Page
 * @param {string} message Error message
 */
function createErrorNotification(message) {
    $topErrorMessageText.textContent = '';
    $topErrorMessageWrapper.style.display = 'block';
    $topErrorMessageText.append(message);
    window.scrollTo(0, 0);
}

// General method which should be used for cases when error came from Braintree
function showErrorByObject(error) {
    var msg = error.message;
    var code = error.code;
    var errorMessage = braintreeErrorMessages[code] || msg || braintreeErrorMessages.CLIENT_GATEWAY_NETWORK;

    createErrorNotification(errorMessage);
}

/**
 * Shows error by message
 * @param {string} message Error Message
 */
function showErrorByMessage(message) {
    createErrorNotification(message);
}

/**
 * Process error form Braintree or Braintree-PaymentConfirm endpoint
 * @param {Object} error Error object
 */
function processStartLpmPaymentError(error) {
    if (error.name === 'BraintreeError') {
        showErrorByObject(error);
    } else {
        showErrorByMessage(error.responseText);
    }
}

/**
 * Hides error message form the top of the Checkout Page
 */
function hideError() {
    $topErrorMessageWrapper.style.display = 'none';
    $topErrorMessageText.innerHTML = '';
}

/**
 * Creates and throws an error if order amount is 0
 * @param {string} paymentMethodName Payment method name
 */
function handleZeroAmountOnCheckout(paymentMethodName) {
    var zeroAmountErrorMsg = lpmErrorMessages.ORDER_TOTAL_0 + paymentMethodName;

    throw zeroAmountErrorMsg;
}

/**
 * Inits LPM error handler components
 */
function init() {
    $topErrorMessageText = document.querySelector('.error-message-text');
    $topErrorMessageWrapper = document.querySelector('.error-message');
    braintreeErrorMessages = lpmHelper.getBraintreeLpmConfigs().messages;
}

module.exports = {
    processStartLpmPaymentError,
    showErrorByObject,
    showErrorByMessage,
    createErrorNotification,
    handleZeroAmountOnCheckout,
    hideError,
    init
};
