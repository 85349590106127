'use strict';

var PayPalBaseModel = require('./payPalBaseModel');
var payPalExpressModelHelper = require('../helpers/payPalExpressModelHelper');
var billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');
var requestHelper = require('../helpers/payPalRequestsHelperGlobal');

/**
 * PayPal express model constructor
 * @param {Object} errorHandlingModel error handling model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {boolean} isVaultMode "true" in case if vault mode is enabled
 * @param {boolean} requestBillingAgreement "true" in case if billing agreement should be requested
 * @param {boolean} isFraudToolsEnabled "true" in case if fraud tools is enabled
 * @param {Object} $csrfToken CSRF token js container
 * @param {Object} $loaderContainer loader js container
 */
function PayPalExpressModel(
    errorHandlingModel,
    btClientInstancePromise,
    payPalButtonSelector,
    payPalConfigurations,
    isVaultMode,
    requestBillingAgreement,
    isFraudToolsEnabled,
    $csrfToken,
    $loaderContainer
) {
    // Init PayPalBaseModel constructor
    PayPalBaseModel.call(
        this,
        errorHandlingModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        isVaultMode,
        requestBillingAgreement,
        isFraudToolsEnabled,
        $loaderContainer
    );

    this.$csrfToken = $csrfToken;
}

// ES5 inheritance
PayPalExpressModel.prototype = Object.create(PayPalBaseModel.prototype);


// For more details see same method from "PayPalBaseModel"
// Create and submit billing data to the server. Redirect on order reivew checkout step
PayPalExpressModel.prototype.onApprovePaymentCallback = function (payload) {
    // Calling parrent method to proceed with PayPal payload validation
    var baseResponse = PayPalBaseModel.prototype.onApprovePaymentCallback.call(this, payload);

    // Error will be handled on BaseModel level
    if (baseResponse && baseResponse.error) {
        return;
    }

    this.loader.show();

    var PayPalExpressInstance = this;
    var payloadDetails = payload.details;
    var $payPalButtonWrapper = document.querySelector('.braintree-cart-paypal-buttons-wrap');
    var paypalCheckoutFormFields = JSON.parse($payPalButtonWrapper.getAttribute('data-paypal-checkout-form-fields'));
    var billingAddressData = billingFormHelper.createBillingAddressData(payloadDetails);
    var billingFromData;

    // Create form data only with billing address
    billingFromData = billingFormHelper.createBillingAddressFormData(paypalCheckoutFormFields, billingAddressData);

    billingFormHelper.appendCsrfTokenToFormData(billingFromData, PayPalExpressInstance.$csrfToken);

    // It will generate FFraud data only if it Fraud Data collection was enabled
    PayPalExpressInstance.fraudDataPromise
        .then(function (data) {
            if (!data.fraudDataCollectionDisabled) {
                billingFormHelper.appendFraudDataToFormData(billingFromData, data.deviceData);
            }
        })
        .then(function () {
            var shippingAddressData;

            // If shipping address was presented in PayPal payload, create a shipping address object,
            // stringify it and store in FormData as an input
            if (payloadDetails.shippingAddress) {
                shippingAddressData = payPalExpressModelHelper.createShippingAddressData(payloadDetails);

                payPalExpressModelHelper.appendShippingDataAsStringToFormData(billingFromData, shippingAddressData);
            }

            // Create a shipping address object, stringify it and store in FormData as an input
            billingFormHelper.appendBillingAddressAsStringToFormData(billingFromData, billingAddressData);

            billingFormHelper.appendEmailAsStringToFormData(billingFromData, payloadDetails.email);

            billingFormHelper.appendBtPaymentFieldsToFormData(billingFromData, payload);
        })
        .then(function () {
            // Submit customer form with email (CheckoutServices-SubmitCustomer)
            // as we skip step "login via guest/registered user" while express checkout
            // email is set only in case of guest checkout and if email is not already set
            payPalExpressModelHelper.submitCustomerForm(payloadDetails.email);
        })
        .then(function () {
            requestHelper.submitPaymentDataToServerPromise(
                PayPalExpressInstance.submitPaymentUrl,
                billingFromData,
                PayPalExpressInstance.placeOrderStageUrl,
                PayPalExpressInstance.loader,
                PayPalExpressInstance.errorHandlingModel
            );

            PayPalExpressInstance.loader.hide();
        });
};

module.exports = PayPalExpressModel;
