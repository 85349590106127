'use strict';

/**
 * Returns Braintree LPM configs
 * @returns {Object} Braintree LPM configs
 */
function getBraintreeLpmConfigs() {
    var braintreeLpmConfigs = null;

    try {
        braintreeLpmConfigs = JSON.parse(document.querySelector('.js_braintree_lpm_button').getAttribute('data-braintree-config'));
    } catch (error) {
        return braintreeLpmConfigs;
    }

    return braintreeLpmConfigs;
}

module.exports = {
    getBraintreeLpmConfigs
};
