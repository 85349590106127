'use strict';

var applePayHelper = require('../helpers/applePayHelper');
var braintreeGeneral = require('../../braintreeGeneral');
var braintreeApplePayModel = require('./braintreeApplePayModel');

var applePayErrorMessages = require('../constants/errorMessages');

/**
 * Initialize Braintree Apple Pay Model. Pattern Builder is used
 * @returns {Object} Object with booleam "error" flag and "message" attribute with error message
 */
function init() {
    var braintreeApplePayConfigs = applePayHelper.getBraintreeApplePayConfigs();
    var clientToken;
    var btClientInstancePromise;
    var response = {
        error: false,
        message: '',
        btApplePayModel: null,
        btApplePayConfigs: null
    };

    if (braintreeApplePayConfigs) {
        clientToken = braintreeApplePayConfigs.clientToken;
        btClientInstancePromise = braintreeGeneral.createClientInstance(clientToken);

        braintreeApplePayModel.init(
            btClientInstancePromise,
            braintreeApplePayConfigs.isFraudToolsEnabled
        );

        response.btApplePayModel = braintreeApplePayModel;
        response.btApplePayConfigs = braintreeApplePayConfigs;
    } else {
        response.error = true;
        response.message = applePayErrorMessages.NO_APPLE_PAY_BRAINTREE_CONFIGURATIONS;
    }

    return response;
}

module.exports = {
    init
};
