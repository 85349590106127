'use strict';

var $googlepayButton = document.querySelector('.js_braintree_googlepay_button');
var $googlepayContent = document.querySelector('.js_braintree_googlepayContent');

// Helpers
var helper = require('../../helper');
var googlePayHelper = require('../helpers/googlePayHelper');

/**
 * Shows session Google Pay account on the Billing Page
 * @param {string} cardDescription Session card description
 */
function showGooglepayAccount(cardDescription) {
    var $newGPAccount = document.getElementById('newGPAccount');
    var $gpAccount = document.querySelector('.form-group.braintree_used_googlepay_account');
    var $sessionAccount = document.querySelector('#sessionGPAccount');

    $newGPAccount.removeAttribute('selected');

    // Sets new session account
    $sessionAccount.classList.remove('used-googlepay-account-hide');
    $sessionAccount.classList.add('used-googlepay-account');
    $sessionAccount.text = cardDescription;
    $sessionAccount.selected = true;
    $sessionAccount.setAttribute('data-session-account', 'true');

    $gpAccount.classList.remove('used-googlepay-account-hide');
    $gpAccount.classList.add('used-googlepay-account');

    $googlepayButton.style.display = 'none';
    $googlepayContent.setAttribute('data-paypal-is-hide-continue-button', false);
    helper.continueButtonToggle(false);
}

/*
    Remove method was used and change appearance of Google Pay tab
**/
function removeSessionNonce() {
    var $gpAccount = document.querySelector('.form-group.braintree_used_googlepay_account');
    $gpAccount.classList.remove('used-googlepay-account');
    $gpAccount.classList.add('used-googlepay-account-hide');

    var $sessionAccount = document.querySelector('#sessionGPAccount');
    $sessionAccount.classList.add('used-googlepay-account-hide');
    $sessionAccount.classList.remove('used-googlepay-account');
    $sessionAccount.value = '';
    $sessionAccount.text = '';
    $sessionAccount.setAttribute('data-session-account', false);
    $sessionAccount.setAttribute('data-save', false);

    document.querySelector('#braintreeGooglePayNonce').value = '';
    document.querySelector('#braintreeGooglePayBillingAddress').value = '';
    document.querySelector('#braintreeGooglepayPaymentType').value = '';
    document.querySelector('#braintreeGooglePayDeviceData').value = '';

    document.querySelector('#newGPAccount').selected = true;
    googlePayHelper.googlePayContentTabHandler(false);
}

module.exports = {
    showGooglepayAccount,
    removeSessionNonce
};
