'use strict';

var SrcBaseModel = require('./srcBaseModel');
var creditCardVaultManager = require('../../creditcard/components/creditCardVaultManager');

/**
 * Check whether new card is presented on storefront and in VaultMgr. If yes - render error message.
 * @param {Promise} clientInstancePromise client instance promise
 * @param {Object} btPayload payload from Braintree
 * @param {Object} srcMessages object with errors
 * @returns {Promise} promise with reject status in case if new card already exists in VaultMgr and on Storefront (buyer's wallet)
 */
function rejectIfCardExistsInVault(clientInstancePromise, btPayload, srcMessages) {
    var payloadDetails = btPayload.details;
    var lastFour = payloadDetails.lastFour.toString();
    var type = payloadDetails.cardType.toLowerCase();

    return creditCardVaultManager.isNewCardCanBeStored(clientInstancePromise, lastFour, type)
        .then(function (newCardCanBeStored) {
            if (!newCardCanBeStored) {
                // eslint-disable-next-line no-undef
                return Promise.reject(srcMessages.CARD_ALREADY_EXIST_ERROR_MESSAGE);
            }
        });
}

// See description of "srcBaseModel" for more info
function SrcAccountModel(clientInstancePromise, srcButtonConfigurations, $braintreeSrcDeviceDataInput, loader, errorHandlingBaseModel) {
    SrcBaseModel.call(
        this,
        clientInstancePromise,
        srcButtonConfigurations,
        $braintreeSrcDeviceDataInput,
        loader,
        errorHandlingBaseModel
    );
}

SrcAccountModel.prototype = Object.create(SrcBaseModel.prototype);

// See description of "srcBaseModel" for more info
// Method set nonce data in to hidden input and submit the form to the server
SrcAccountModel.prototype.onTokenizeCallback = function (srcPayload) {
    SrcBaseModel.prototype.onTokenizeCallback.call(this, srcPayload);
    var srcLoader = this.loader;
    var errorHandlingBaseModel = this.errorHandlingModel;
    var $addSrcAccountForm = $('.js_braintree_addSrcAccountForm');
    var addSrcFormUrl = $addSrcAccountForm.attr('action');
    var $srcNonce = document.querySelector('#braintreeSrcNonce');

    srcLoader.show();

    $srcNonce.value = srcPayload.nonce;

    return rejectIfCardExistsInVault(this.clientInstancePromise, srcPayload, this.srcButtonConfigurations.messages)
        .then(function () {
            var formData = $addSrcAccountForm.serialize();

            return $.ajax({
                url: addSrcFormUrl,
                type: 'post',
                dataType: 'json',
                data: formData
            })
                .then(function (response) {
                    if (!response.success) {
                        errorHandlingBaseModel.showErrorByMessage(response.error);
                    } else {
                        location.href = response.redirectUrl;
                    }
                });
        })
        .catch(function (error) {
            srcLoader.hide();
            errorHandlingBaseModel.showErrorByMessage(error);
        })
        .finally(function () {
            srcLoader.hide();
        });
};

// See description of "srcBaseModel" for more info
SrcAccountModel.prototype.initSrcButton = function () {
    var SrcAccountModelInstance = this;

    SrcAccountModelInstance.braintreeSrcSdkButtonInit(SrcAccountModelInstance)
        .catch(function (error) {
            SrcAccountModelInstance.errorHandlingModel.srcErrorHandler(error);
        });
};

module.exports = SrcAccountModel;
