'use strict';

var lpmModel = require('../braintreesdk/braintreeLpmModel');

var processingMsg = document.querySelector('.processingMsg');
var finalProcessingMsg = document.querySelector('.finalProcessingMsg');

/**
 * Whether 'details' parameter is exist returns 'details' or shipping address if not exist
 *
 * @param {Object} details Details object from StartPaymentPayload
 * @param {string} email Email Address
 * @returns {Object} Details object
 */
function getPaymentDetailsParameter(details, email) {
    var shippingAddress = lpmModel.getShippingAddress();
    shippingAddress.email = email;

    return jQuery.isEmptyObject(details) ? shippingAddress : details;
}

/**
 * Shows processing message on the Billing Page
 */
function showProcessingMsg() {
    processingMsg.style.display = 'block';
}

/**
 * Hides processing message on the Billing Page
 */
function hideProcessingMsg() {
    processingMsg.style.display = 'none';
}

/**
 * Show final processing message on the Billing Page
 */
function showFinalProcessingMsg() {
    finalProcessingMsg.style.display = 'block';
}

/**
 *
 * @param {Object} lpmConfigs LPM configs object
 * @param {string} paymentMethodName Payment method name
 * @param {string} email Email address
 * @param {Object} startPaymentPayload StartPaymnetPayload
 * @returns {Promise} Promise resolved with 'redirectUrl'
 */
function confirmPayment(lpmConfigs, paymentMethodName, email, startPaymentPayload) {
    return $.ajax({
        url: lpmConfigs.paymentConfirmUrl,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
            nonce: startPaymentPayload.nonce,
            lpmName: paymentMethodName,
            details: getPaymentDetailsParameter(startPaymentPayload.details, email),
            deviceData: JSON.stringify({ correlation_id: startPaymentPayload.correlationId })
        })
    });
}

/**
 * Creates a redirecting form to Order-Confirm endpoint
 * @param {Object} param  The helping object for creating a from
 * @returns {Object} form element
 */
function createConfirmForm(param) {
    var form = $('<form>')
        .appendTo(document.body)
        .attr({
            method: 'POST',
            action: param.url
        });

    $('<input>')
        .appendTo(form)
        .attr({
            name: 'orderID',
            value: param.orderID
        });

    $('<input>')
        .appendTo(form)
        .attr({
            name: 'orderToken',
            value: param.orderToken
        });

    return form;
}

/**
 * Prepare and submits form in order to confirm order with Lpm
 * @param {string} redirectUrl Redirect Url
 */
function processLpmConfirmForm(redirectUrl) {
    var splitUrl = redirectUrl.split('?');
    var url = splitUrl[0];
    var paramsString = splitUrl[1];
    var searchParams = new URLSearchParams(paramsString);
    var formParam = {
        orderID: searchParams.get('orderID'),
        orderToken: searchParams.get('orderToken'),
        url: url
    };
    var form = createConfirmForm(formParam);

    form.submit();
}

module.exports = {
    getPaymentDetailsParameter,
    showProcessingMsg,
    hideProcessingMsg,
    showFinalProcessingMsg,
    confirmPayment,
    processLpmConfirmForm
};
