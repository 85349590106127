'use strict';

/* global braintreeUtils braintree $ ApplePaySession */

// Helpers
var loaderInstance = require('../../loaderHelper');
var applePayHelper = require('../helpers/applePayHelper');
var applePayPaymentProcessingHelper = require('../helpers/applePayPaymentProcessingHelper');
var helper = require('../../helper');

var applePayPaymentProcessingConstants = require('../constants/applePayPaymentProcessingConstants');

// Components
var applePayCart = require('./applePayCart');
var applePayCheckout = require('./applePayCheckout');

var $braintreeApplePayLoader = document.querySelector('.braintreeApplePayLoader');
var $applePayButton = document.querySelector('.js_braintree_applepay_button');
var $continueButton = document.querySelector('button.submit-payment');
var applePayEnabledOnCheckout = Boolean(document.querySelector('.js_braintree_applepayContent'));

// global variables
var loader;
var applePayModel;
var applePayConfigs;
var errHandlingModel;

/**
 * Functionality over tokenized payment
 * @param {Object} paymentData Apple Pay payment data
 */
function onTokenizedPayment(paymentData) {
    var applePayFlow = applePayPaymentProcessingHelper.getApplePayFlowId();

    helper.removeActiveSessionPayment();

    switch (applePayFlow) {
        case applePayPaymentProcessingConstants.FLOW_CHECKOUT_NAME:
            applePayCheckout.init(paymentData);
            $continueButton.click();
            break;
        case applePayPaymentProcessingConstants.FLOW__CART_NAME:
            applePayCart.init(paymentData, errHandlingModel);
            break;
        default:
            break;
    }
}
/**
 * Creates Apple Pay session. Opens and processes Apple Pay payment window.
 */
function createApplePaySession() {
    // Updates order amount for paymentDataRequest
    applePayModel.updateApplePayAmount(applePayConfigs);

    // Creates a configuration object for creating Apple pay session
    var paymentDataRequest = applePayModel.createPaymentDataRequest(applePayConfigs);

    if (applePayConfigs.isRequiredBillingContactFields) {
        paymentDataRequest.requiredBillingContactFields = ['postalAddress', 'name'];
    }

    if (applePayConfigs.isRequiredShippingContactFields) {
        paymentDataRequest.requiredShippingContactFields = ['postalAddress', 'name', 'phone', 'email'];
    }

    // Creates new Apple Pay session
    var session = new ApplePaySession(1, paymentDataRequest);

    // Validates merchant website
    session.onvalidatemerchant = function (event) {
        applePayModel.onValidateMerchant(event, applePayConfigs)
            .then(function (merchantSession) {
                session.completeMerchantValidation(merchantSession);
            })
            .catch(function (error) {
                errHandlingModel.applePayErrorHandler(error);
                session.abort();
            });
    };

    // Tokenizes an Apple Pay payment
    session.onpaymentauthorized = function (event) {
        loader.show();

        applePayModel.onPaymentAuthorized(event)
            .then(function (tokenizedPayload) {
                session.completePayment(ApplePaySession.STATUS_SUCCESS);

                var TokenizedPaymentData = applePayPaymentProcessingHelper.getTokenizedPaymentData(event, tokenizedPayload);

                onTokenizedPayment(TokenizedPaymentData);

                loader.hide();
            })
            .catch(function (error) {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                loader.hide();
                errHandlingModel.applePayErrorHandler(error);
            });
    };

    session.begin();
}

/**
 * Call back function to be resolved when Apple Pay payment button is clicked
 */
function onApplePayButtonClick() {
    errHandlingModel.hideError();

    // Billing flow
    if (applePayEnabledOnCheckout) {
        // Validates email and phone fields on the Billing page
        if (applePayHelper.isBilligFiledsValid()) {
            createApplePaySession();
        }
    // Cart flow
    } else {
        createApplePaySession();
    }
}

/**
 * Init and add Apple Pay on the Cart or Billing page
 */
function initApplePay() {
    loader.show();

    var result = applePayModel.collectFraudData()
        .then(function (fraudDataPayload) {
            applePayHelper.setApplePayDeviceData(fraudDataPayload);
            loader.hide();

            return applePayModel.initApplePay(ApplePaySession)
                .then(function (canMakePayments) {
                    loader.hide();
                    applePayHelper.showApplePayButton();

                    if (!canMakePayments) {
                        applePayHelper.makeApplePayButtonDisabled();
                    } else {
                        $applePayButton.addEventListener('click', onApplePayButtonClick);
                    }
                });
        });
    result
        .catch(function (error) {
            loader.hide();
            errHandlingModel.applePayErrorHandler(error);
        });
}

/**
 * Inits Apple Pay payment processing components
 * @param {Object} braintreeApplePayModel Apple Pay model
 * @param {Constructor} errorHandlingModel Error handling model
 */
function init(braintreeApplePayModel, errorHandlingModel) {
    loader = loaderInstance($braintreeApplePayLoader);
    applePayModel = braintreeApplePayModel;
    applePayConfigs = JSON.parse($applePayButton.getAttribute('data-braintree-config'));
    errHandlingModel = errorHandlingModel;

    if (window.ApplePaySession) {
        initApplePay();
    } else {
        applePayHelper.hideApplePayTab();
    }
}

module.exports = {
    init
};
