'use strict';
var $continueButton = document.querySelector('button.submit-payment');

/**
 * Init the event in order to update the cart grant total price
 */
function initCartUpdateEvent() {
    var $grantTotal = document.querySelector('.grand-total');

    if ($grantTotal) {
        var currentGrantTotalValue = $grantTotal.textContent;

        $('body').on('cart:update', function () {
            var newGrantTotalValue = $grantTotal.textContent;

            if (newGrantTotalValue !== '' && newGrantTotalValue !== currentGrantTotalValue) {
                currentGrantTotalValue = newGrantTotalValue;
                var updateCartTotals = document.createEvent('Event');
                updateCartTotals.initEvent('updateCartTotals', true, true);

                document.querySelector('body').addEventListener('updateCartTotals', function () {
                    'braintree:updateCartTotals';
                }, false);
                document.querySelector('body').dispatchEvent(updateCartTotals);
            }
        });
    }
}

/**
 * Depends on the value flag, sets style.display to the $continueButton
 * @param {boolean} flag Boolean value
 * @returns {void}
 */
function continueButtonToggle(flag) {
    var stage = window.location.hash.substring(1);

    if (stage === 'placeOrder' || stage === 'shipping' || stage === null || stage === '') {
        return;
    }

    $continueButton.style.display = flag ? 'none' : '';
}

/**
 * Handles payment method tab changing
 * @param {Object} currentTab Choosen payment method tab
 */
function paymentMethodChangeHandle(currentTab) {
    document.querySelectorAll('.payment-options[role=tablist] a[data-toggle="tab"]').forEach(function (el) {
        var $tabContent = document.querySelector(el.getAttribute('href'));

        if (el === currentTab) {
            $tabContent.querySelectorAll('input, textarea, select').forEach(function (tab) {
                tab.removeAttribute('disabled', 'disabled');
            });
            $tabContent.querySelectorAll('select.no-disable').forEach(function (tab) {
                tab.setAttribute('disabled', 'disabled');
            });
            continueButtonToggle(JSON.parse($tabContent.getAttribute('data-paypal-is-hide-continue-button')));
        } else {
            $tabContent.querySelectorAll('input, textarea, select').forEach(function (tab) {
                tab.setAttribute('disabled', 'disabled');
            });
        }
    });
}

/**
 * Return payment method name in lovercase
 * @param {string} paymentMethodName Payment method name
 * @returns {string} Paymnet method name
 */
function getPaymentMethodToLowerCase(paymentMethodName) {
    var paymentMethod = paymentMethodName.split('_');
    if (paymentMethod.length === 1) {
        return paymentMethodName;
    }
    paymentMethod.forEach(function (element, index) {
        paymentMethod[index] = element.charAt(0) + element.slice(1).toLocaleLowerCase();
    });
    return paymentMethod[1] ?
        paymentMethod[0] + ' ' + paymentMethod[1] :
        paymentMethod[0];
}

/**
 * Updates checkout view
 * @param {Object} e Event object
 * @param {Object} data Data object
 */
function updateCheckoutView(e, data) {
    var $paymentSummary = document.querySelector('.summary-details .braintree-payment-details');
    var htmlToAppend = '';
    var order = data.order;
    var payment = order.billing.payment;

    if (payment && payment.selectedPaymentInstruments
        && payment.selectedPaymentInstruments.length > 0) {
        var selectedPaymentInstrument = payment.selectedPaymentInstruments[0];
        var paymnetMethodId = selectedPaymentInstrument.paymentMethod;

        htmlToAppend += '<div>' + getPaymentMethodToLowerCase(paymnetMethodId) + '</div>';
        if (selectedPaymentInstrument.maskedCreditCardNumber) {
            htmlToAppend += '<div>' + selectedPaymentInstrument.maskedCreditCardNumber + '</div>';
        }
        if (paymnetMethodId === 'PayPal') {
            htmlToAppend += '<div>' + selectedPaymentInstrument.braintreePaypalEmail + '</div>';
        }
        if (paymnetMethodId === 'Venmo') {
            htmlToAppend += '<div>' + selectedPaymentInstrument.braintreeVenmoUserId + '</div>';
        }
        if (selectedPaymentInstrument.type) {
            htmlToAppend += '<div>' + selectedPaymentInstrument.type + '</div>';
        }
        htmlToAppend += '<div>' + order.priceTotal.charAt(0) + selectedPaymentInstrument.amount + '</div>';
    }

    if ($paymentSummary) {
        $paymentSummary.innerHTML = htmlToAppend;
    }
}

/**
 * Validates whether input field is valid
 * @param {Object} field Input filed
 * @returns {boolean} true/false
 */
function isValidInputField(field) {
    if (!field.checkValidity()) {
        if (!field.classList.contains('is-invalid')) {
            field.classList.add('is-invalid');
        }
        return false;
    }
    if (field.checkValidity() && field.classList.contains('is-invalid')) {
        field.classList.remove('is-invalid');
    }
    return true;
}
/*
    Adding *active* line to the tab-content class in a case if it isn't already active
    Use case: customer checkout from Cart (page) and
    (under the place Order page) hit the 'edit' button
**/
function updatePaymentMethodTab() {
    let paymentMethodName = document.querySelectorAll('[data-braintree-payment-method]')[0].dataset.braintreePaymentMethod;
    let content = document.querySelector(`.js_braintree_${paymentMethodName.toLowerCase()}Content`).classList.contains('active');
    if (!content) {
        document.querySelectorAll(`[data-method-id=${paymentMethodName}]`)[0].children[0].click();
    }
}

/**
 * Gets Billing Address Form Values
 *
 * @returns {Object} with Billing Address
 */
function getBillingAddressFormValues() {
    return $('#dwfrm_billing').serialize().split('&')
        .map(function (el) {
            return el.split('=');
        })
        .reduce(function (accumulator, item) {
            var elem = item[0].lastIndexOf('_');
            if (elem < 0) {
                accumulator[item[0]] = item[1];
            } else {
                elem = item[0].substring(elem + 1);
                accumulator[elem] = item[1];
            }
            return accumulator;
        }, {});
}

/**
 * Gets Nonce depending on payment method name
 *
 * @param {string} paymentMethodName - payment method name
 * @returns {boolean} nonce exist
 */

function isNonceExist(paymentMethodName) {
    // Payment method name
    var pmName = paymentMethodName;

    // Сhange 'CREDIT_CARD' to 'CreditCard' in order to get braintreewCreditCardNonce input
    if (paymentMethodName === 'CREDIT_CARD') {
        pmName = 'CreditCard';
    }

    var $nonce = document.querySelector(`#braintree${pmName}Nonce`);

    if (!$nonce) return false;

    var nonceValue = $nonce.value;
    var $tab = document.querySelector(`.${pmName.toLowerCase()}-tab`);
    var isActiveTab;
    if ($tab) {
        isActiveTab = $tab.classList.contains('active');
    }

    return !isActiveTab && nonceValue;
}

var loaderInstance = require('./loaderHelper');
var accountsLoader;

function removeBtPayment(e) {
    let target = e.target;
    var $loaderContainter = document.querySelector('#' + target.getAttribute('data-loader'));
    accountsLoader = loaderInstance($loaderContainter);
    accountsLoader.show();

    $.get(target.getAttribute('data-url') + '?UUID=' + target.getAttribute('data-id'))
        .then((data => {
            $('#uuid-' + data.UUID).remove();
            if (data.newDefaultAccount) {
                document.querySelector('#uuid-' + data.newDefaultAccount + ' span').style.fontWeight = 'bold';
                document.querySelector('#uuid-' + data.newDefaultAccount + ' button.braintree-make-default-card').style.display = 'none';
            }
            $('body').trigger('cart:update');
            accountsLoader.hide();
        }))
        .fail(() => {
            location.reload();
            accountsLoader.hide();
        });
}

/**
 * Create formData from fields data
 *
 * @param {Object} paymentFields fields data values
 * @param {Object} fieldsData fields data values
 * @returns {Object} cart billing form data
 */
function createPaymentFormData(paymentFields, fieldsData) {
    var paymentFieldsParsed;

    if (typeof paymentFields === 'object') {
        paymentFieldsParsed = paymentFields;
    } else {
        paymentFieldsParsed = JSON.parse(paymentFields);
    }

    return Object.entries(paymentFieldsParsed).reduce(function (formData, entry) {
        const [key, field] = entry;
        if (typeof field === 'object') {
            formData.append(field.name, fieldsData && fieldsData[key] !== null ? fieldsData[key] : field.value);
        }
        return formData;
    }, new FormData());
}

function setDefaultProperty(params) {
    return $.get(params.url + '?UUID=' + params.id + '&pmID=' + params.paymentMethodID)
        .then((data) => {
            document.querySelector('#uuid-' + data.newDefaultProperty + ' span').style.fontWeight = 'bold';
            document.querySelector('.braintree-make-default-card.uuid-' + data.newDefaultProperty).style.display = 'none';
            document.querySelector('#uuid-' + data.toRemoveDefaultProperty + ' span').style.fontWeight = 'normal';
            document.querySelector('.braintree-make-default-card.uuid-' + data.toRemoveDefaultProperty).style.display = 'inline';
            params.loader.hide();
        })
        .fail(() => {
            params.loader.hide();
        });
}

/**
 * Checks authenticated customer, account list for default Payment Method
 *
 * @param {string} selector - querySelector
 * @returns {Object} default data attribute or null
 */
function getOptionByDataDefault(selector) {
    if (!document.querySelector(selector)) { return null; }

    return Array.apply(null, document.querySelector(selector).options).find(function (el) {
        return el.getAttribute('data-default') ? JSON.parse(el.getAttribute('data-default')) : null;
    });
}

/**
 * Checks authenticated customer, account list for session Account
 *
 * @param {Object} params querySelector + el.id
 * @returns {Object} session account object
 */
function getSessionAccountOption(params) {
    return Array.apply(null, document.querySelector(params.querySelector).options).find(function (el) {
        return el.id === params.id && JSON.parse(el.getAttribute('data-session-account'));
    });
}

/**
 * This method is called to remove active session account but just in case
 * if tab of currently active session payment isn't active (buyer submited another payment method)
 * @returns {function}function call
 */
function removeActiveSessionPayment() {
    const activePaymentMethods = [];
    document.querySelectorAll('.payment-options[role=tablist] li').forEach(function (el) {
        activePaymentMethods.push(el.dataset.methodId);
    });
    var helpers = {
        PayPal: require('./paypal/components/payPalSessionAccount'),
        Venmo: require('./venmo/components/venmoSessionAccount'),
        GooglePay: require('./googlepay/components/googlePaySessionAccount'),
        CREDIT_CARD: require('./creditcard/components/creditCardSessionAccount'),
        SRC: require('./src/components/srcSessionAccount'),
        ApplePay: require('./applepay/helpers/applePayHelper')
    };
    var activePM = activePaymentMethods.find(function (el) {
        return isNonceExist(el);
    });
    if (activePM) {
        return helpers[activePM].removeSessionNonce();
    }
}

function showCartErrorMsg(message) {
    $('.checkout-btn').addClass('disabled');
    $('.cart-error').append(
        `<div class="alert alert-danger alert-dismissible valid-cart-error fade show cartError" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            ${message}
        </div>`
    );
    window.scrollTo(0, 0);
}

/**
 * Update Checkout Billing form values
 *
 * @param {Object} billingData fields data values
 */
function updateBillingFormValues(billingData) {
    var $billingFormFields = document.querySelectorAll('.billing-address select, .billing-address input, .contact-info-block input');

    $billingFormFields.forEach(function (el) {
        if (billingData[el.name]) {
            el.value = billingData[el.name];
        }
    });
}

/**
 * Returns selected option from select container
 * @param {Object} $selectContainer Select container
 * @returns {Object} Selected option
 */
function getSelectedOption($selectContainer) {
    var selectedOptionIndex = $selectContainer.selectedIndex;
    var selectedOption = $selectContainer.options[selectedOptionIndex];

    return selectedOption;
}

/**
 * Returns payment field data to be send on backend
 * @param {Object} addressData Address data to be set
 * @param {string} paymentMethodName Payment method name
 * @returns {Object} payment data
 */
function getPaymentFieldsData(addressData, paymentMethodName) {
    return {
        firstName: addressData.firstName,
        lastName: addressData.lastName,
        address1: addressData.streetAddress,
        address2: addressData.extendedAddress || '',
        city: addressData.locality,
        postalCode: decodeURIComponent(addressData.postalCode),
        stateCode: addressData.stateCode || addressData.region,
        country: addressData.countryCodeAlpha2,
        phone: addressData.phone,
        paymentMethod: paymentMethodName
    };
}

function getUpdatedStoreFrontBillingData(billingAddress) {
    var storeFrontBillingData = JSON.parse(document.querySelector('.braintree-billing-payment-wrap').getAttribute('data-billing-form-fields-names'));

    storeFrontBillingData.dwfrm_billing_addressFields_firstName = billingAddress.firstName;
    storeFrontBillingData.dwfrm_billing_addressFields_lastName = billingAddress.lastName;
    storeFrontBillingData.dwfrm_billing_addressFields_address1 = billingAddress.streetAddress;
    storeFrontBillingData.dwfrm_billing_addressFields_address2 = billingAddress.extendedAddress || '';
    storeFrontBillingData.dwfrm_billing_addressFields_city = billingAddress.locality;
    storeFrontBillingData.dwfrm_billing_addressFields_postalCode = decodeURIComponent(billingAddress.postalCode);
    storeFrontBillingData.dwfrm_billing_addressFields_states_stateCode = billingAddress.stateCode || billingAddress.region;
    storeFrontBillingData.dwfrm_billing_addressFields_country = billingAddress.countryCodeAlpha2;
    storeFrontBillingData.dwfrm_billing_contactInfoFields_phone = billingAddress.phone;

    return storeFrontBillingData;
}

module.exports = {
    initCartUpdateEvent,
    paymentMethodChangeHandle,
    continueButtonToggle,
    updateCheckoutView,
    isValidInputField,
    updatePaymentMethodTab,
    getBillingAddressFormValues,
    removeBtPayment,
    createPaymentFormData,
    isNonceExist,
    setDefaultProperty,
    getOptionByDataDefault,
    removeActiveSessionPayment,
    getSessionAccountOption,
    showCartErrorMsg,
    updateBillingFormValues,
    getSelectedOption,
    getPaymentFieldsData,
    getUpdatedStoreFrontBillingData
};
