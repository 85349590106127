'use strict';

var SrcBaseModel = require('./srcBaseModel');

// Helpers
var srcHelper = require('../helpers/srcHelper');
var helper = require('../../helper');

var srcSessionAccountComponent = require('../components/srcSessionAccount');

// See description of "srcBaseModel" for more info
function SrcCheckoutModel(clientInstancePromise, srcButtonConfigurations, $braintreeSrcDeviceDataInput, loader, errorHandlingCheckoutModel) {
    SrcBaseModel.call(
        this,
        clientInstancePromise,
        srcButtonConfigurations,
        $braintreeSrcDeviceDataInput,
        loader,
        errorHandlingCheckoutModel
    );
}

SrcCheckoutModel.prototype = Object.create(SrcBaseModel.prototype);

// See description of "srcBaseModel" for more info
// Add nonce and billing data to the from and trigger click on "SubmitPayment" button
SrcCheckoutModel.prototype.onTokenizeCallback = function (srcPayload) {
    SrcBaseModel.prototype.onTokenizeCallback.call(this, srcPayload);

    this.loader.show();

    // Remove all other Payments session payments
    helper.removeActiveSessionPayment();

    var $srcNonce = document.querySelector('#braintreeSRCNonce');
    var $srcBillingAddress = document.querySelector('#braintreeSrcBillingAddress');
    var $continueButton = document.querySelector('button.submit-payment');

    var srcBillingData = srcPayload.billingAddress;

    srcPayload.billingAddressString = srcHelper.createSrcBillingAddress(srcPayload.billingAddress, srcPayload.userData);

    $srcNonce.value = srcPayload.nonce;
    srcBillingData.email = srcPayload.userData.userEmail;
    $srcBillingAddress.value = JSON.stringify(srcBillingData);

    srcSessionAccountComponent.showSrcSessionAccount(srcPayload);

    // Updating Storefront Billing Form data with SRC Billing
    srcHelper.updateStorefrontBillingData(srcBillingData);

    $continueButton.click();

    this.loader.hide();
};

module.exports = SrcCheckoutModel;
