'use strict';

var helper = require('../../helper');
var creditCardPaymentProcessingHelper = require('../helpers/creditCardPaymentProcessingHelper');
var creditCardHelper = require('../helpers/creditCardHelper');
var creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');
var creditCardSessionAccountHelper = require('../helpers/creditCardSessionAccountHelper');

/*
    Update Session account
**/
function clearSessionAccount(sessionOption) {
    sessionOption.selected = false;
    sessionOption.classList.add('used-creditcard-account-hide');
    sessionOption.classList.remove('used-creditcard-account');
    sessionOption.text = '';
    sessionOption.setAttribute('data-session-account', false);
    sessionOption.setAttribute('data-owner', false);
    sessionOption.setAttribute('data-expiration', false);
    sessionOption.setAttribute('data-number', false);
    sessionOption.setAttribute('data-nonce', false);
    sessionOption.setAttribute('data-type', false);
    sessionOption.setAttribute('data-save-card', false);
}

/**
 * Remove Credit Card session account
 */
function removeSessionNonce() {
    var defaultCreditCard = helper.getOptionByDataDefault('#braintreeCreditCardList');
    var creditCardFieldstoDisplay = creditCardFormFieldHelper.getCCFieldsToDisplay();
    var creditCardFieldsAsArray = creditCardFormFieldHelper.getCCFields().asArray;
    var sessionOption = helper.getSessionAccountOption({
        querySelector: '#braintreeCreditCardList',
        id: 'braintreeSessionCreditAccount'
    });

    if (!sessionOption) {
        return;
    }

    var sessionLastFour = sessionOption.getAttribute('data-last-four');
    var sessionType = sessionOption.getAttribute('data-type').toLowerCase();

    clearSessionAccount(sessionOption);

    // Handles case when stored and session credit card have the same type and last four digest
    // Makes visable already stored Credit Card in Credit Card List
    creditCardPaymentProcessingHelper.showAlreadyStoredCard(
        sessionLastFour,
        sessionType
    );

    creditCardSessionAccountHelper.clearCreditCardFields();
    creditCardSessionAccountHelper.clearCreditCardNonceField();

    if (defaultCreditCard) {
        defaultCreditCard.selected = true;

        creditCardFormFieldHelper.setCreditCardFieldsToDisplay(defaultCreditCard, creditCardFieldstoDisplay.asObject);

        // Uncheck Save Credit Card checkbox
        creditCardHelper.saveCreditCard(false);
        creditCardHelper.hideSaveCreditCardBlock(true);
    } else {
        creditCardFormFieldHelper.hideCardElements(creditCardFieldstoDisplay.asArray);
        creditCardFormFieldHelper.showCardElements(creditCardFieldsAsArray);

        // Hides 'Select a Credit Card block' on the Billing Page
        var ccAccount = document.querySelector('.form-group.braintree_used_creditcard_account');
        ccAccount.classList.remove('used-creditcard-account');
        ccAccount.classList.add('used-creditcard-account-hide');
    }
}

module.exports = {
    removeSessionNonce
};
