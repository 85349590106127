'use strict';

/**
 * Creates an object of configuration for creating a Google Pay payment
 * @param {Client} btClientInstance A client instance
 * @param {string} googleMerchantId Google Pay merchant identifier
 * @returns {Object} Configuration object
 */
function createGooglePayOptions(btClientInstance, googleMerchantId) {
    var gpOptions = {
        client: btClientInstance,
        googlePayVersion: 2
    };

    if (googleMerchantId !== '' && googleMerchantId !== null) {
        gpOptions.googleMerchantId = googleMerchantId;
    }

    return gpOptions;
}

module.exports = {
    createGooglePayOptions
};
