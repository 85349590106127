'use strict';

/**
 * Returns Braintree Google Pay configs
 * @returns {Object} Braintree Google Pay configs
 */
function getBraintreeGooglePayConfigs() {
    var braintreeGooglePayButtonConfigs = null;

    try {
        braintreeGooglePayButtonConfigs = JSON.parse(document.querySelector('.js_braintree_googlepay_button').getAttribute('data-braintree-config'));
    } catch (error) {
        return braintreeGooglePayButtonConfigs;
    }

    return braintreeGooglePayButtonConfigs;
}

/**
 * Sets device data from 'data collector' to corresponding input field
 * @param {Object} fraudDataPayload Froud payload data
 */
function setGooglePayDeviceData(fraudDataPayload) {
    var $braintreeGooglePayDeviceData = document.querySelector('#braintreeGooglePayDeviceData');

    if (fraudDataPayload.deviceData) {
        $braintreeGooglePayDeviceData.value = fraudDataPayload.deviceData;
    }
}

/**
 * Handles all behavior related to the Google Pay content tab
 * @param {boolean} isActiveGooglePayTab Whether Google Pay tab is active
 */
function googlePayContentTabHandler(isActiveGooglePayTab) {
    var helper = require('../../helper');
    var $btGooglepayAccountsList = document.querySelector('#braintreeGooglepayAccountsList');
    var $googlepayButton = document.querySelector('.js_braintree_googlepay_button');
    var $googlepayContent = document.querySelector('.js_braintree_googlepayContent');

    if ($btGooglepayAccountsList.value === 'newaccount') {
        $googlepayButton.style.display = 'block';

        if (isActiveGooglePayTab) {
            helper.continueButtonToggle(true);
        }

        $googlepayContent.setAttribute('data-paypal-is-hide-continue-button', true);
    } else {
        $googlepayButton.style.display = 'none';
        helper.continueButtonToggle(false);
        $googlepayContent.setAttribute('data-paypal-is-hide-continue-button', false);
    }
}

/**
 * Triggers the Google Pay tab if Google Pay session account uses
 * @param {Object} googlePayConfigs Google Pay config object
 */
function triggerGooglePayTab(googlePayConfigs) {
    var sessionPaymentMethodId = googlePayConfigs.sessionPaymentMethodId;
    var $googlePayTab = $('.googlepay-tab');

    if (sessionPaymentMethodId === 'GooglePay') {
        $googlePayTab.click();
    }
}

module.exports = {
    getBraintreeGooglePayConfigs,
    setGooglePayDeviceData,
    googlePayContentTabHandler,
    triggerGooglePayTab
};
