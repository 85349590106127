'use strict';

var braintreeGeneral = require('./braintreeGeneral');

// Components
var payPalStoredBillingAddressComponent = require('./paypal/components/payPalStoredBillingAddress');

// Helpers
var paymentMethodGeneralHelper = require('./paymentMethodGeneralHelper');
var helper = require('./helper');
var googlePayHelper = require('./googlepay/helpers/googlePayHelper');
var srcHelper = require('./src/helpers/srcHelper');
var venmoHelper = require('./venmo/helpers/venmoHelper');
var applePayHelper = require('./applepay/helpers/applePayHelper');
var payPalListHelper = require('./paypal/helpers/payPalListHelper');

// Constancts used only for "createGeneralClientInstancePromise" function
const CHECKOUT_PAGE = 'checkout_page';
const CART_MINICART_PRODUCT_PAGE = 'cart_minicart_product_page';

function getApplicablePageType() {
    var isCheckoutPage = Boolean(document.getElementById('checkout-main'));
    // It is so, since MiniCart is exists on all mentioned pages
    var isCartMiniCartProductPage = Boolean(document.getElementsByClassName('minicart').length);
    var currentPageType;

    if (isCheckoutPage) {
        currentPageType = CHECKOUT_PAGE;
    } else if (isCartMiniCartProductPage) {
        currentPageType = CART_MINICART_PRODUCT_PAGE;
    }

    return currentPageType;
}

function createGeneralClientInstancePromise() {
    var applycablePageType = getApplicablePageType();
    var clientToken = null;
    var clientInstancePromise = null;

    // eslint-disable-next-line default-case
    switch (applycablePageType) {
        case CHECKOUT_PAGE:
            var $billingElement = document.querySelector('.summary-details.billing');

            clientToken = $billingElement.getAttribute('data-bt-client-token');
            break;

        case CART_MINICART_PRODUCT_PAGE:
            var $btExpressCheckoutPayPalButtonWrapper = document.querySelector('.braintree-cart-paypal-buttons-wrap');
            var $btAccountPayPalButtonWrapper = document.querySelector('.js_braintree_accountPaypalButton_wrapper');

            if ($btExpressCheckoutPayPalButtonWrapper) {
                clientToken = $btExpressCheckoutPayPalButtonWrapper.getAttribute('data-bt-client-token');
            } else if ($btAccountPayPalButtonWrapper) {
                clientToken = $btAccountPayPalButtonWrapper.getAttribute('data-bt-client-token');
            }
            break;
    }

    if (clientToken) {
        clientInstancePromise = braintreeGeneral.createClientInstance(clientToken);
    }

    return clientInstancePromise;
}

/**
 * Method override default SFRA payment-details filling behavior.
 * Method fill payment-details block with the right payment methdo data (like name, account, etc.)
 */
function fillPaymentSammaryContainer() {
    var $summaryDetails = document.querySelector('.summary-details .payment-details');

    if ($summaryDetails) {
        $summaryDetails.classList.add('braintree-payment-details');
        $summaryDetails.classList.remove('payment-details');

        $('body').on('checkout:updateCheckoutView', helper.updateCheckoutView);
    }
}

/**
 * May be used for all payment methods
 * Create error container and append it after PayPal button
 * @param {string} payPalButtonSelector selector of PayPal button
 * @returns {Object} return created error container
 */
function createErrorContainerAfterPayPalButton(payPalButtonSelector) {
    var $errorContainer = document.createElement('div');
    var $payPalButton = document.querySelector(payPalButtonSelector);

    $errorContainer.classList.add('alert-danger');
    $errorContainer.style.cssText = 'text-align: center';

    $payPalButton.parentNode.insertBefore($errorContainer, $payPalButton.nextSibling);

    return $errorContainer;
}

/**
 * Init behavior which should be triggered when buyer clicked on Payment Method tab.
 * Currently we handle billing address modification enabling/disabling.
 * We also have init behavior of "Submit Payment" button
 */
function initPaymentMethodTabClickBehavior() {
    $('.payment-options .nav-item').click(function () {
        var selectedMethodID = $(this).data('method-id');
        var $payPalAccountList = document.querySelector('#braintreePaypalAccountsList');
        var localPaymentMethodsList = [
            'sepa',
            'bancontact',
            'eps',
            'giropay',
            'ideal',
            'mybank',
            'p24',
            'sofort'
        ];

        if (selectedMethodID !== 'PayPal') {
            // Undo address selection which was done via "displaySelectedStoredBillingAddress" for PayPal PM
            payPalStoredBillingAddressComponent.selectDefaultBillingAddressOption();
        }

        if (localPaymentMethodsList.includes(selectedMethodID)) {
            selectedMethodID = 'LPM';
        }

        switch (selectedMethodID) {
            case 'PayPal':
                var $selectedPayPalAccountOption = $payPalAccountList.options[$payPalAccountList.selectedIndex];
                // Disabled billing address functionality on billing page
                paymentMethodGeneralHelper.disableBillingAddressFunctionality();

                // Submit Payment button behavior
                var isAccountListHidden = document.querySelector('.braintree_used_paypal_account').classList.contains('used-paypal-account-hide');

                if (isAccountListHidden) {
                    paymentMethodGeneralHelper.hideSubmitPaymentButton();
                } else if (!payPalListHelper.isNewPayPalAccountOptionSelected()) {
                    paymentMethodGeneralHelper.showSubmitPaymentButton();
                } else {
                    paymentMethodGeneralHelper.hideSubmitPaymentButton();
                }

                // Display billing address of selected PayPal account ONLY for STORED PP accounts use-case
                payPalStoredBillingAddressComponent.displaySelectedStoredBillingAddress($selectedPayPalAccountOption);
                break;

            case 'GooglePay':
                googlePayHelper.googlePayContentTabHandler(true);
                // Disabled billing address functionality on the Billing Page
                paymentMethodGeneralHelper.disableBillingAddressFunctionality();
                break;

            case 'SRC':
                srcHelper.srcContentTabHandler(true);
                // Disabled billing address functionality on Billing Page
                paymentMethodGeneralHelper.disableBillingAddressFunctionality();
                break;

            case 'ApplePay':
                applePayHelper.toggleContinueButtonOnBillingPage();
                // Disabled billing address functionality on the Billing Page
                paymentMethodGeneralHelper.disableBillingAddressFunctionality();
                break;

            case 'CREDIT_CARD':
                paymentMethodGeneralHelper.enableBillingAddressFunctionality();

                // Submit Payment button behavior
                paymentMethodGeneralHelper.showSubmitPaymentButton();
                break;
            case 'Venmo':
                var isBtVenmoAccountsList = Boolean(document.querySelector('#braintreeVenmoAccountsList'));
                var isSessionAccountExist = Boolean(document.querySelector('.used-venmo-account'));
                // Handles continue button visibility on the Billing Page in Venmo tab
                // Case for the registered user with saved account
                if (isBtVenmoAccountsList) {
                    venmoHelper.venmoContentTabHandler();
                } else if (isSessionAccountExist) {
                    // Case when only session account exist without saved account
                    helper.continueButtonToggle(false);
                } else {
                    // Case when no session/saved account exist and Venmo button is shown
                    helper.continueButtonToggle(true);
                }

                // Enabled billing address functionality on the Billing Page
                paymentMethodGeneralHelper.enableBillingAddressFunctionality();
                break;
            case 'LPM':
                // Enabled billing address functionality on the Billing Page
                paymentMethodGeneralHelper.enableBillingAddressFunctionality();
                paymentMethodGeneralHelper.hideSubmitPaymentButton();
                break;
            default:
                // Enabled billing address functionality on the Billing Page
                paymentMethodGeneralHelper.enableBillingAddressFunctionality();
        }
    });
}

/**
 * Creates customer form with email to submit it to CheckoutServices-SubmitCustomer endpoint
 * @param {string} email email from payment method's response
 * @returns {Object} customer form data
 */
function createCustomerFormData(email) {
    var data = {
        email: email
    };

    var csrfToken = document.querySelector('.braintree-cart-buttons-wrap #csrf_token');
    var checkoutCustomerFormFields = document.querySelector('.braintree-cart-buttons-wrap').getAttribute('data-checkout-customer-form-fields');
    var customerFormData = helper.createPaymentFormData(checkoutCustomerFormFields, data);

    customerFormData.append(csrfToken.name, csrfToken.value);

    return customerFormData;
}

/**
 * Submits customer form with email to CheckoutServices-SubmitCustomer endpoint
 * Call is triggered only in case if email in basket is empty (guest first checkout from cart)
 * @param {string} email email from payment method's response
 * @returns {Ajax} ajax call to CheckoutServices-SubmitCustomer endpoint
 */
function submitCustomerForm(email) {
    var buttonsWrapper = document.querySelector('.braintree-cart-buttons-wrap');
    var isCustomerEmailEmpty = JSON.parse(buttonsWrapper.getAttribute('data-is-customer-email-empty'));
    // Could be "true" only in case if email in basket is empty (first guest checkout from cart)
    if (isCustomerEmailEmpty) {
        var submitCustomerUrl = buttonsWrapper.getAttribute('data-checkout-submit-customer-url');
        var customerFormData = createCustomerFormData(email);

        return $.ajax({
            type: 'POST',
            url: submitCustomerUrl,
            data: customerFormData,
            contentType: false,
            processData: false
        });
    }
}

module.exports = {
    createGeneralClientInstancePromise,
    fillPaymentSammaryContainer,
    createErrorContainerAfterPayPalButton,
    initPaymentMethodTabClickBehavior,
    submitCustomerForm
};
