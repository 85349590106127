'use strict';
// File for form fields validation (applying red, green, yellow frame)
// This file is used ONLY for validation of Owner field (since Owner field isn't a Hosted Field provided by Braintree client SDK)

var creditCardFormFieldsHelper = require('../helpers/creditCardFormFieldsHelper');

var BRAINTREE_ERROR_SELECTOR = 'braintree-hosted-fields-invalid';
var BRAINTREE_VALID_SELECTOR = 'braintree-hosted-fields-valid';
var BRAINTREE_FOCUSED_SELECTOR = 'braintree-hosted-fields-focused';

/**
 * Returns array of filed containers
 * @param {Array} arrayOfFormFields Array of invalid fields
 * @returns {Array} Array of filed containers
 */
function getArrayOfFieldContainers(arrayOfFormFields) {
    var collectionOfCreditCardFields = creditCardFormFieldsHelper.getCCFields().asObject;
    var arrayOfFieldContainers = [];
    var fieldContainer;

    arrayOfFormFields.forEach(function (fieldName) {
        fieldContainer = collectionOfCreditCardFields[fieldName];

        if (fieldContainer) {
            arrayOfFieldContainers.push(fieldContainer);
        }
    });

    return arrayOfFieldContainers;
}

/**
 * Sets 'BRAINTREE_ERROR_SELECTOR' selector to the invalid form fields
 * @param {Array} arrayOfInvalidFieldsFrom Array of invalisd fields
 */
function makeFormFieldsInvalid(arrayOfInvalidFieldsFrom) {
    var arrayOfInvalidFormFields = getArrayOfFieldContainers(arrayOfInvalidFieldsFrom);

    arrayOfInvalidFormFields.forEach(function ($field) {
        // remove "field valid" class
        $field.classList.remove(BRAINTREE_VALID_SELECTOR);
        // add "field not valid" class
        $field.classList.add(BRAINTREE_ERROR_SELECTOR);
    });
}

/**
 * Makes all fields invalid
 */
function makeAllFormFieldsInvalid() {
    var collectionOfCreditCardFields = creditCardFormFieldsHelper.getCCFields().asObject;
    var arrayOfCreditCardFieldNames = Object.keys(collectionOfCreditCardFields);

    makeFormFieldsInvalid(arrayOfCreditCardFieldNames);
}

/**
 * Makes all form fields valid
 */
function makeAllFormFieldsValid() {
    var arrayOfCreditCardFields = creditCardFormFieldsHelper.getCCFields().asArray;

    arrayOfCreditCardFields.forEach(function ($field) {
        // remove "field in focuse" class
        $field.classList.remove(BRAINTREE_FOCUSED_SELECTOR);
        // remove "field not valid" class
        $field.classList.remove(BRAINTREE_ERROR_SELECTOR);
        // add "field valid" class
        $field.classList.add(BRAINTREE_VALID_SELECTOR);
    });
}

module.exports = {
    makeFormFieldsInvalid,
    makeAllFormFieldsInvalid,
    makeAllFormFieldsValid
};
