'use strict';

var helper = require('../../helper');

// Global variables
var googlePayBillingAddressData;
var googlePayNonce;
var googlepayPaymentType;
var googlePayCardDescription;

var $googlepayNonce = document.querySelector('#braintreeGooglePayNonce');
var $googlepayCardDescription = document.querySelector('#braintreeGooglePayCardDescription');
var $braintreeGooglepayPaymentType = document.querySelector('#braintreeGooglepayPaymentType');
var $googlePayBillingAddress = document.querySelector('#braintreeGooglePayBillingAddress');

/**
 * Process Google Pay on the Checkout Page
 */
function processCheckoutFlow() {
    $googlepayNonce.value = googlePayNonce;
    $googlepayCardDescription.value = googlePayCardDescription;
    $braintreeGooglepayPaymentType.value = googlepayPaymentType;

    // Updating Storefront Billing Form data with GooglePay Billing
    var storeFrontBillingData = helper.getUpdatedStoreFrontBillingData(googlePayBillingAddressData);

    $googlePayBillingAddress.value = JSON.stringify(googlePayBillingAddressData);

    helper.updateBillingFormValues(storeFrontBillingData);
}

/**
 * Inits Google Pay checkout components
 * @param {string} nonce Payment method nonce
 * @param {string} description Google Pay card description
 * @param {string} paymentType Payment method type
 * @param {Object} billingAddressData Billing address data object
 */
function init(nonce, description, paymentType, billingAddressData) {
    googlePayBillingAddressData = billingAddressData;
    googlePayNonce = nonce;
    googlepayPaymentType = paymentType;
    googlePayCardDescription = description;

    processCheckoutFlow();
}

module.exports = {
    init
};
