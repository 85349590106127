'use strict';

var helper = require('../../helper');
var venmoErrorHandler = require('../errorhandler/venmoErrorHandler');

var $addVenmoAccountBtn = document.querySelector('.add-venmo-account');
var $venmoAccountWrapper = document.querySelector('.js_braintree_accountVenmoButton_wrapper');

/**
 * Returns Braintree Venmo configs
 * @returns {Object} Braintree Venmo configs
 */
function getBraintreeVenmoButtonConfigs() {
    var braintreeVenmoButtonConfigs = null;
    var $venmoButton = document.querySelector('.js_braintree_venmo_button') || document.querySelector('.js_braintree_accountVenmoButton');

    try {
        braintreeVenmoButtonConfigs = JSON.parse($venmoButton.getAttribute('data-braintree-config'));
    } catch (error) {
        return braintreeVenmoButtonConfigs;
    }

    return braintreeVenmoButtonConfigs;
}

/**
 * Creates loader container on the Billing page
 * @param {Element} $target Venmo button target for which loader container is created
 * @returns {Element} created loader container element
 */
function createLoaderContainter($target) {
    var $loaderContainter = document.createElement('div');

    $loaderContainter.className = 'venmo-braintree-loader';

    helper.continueButtonToggle(true);

    return $target.parentNode.insertBefore($loaderContainter, $target);
}


/**
 * Updates the billing address form values within saved billing
 * @param {Object} billingAddress billing address
 */
function updateBillingAddressFormValues(billingAddress) {
    var form = $('form[name=dwfrm_billing]');
    var inputNames = ['firstName', 'lastName', 'address1', 'address2', 'city', 'postalCode', 'country'];

    if (!form) {
        return;
    }

    $.each(inputNames, function (index, value) {
        $(`input[name$=_${value}]`, form).val(decodeURIComponent(billingAddress[value]));
    });

    $('select[name$=_stateCode],input[name$=_stateCode]', form)
        .val(billingAddress.stateCode);
}

/**
 * Shows Venmo button on the Account Page and hides "add new" button
 */
function showVenmoAccountBtn() {
    $addVenmoAccountBtn.style.display = 'none';
    $venmoAccountWrapper.style.display = 'block';
}

/**
 * Hides Venmo button on the Account Page and shows "add new" button
 */
function hideVenmoAccountBtn() {
    $addVenmoAccountBtn.style.display = 'block';
    $venmoAccountWrapper.style.display = 'none';
}

/**
 * Saves Venmo device (risk) data to hidden input
 * @param {string} deviceData Venmo device (risk) data
 */
function setVenmoDeviceData(deviceData) {
    var $braintreeVenmoDeviceData = document.querySelector('#braintreeVenmoDeviceData');

    if ($braintreeVenmoDeviceData) {
        $braintreeVenmoDeviceData.value = deviceData;
    }
}

/**
 * Checks billing form on the Billing Page
 * If billing form is valid, saves/updates billing address to input
 * If not valid (possible case: empty email) - add error class to email input
 * @returns {boolean} billing form check result value
 */
function checkBillingForm() {
    var $billingForm = document.querySelector('.card-body [id=dwfrm_billing]');
    var $venmoBillingInput = document.querySelector('#braintreeVenmoBilling');

    if ($billingForm.checkValidity()) {
        /**
            * Setting current customer Billing address to form
            * For the case when SFCC redirects customer from payment stage to shipping stage
            * after successful Venmo app response
        */
        $venmoBillingInput.value = JSON.stringify(helper.getBillingAddressFormValues());

        return true;
    }

    return false;
}

/**
 * Checks zero amount on the Billing Page
 * If amount is zero Venmo pop-up is closed and appropriate error is shown
 * If amount is acceptable by Venmo user could proceed through pop-up
 * @returns {boolean} zero amount check result value
 */
function checkZeroAmount() {
    var $venmoButton = document.querySelector('.js_braintree_venmo_button');
    var venmoButtonConfig = JSON.parse($venmoButton.getAttribute('data-braintree-config'));
    var amount = venmoButtonConfig.options.amount;

    if (amount === 0) {
        var errorMessage = venmoButtonConfig.messages.VENMO_ZERO_AMOUNT_ERROR;

        venmoErrorHandler.showCheckoutErrorMsg(errorMessage);

        return true;
    }

    return false;
}

/**
 * Handles all behavior related to the Venmo content tab
 */
function venmoContentTabHandler() {
    var $btVenmoAccountsList = document.querySelector('#braintreeVenmoAccountsList');
    var $saveVenmoAccountCheckboxContainer = document.querySelector('#braintreeSaveVenmoAccountContainer');
    var $saveVenmoAccountCheckbox = document.querySelector('#braintreeSaveVenmoAccount');
    var $venmoButton = document.querySelector('.js_braintree_venmo_button');
    var $venmoContent = document.querySelector('.js_braintree_venmoContent');
    var venmoOptionId = $btVenmoAccountsList.value;

    switch (venmoOptionId) {
        // Case when option "new account" is selected in dropdown
        case 'newaccount':
            $venmoButton.style.display = 'block';

            helper.continueButtonToggle(true);
            $venmoContent.setAttribute('data-paypal-is-hide-continue-button', true);

            break;
        // Case when saved account is selected in dropdown
        default:
            $venmoButton.style.display = 'none';

            helper.continueButtonToggle(false);
            $venmoContent.setAttribute('data-paypal-is-hide-continue-button', false);
            // Case when registered user and vaulting is enabled
            if ($saveVenmoAccountCheckboxContainer) {
                $saveVenmoAccountCheckbox.checked = false;
                $saveVenmoAccountCheckboxContainer.style.display = 'none';
            }
    }
}


module.exports = {
    getBraintreeVenmoButtonConfigs,
    createLoaderContainter,
    updateBillingAddressFormValues,
    showVenmoAccountBtn,
    hideVenmoAccountBtn,
    setVenmoDeviceData,
    checkBillingForm,
    checkZeroAmount,
    venmoContentTabHandler
};
