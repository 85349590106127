'use strict';

var venmoHelper = require('../helpers/venmoHelper');

var $btVenmoAccountsList = document.querySelector('#braintreeVenmoAccountsList');
var $venmoButton = document.querySelector('.js_braintree_venmo_button');

/**
 * Inits Venmo list functionality
 */
function init() {
    // Attribute is set as "true" when user has saved Venmo account in wallet
    // or when user has proceeded with Venmo session account
    var isHideVenmoButtonAttribute = JSON.parse($venmoButton.getAttribute('data-is-hide-venmo-button'));
    // Case when we already have session account in disabled dropdown
    // and user returns to the Billing Page from Cart or other page (from not billing page/stages)
    // only in this case we need to hide Venmo button
    // in other cases button and dropdown behavior is handled by venmoContentTabHandler function
    // because disabled dropdown with session Venmo account and with saved Venmo and "new account" option are separated
    if (isHideVenmoButtonAttribute && !$btVenmoAccountsList !== 'newaccount') {
        $venmoButton.style.display = 'none';
    }

    if ($btVenmoAccountsList) {
        $btVenmoAccountsList.addEventListener('change', function () {
            venmoHelper.venmoContentTabHandler();
        });

        venmoHelper.venmoContentTabHandler();
    }
}

module.exports = {
    init
};
