'use strict';

var CreditCardVaultManagerModel = require('./creditCardVaultManagerModel');

/**
 * Function which hide all stored CC which are not presented in VaultMgr
 * @param {Promise} clientInstancePromise BT client instance promise
 */
function removeFromDomCardsWhichAreNotInVaultMgr(clientInstancePromise) {
    var CreditCardVaultManagerModelInstance = new CreditCardVaultManagerModel(clientInstancePromise);
    var storefrontCardArray = [];

    $('.js_stored_card').each(function (i, el) {
        var $card = $(el);

        storefrontCardArray.push({
            cardType: $card.data('type'),
            lastFour: $card.data('last-four'),
            $card: $card
        });
    });

    if (storefrontCardArray.length) {
        CreditCardVaultManagerModelInstance.getCardsWhichAreNotInVault(storefrontCardArray)
        .then(function (cardsWhichAreNotInVault) {
            cardsWhichAreNotInVault.forEach(function (card) {
                card.$card.remove();
            });
        });
    }
}

/**
 * Return true in case if passed card can be stored on the Storefront and in the Vault
 * @param {Promise} clientInstancePromise BT client instance promise
 * @param {string} lastFour last four digits of the new card
 * @param {string} cardType type of new card
 * @returns {Promise} Promise with true or false
 */
function isNewCardCanBeStored(clientInstancePromise, lastFour, cardType) {
    var CreditCardVaultManagerModelInstance = new CreditCardVaultManagerModel(clientInstancePromise);
    var storefrontCardArray = [];

    $('.js_stored_card').each(function (i, el) {
        var $card = $(el);

        storefrontCardArray.push({
            cardType: $card.data('type').toLowerCase(),
            lastFour: $card.data('last-four'),
            $card: $card
        });
    });

    // Return "false" in case if new card exists on the Storefront and in the VaultMgr
    return CreditCardVaultManagerModelInstance.isCreditCardExistOnStorefrontAndVault(storefrontCardArray, lastFour, cardType)
        .then(function (isCardExists) {
            return !isCardExists;
        });
}

module.exports = {
    removeFromDomCardsWhichAreNotInVaultMgr,
    isNewCardCanBeStored
};
