'use strict';

var braintreeVenmoModel = require('./braintreeVenmoModel');
var braintreeGeneral = require('../../braintreeGeneral');
var venmoHelper = require('../helpers/venmoHelper');

/**
 * Initialize Braintree Venmo Model. Pattern Builder is used
 * @returns {Object} object with boolean "error" flag and "message" attribute with error message
 */
function init() {
    var braintreeVenmoButtonConfigs = venmoHelper.getBraintreeVenmoButtonConfigs();
    var clientToken;
    var btClientInstancePromise;
    var response = {
        error: false,
        message: '',
        btVenmoModel: null
    };

    if (braintreeVenmoButtonConfigs) {
        clientToken = braintreeVenmoButtonConfigs.clientToken;
        btClientInstancePromise = braintreeGeneral.createClientInstance(clientToken);

        braintreeVenmoModel.init(
            btClientInstancePromise
        );

        response.btVenmoModel = braintreeVenmoModel;
    } else {
        response.error = true;
        response.message = 'No Braintree Venmo Configurations';
    }

    return response;
}

module.exports = {
    init
};
