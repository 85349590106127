'use strict';

var googlePayHelper = require('../helpers/googlePayHelper');

/**
 * Inits Google Pay list components
 */
function init() {
    var $googlepayCardDescription = document.querySelector('#braintreeGooglePayCardDescription');
    var $btGooglepayAccountsList = document.querySelector('#braintreeGooglepayAccountsList');
    var googlePayAccountListExist = Boolean($btGooglepayAccountsList);

    if (googlePayAccountListExist) {
        $btGooglepayAccountsList.addEventListener('change', function () {
            googlePayHelper.googlePayContentTabHandler(true);
        });
    }

    if ($googlepayCardDescription.value === 'GooglePay') {
        $googlepayCardDescription.value = $btGooglepayAccountsList.selectedOptions[0].label;
    }
}

module.exports = {
    init
};
