'use strict';

var helper = require('../../helper');

/**
 * Save PayPal account to DW
 * @param {string} addPayPalAccountUrl add PayPal account url
 * @param {FormData} paymentFormData payment FormData
 * @returns {Promise} response from server
 */
function savePayPalAccount(addPayPalAccountUrl, paymentFormData) {
    return $.ajax({
        type: 'POST',
        url: addPayPalAccountUrl,
        data: paymentFormData,
        contentType: false,
        processData: false
    });
}

/**
 * Render PayPal account
 * @param {string} renderAccountsUrl render PayPal accounts URL
 * @returns {Promise} promise with html of PayPal account list
 */
function renderPayPalAccounts(renderAccountsUrl) {
    return $.get(renderAccountsUrl);
}

/**
 * Hide PayPal account button
 */
function hidePaypalAccountBtn() {
    var $addNewPaypalAccountBtn = document.querySelector('.add-paypal-account');
    var $paypalAccountButtonWrapper = document.querySelector('.js_braintree_accountPaypalButton_wrapper');

    // Display add new paypal account button
    $addNewPaypalAccountBtn.style.display = 'block';
    // Hide PayPal Account button
    $paypalAccountButtonWrapper.style.display = 'none';
}

/**
 * Add PayPal accounts html template to the DOM
 * @param {string} accountListTemplate html template with PayPal account list
 */
function addPayPalAccountsToTheTemplate(accountListTemplate) {
    var $payPalAccountsContainer = document.querySelector('.paypal-accounts');

    $payPalAccountsContainer.innerHTML = accountListTemplate;
}

/**
 * Trigger cart:updates event
 */
function throwCartUpade() {
    $('body').trigger('cart:update');
}

/**
 * Init Remove PayPal Account button listner
 */
function initRemovePayPalAccountButtonsListners() {
    var $allRemovePayPalAccountButtons = document.querySelectorAll('.paypal-accounts .remove-bt-payment');

    $allRemovePayPalAccountButtons.forEach(function (removePayPalAccountButton) {
        removePayPalAccountButton.addEventListener('click', helper.removeBtPayment);
    });
}

module.exports = {
    savePayPalAccount,
    renderPayPalAccounts,
    hidePaypalAccountBtn,
    addPayPalAccountsToTheTemplate,
    throwCartUpade,
    initRemovePayPalAccountButtonsListners
};
