'use strict';

function hideBillingAddressManipulationButtons() {
    document.querySelector('.address-selector-block .btn-show-details').style.display = 'none';
    document.querySelector('.address-selector-block .btn-add-new').style.display = 'none';
}

function showBillingAddressManipulationButtons() {
    document.querySelector('.address-selector-block .btn-show-details').style.display = '';
    document.querySelector('.address-selector-block .btn-add-new').style.display = '';
}

function disableBillingAddressList() {
    document.getElementById('billingAddressSelector').setAttribute('disabled', 'disabled');
}

function enableBillingAddressList() {
    document.getElementById('billingAddressSelector').removeAttribute('disabled');
}

function hideSubmitPaymentButton() {
    document.querySelector('.submit-payment').style.display = 'none';
}

function showSubmitPaymentButton() {
    document.querySelector('.submit-payment').style.display = '';
}

/**
 * Hides biiling address form on the Billing Page for appropriate tabs.
 * Case when customer cliked 'Updated address' or 'Add New' button and flipped through the payment method tabs
 */
function hideBillingAddressForm() {
    document.querySelector('.billing-address-block .billing-address').style.display = 'none';
}

/**
 * Shows biiling address form on the Billing Page for appropriate tabs.
 * Case when customer cliked 'Updated address' or 'Add New' button and flipped through the payment method tabs
 */
function showBillingAddressForm() {
    document.querySelector('.billing-address-block .billing-address').style.display = '';
}

/**
 * Disabled billing address functionality on the Billing Page
 */
function disableBillingAddressFunctionality() {
    disableBillingAddressList();
    hideBillingAddressManipulationButtons();
    hideBillingAddressForm();
}

/**
 * Enabled billing address functionality on the Billing Page
 */
function enableBillingAddressFunctionality() {
    showBillingAddressManipulationButtons();
    enableBillingAddressList();
    showBillingAddressForm();
}

module.exports = {
    disableBillingAddressFunctionality,
    enableBillingAddressFunctionality,
    hideBillingAddressManipulationButtons,
    showBillingAddressManipulationButtons,
    disableBillingAddressList,
    enableBillingAddressList,
    hideBillingAddressForm,
    showBillingAddressForm,
    hideSubmitPaymentButton,
    showSubmitPaymentButton
};
