'use strict';

// Helpers
var helper = require('../../helper');
var srcListHelper = require('../helpers/srcListHelper');
var srcHelper = require('../helpers/srcHelper');

var $srcSessionAccount = document.querySelector('#sessionSrcAccount');
var $srcButton = document.querySelector('.js_braintree_src_button');
var $accountListBlock = document.querySelector('.braintree_used_src_account');
var $srcCardDescription = document.querySelector('#braintreeSrcCardDescription');
var $braintreeSrcCardType = document.querySelector('#braintreeSrcCardType');
var $braintreeSrcLastFourDigits = document.querySelector('#braintreeSrcLastFourDigits');
var $braintreeSrcCreditCardNumber = document.querySelector('#braintreeSrcCreditCardNumber');
/**
 * Shows used session account(card) in dropdown and handles checkout view
 * @param {Object} srcPayload payload with card data from tokenization
 */
function showSrcSessionAccount(srcPayload) {
    // description of used card(ex. Visa ending in 1111)
    var cardDescription = srcPayload.details.cardType + ' ' + srcPayload.description;

    $srcSessionAccount.value = cardDescription;
    $srcSessionAccount.text = cardDescription;
    $srcCardDescription.value = cardDescription;
    $srcSessionAccount.selected = true;
    $accountListBlock.style.display = 'block';
    $srcButton.style.display = 'none';

    // Inputs required for identifying already saved cards
    $braintreeSrcCardType.value = srcPayload.details.cardType;
    $braintreeSrcLastFourDigits.value = srcPayload.details.lastFour;
    $braintreeSrcCreditCardNumber.value = '************' + srcPayload.details.lastFour;

    srcListHelper.handleSaveSrcAccountCheckbox($srcSessionAccount, true);
    srcListHelper.setIsHideContinueButtonAttr(false);
    helper.continueButtonToggle(false);
}

/*
* Removes method was used and changes appearance of SRC tab
**/
function removeSessionNonce() {
    $srcSessionAccount.value = '';
    $srcSessionAccount.text = '';
    $accountListBlock.style.display = 'none';
    $srcSessionAccount.setAttribute('data-save', false);

    document.querySelector('#braintreeSRCNonce').value = '';
    document.querySelector('#braintreeSrcBillingAddress').value = '';
    document.querySelector('#braintreeSrcCardDescription').value = '';
    document.querySelector('#newSrcAccount').selected = true;

    srcHelper.srcContentTabHandler(false);
}

module.exports = {
    showSrcSessionAccount,
    removeSessionNonce
};
