'use strict';

/**
 * Shows a zero amount SRC button and hides a normal SRC button
 */
function showZeroAmountSrcButton() {
    $('.v-button-zero-amount').show();
    $('.v-button').hide();
}

/**
 * Hides a zero amount SRC button and show a normal SRC button
 */
function hideZeroAmountSrcButton() {
    $('.v-button-zero-amount').hide();
    $('.v-button').show();
}

/**
 * Handles whether show or hide zero amount SRC button
 * @param {number} totalAmount Total amount of order
 */
function handleZeroAmountButtonVisibility(totalAmount) {
    if (totalAmount === 0) {
        showZeroAmountSrcButton();
    } else {
        hideZeroAmountSrcButton();
    }
}

/**
 * Adds event listener to the zero amount button on the Cart Page
 * @param {DOMElement} $zeroAmountButton DOM element of zero amount SRC button
 * @param {Object} errorHandlingModel Checkout error handling model
 */
function addZeroAmountButtonEventListenerOnCart($zeroAmountButton, errorHandlingModel) {
    $zeroAmountButton.addEventListener('click', () => {
        errorHandlingModel.hideError();
        errorHandlingModel.showZeroAmountOnCart();
    });
}

/**
 * Adds event listener to the zero amount SRC button on the Checkout Page
 * @param {DOMElement} $zeroAmountButton DOM element of zero amount SRC button
 * @param {Object} errorHandlingModel Checkout error handling model
 */
function addZeroAmountButtonEventListenerOnCheckout($zeroAmountButton, errorHandlingModel) {
    $zeroAmountButton.addEventListener('click', () => {
        errorHandlingModel.hideError();
        errorHandlingModel.showZeroAmountOnCheckout();
    });
}

/**
 * Creates a mutation obserber for total amount element
 * @param {DOMElement} totalAmountElement DOM Element that includes the total cost
 */
function initTotalAmountElementObserver(totalAmountElement) {
    var config = { attributes: true, childList: true, subtree: true };

    var observer = new MutationObserver((element) => {
        var totalAmount = Number.parseFloat(element[0].target.textContent.slice(1));

        // Handles whether show or hide zero amount SRC button
        handleZeroAmountButtonVisibility(totalAmount);
    });

    observer.observe(totalAmountElement, config);
}

module.exports = {
    initTotalAmountElementObserver: initTotalAmountElementObserver,
    addZeroAmountButtonEventListenerOnCheckout: addZeroAmountButtonEventListenerOnCheckout,
    addZeroAmountButtonEventListenerOnCart: addZeroAmountButtonEventListenerOnCart,
    hideZeroAmountSrcButton: hideZeroAmountSrcButton,
    showZeroAmountSrcButton: showZeroAmountSrcButton,
    handleZeroAmountButtonVisibility: handleZeroAmountButtonVisibility
};
