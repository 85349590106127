'use strict';

var SrcBaseModel = require('./srcBaseModel');
var srcHelper = require('../helpers/srcHelper');
var srcPaymentProcessingHelper = require('../helpers/srcPaymentProcessingHelper');

// See description of "srcBaseModel" for more info
function SrcCartModel(clientInstancePromise, srcButtonConfigurations, $braintreeSrcDeviceDataInput, loader, errorHandlingCartModel) {
    SrcBaseModel.call(
        this,
        clientInstancePromise,
        srcButtonConfigurations,
        $braintreeSrcDeviceDataInput,
        loader,
        errorHandlingCartModel
    );
}

SrcCartModel.prototype = Object.create(SrcBaseModel.prototype);

// See description of "srcBaseModel" for more info
// Add nonce and billing data to the from and submit to the "CheckoutService-SubmitPayment" endpoint
SrcCartModel.prototype.onTokenizeCallback = function (srcPayload) {
    SrcBaseModel.prototype.onTokenizeCallback.call(this, srcPayload);

    this.loader.show();

    var $srcButton = document.querySelector('.js_braintree_src_button');

    srcPayload.billingAddressString = srcHelper.createSrcBillingAddress(srcPayload.billingAddress, srcPayload.userData);

    var paymentMethodName = JSON.parse($srcButton.getAttribute('data-braintree-config')).paymentMethodName;
    var srcFormData = srcHelper.createSrcFormData(srcPayload, paymentMethodName);
    var srcCheckoutFormData = srcHelper.createSrcCheckoutFormData(srcFormData, srcPayload);

    srcPaymentProcessingHelper.checkoutFromCartCall(srcCheckoutFormData, srcPayload.userData.userEmail, this.errorHandlingModel)
        .always(function () {
            this.loader.hide();
        });
};

module.exports = SrcCartModel;
