'use strict';

var $addVenmoAccountBtn = document.querySelector('.add-venmo-account');
var $venmoAccountWrapper = document.querySelector('.js_braintree_accountVenmoButton_wrapper');
var $venmoErrorContainer = document.querySelector('#venmoAccountErrorContainer');

/**
 * Shows error on the Account Page and hides Venmo block in case when browser is not supported
 * @param {Object} braintreeVenmoConfig Braintree Venmo config
 */
function handleNoBrowserSupportErrorOnAccount(braintreeVenmoConfig) {
    $addVenmoAccountBtn.style.display = 'none';
    $venmoAccountWrapper.style.display = 'block';
    document.querySelector('#venmoAccountErrorContainer').textContent = braintreeVenmoConfig.messages.VENMO_BROWSER_NOT_SUPPORTED;

    [].slice.call($venmoAccountWrapper.children).forEach(function (item) {
        if (!item.classList.contains('error')) {
            item.style.display = 'none';
        }
    });
}

/**
 * Hides Venmo tab on the Billing Page - in case when browser is not supported
 */
function hideVenmoTab() {
    document.querySelector('.js_braintree_venmo_button').parentElement.firstElementChild.style.display = 'none';
    document.querySelector('.payment-options[role=tablist] .nav-item[data-method-id="Venmo"]').style.display = 'none'; // Remove the venmo select payment method radiobutton
}

/**
 * Handles error flows in case when browser is not supported for Billing/Account Pages
 * @param {Object} braintreeVenmoConfig Braintree Venmo config
 */
function handleNoBrowserSupport(braintreeVenmoConfig) {
    if ($venmoAccountWrapper) {
        handleNoBrowserSupportErrorOnAccount(braintreeVenmoConfig);
    } else {
        hideVenmoTab();
    }
}

/**
 * Shows error container and passed error message
 * @param {string} error error text
 */
function showVenmoError(error) {
    $venmoErrorContainer.style.display = 'block';
    $venmoErrorContainer.textContent = error;
}

/**
 * Hides error container and clear existing error
 */
function clearVenmoError() {
    $venmoErrorContainer.textContent = '';
    $venmoErrorContainer.style.display = 'none';
}

/**
 * Show checkout error message on top of the page
 * @param {string} message Error message
 */
function showCheckoutErrorMsg(message) {
    document.querySelector('.error-message-text').textContent = '';
    document.querySelector('.error-message').style.display = 'block';
    document.querySelector('.error-message-text').append(message);

    window.scrollTo(0, 0);
}

/**
 * Inits Venmo error handler components
 * @param {Element} $venmoButton Venmo button element
 */
function init($venmoButton) {
    // For account page flow error container already exist
    var isVenmoErrorContainerExists = Boolean($venmoErrorContainer);

    // For the Billing Page we create error containter
    if (!isVenmoErrorContainerExists) {
        $venmoErrorContainer = document.createElement('div');
        $venmoErrorContainer.className = 'venmo-error';
        $venmoButton.parentNode.insertBefore($venmoErrorContainer, $venmoButton.nextSibling);
    }
}

module.exports = {
    init,
    handleNoBrowserSupport,
    showVenmoError,
    clearVenmoError,
    showCheckoutErrorMsg
};
