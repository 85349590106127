'use strict';

/**
 * Append save PayPal account prop
 * @param {FormData} billingFormData billing FormData
 */
function appendSavePayPalAccount(billingFormData) {
    billingFormData.append('braintreeSavePaypalAccount', true);
}

/**
 * Append payment data to the FromData
 * @param {FormData} formData billing FormData
 * @param {Object} payPalAccountData data from Bt
 */
function appendPaymentDataToFormData(formData, payPalAccountData) {
    formData.append('paymentMethodUUID', payPalAccountData.uuid);
}

module.exports = {
    appendSavePayPalAccount,
    appendPaymentDataToFormData
};
