'use strict';

var helper = require('../../helper');
var srcListHelper = require('./srcListHelper');
var srcButtonHelper = require('./srcButtonHelper');

/**
 * Returns Braintree SRC configs
 * @returns {Object} Braintree SRC configs
 */
function getBraintreeSRCButtonConfigs() {
    var braintreeSrcButtonConfigs = null;
    var $srcButton = document.querySelector('.js_braintree_src_button') || document.querySelector('.js_braintree_src_account_button');

    try {
        braintreeSrcButtonConfigs = JSON.parse($srcButton.getAttribute('data-braintree-config'));
    } catch (error) {
        return braintreeSrcButtonConfigs;
    }

    return braintreeSrcButtonConfigs;
}

/**
 * Returns stringified SRC shipping address
 * @param {Object} shippingData SRC shipping
 * @returns {string} stringified shipping address
 */
function createSrcShippingAddressData(shippingData) {
    var shippingAddress = {};
    shippingAddress.recipientName = shippingData.firstName + ' ' + shippingData.lastName;
    shippingAddress.phone = shippingData.phoneNumber;
    shippingAddress.countryCodeAlpha2 = shippingData.countryCode;
    shippingAddress.streetAddress = shippingData.streetAddress;
    shippingAddress.extendedAddress = shippingData.extendedAddress;
    shippingAddress.locality = shippingData.locality;
    shippingAddress.region = shippingData.region;
    shippingAddress.postalCode = decodeURIComponent(shippingData.postalCode);

    return JSON.stringify(shippingAddress);
}

/**
 * Returns stringified SRC billing address
 * @param {Object} billingData SRC billing
 * @param {Object} userData user data with email
 * @returns {string} stringified billing address
 */
function createSrcBillingAddress(billingData, userData) {
    var srcBillingAddress = {};
    srcBillingAddress.recipientName = billingData.firstName + ' ' + billingData.lastName;
    srcBillingAddress.phone = billingData.phoneNumber;
    srcBillingAddress.countryCodeAlpha2 = billingData.countryCode;
    srcBillingAddress.streetAddress = billingData.streetAddress;
    srcBillingAddress.extendedAddress = billingData.streetAddress;
    srcBillingAddress.locality = billingData.locality;
    srcBillingAddress.region = billingData.region;
    srcBillingAddress.postalCode = decodeURIComponent(billingData.postalCode);
    srcBillingAddress.email = userData.userEmail;

    return JSON.stringify(srcBillingAddress);
}

/**
 * Creates SRC checkout form data
 * @param {Object} srcCheckoutFormData SRC form data
 * @param {Object} srcResponseData payload from SRC
 * @returns {Object} SRC Checkout Form with appended SRC data
 */
function createSrcCheckoutFormData(srcCheckoutFormData, srcResponseData) {
    var csrfToken = document.querySelector('.braintree-cart-buttons-wrap #csrf_token');

    var cardDescription = srcResponseData.details.cardType + ' ' + srcResponseData.description;
    var deviceData = document.querySelector('input[name=braintreeSrcDeviceDataInput]').value;
    var shippingAddressData = createSrcShippingAddressData(srcResponseData.shippingAddress);

    srcCheckoutFormData.append(csrfToken.name, csrfToken.value);
    srcCheckoutFormData.append('braintreeSrcNonce', srcResponseData.nonce);
    srcCheckoutFormData.append('braintreeSrcDeviceDataInput', deviceData);
    srcCheckoutFormData.append('braintreeSrcShippingAddress', shippingAddressData || '{}');
    srcCheckoutFormData.append('braintreeSrcCardDescription', cardDescription);
    srcCheckoutFormData.append('braintreeSrcCardType', srcResponseData.details.cardType);
    srcCheckoutFormData.append('braintreeSrcCreditCardNumber', '************' + srcResponseData.details.lastFour);

    return srcCheckoutFormData;
}

/**
 * Creates SRC form data
 * @param {Object} responseData data received after SRC tokenize
 * @param {string} paymentMethodName SRC payment method name
 * @returns {Object} SRC payment form data
 */
function createSrcFormData(responseData, paymentMethodName) {
    var srcData = {};
    var checkoutFormFields = document.querySelector('.braintree-cart-buttons-wrap').getAttribute('data-checkout-form-fields');

    srcData.firstName = responseData.userData.userFirstName;
    srcData.lastName = responseData.userData.userLastName;
    srcData.address1 = responseData.billingAddress.streetAddress;
    srcData.address2 = responseData.billingAddress.extendedAddress || '';
    srcData.city = responseData.billingAddress.locality;
    srcData.postalCode = decodeURIComponent(responseData.billingAddress.postalCode);
    srcData.stateCode = responseData.billingAddress.region;
    srcData.country = responseData.billingAddress.countryCode;
    srcData.email = responseData.userData.userEmail;
    srcData.phone = responseData.billingAddress.phoneNumber;
    srcData.paymentMethod = paymentMethodName;

    return helper.createPaymentFormData(checkoutFormFields, srcData);
}

/**
 * Updates Storefront billing data form with SRC data
 * @param {Object} srcBillingData SRC billing address data/phone/email
 */
function updateStorefrontBillingData(srcBillingData) {
    var storefrontBillingData = JSON.parse(document.querySelector('.braintree-billing-payment-wrap').getAttribute('data-billing-form-fields-names'));
    storefrontBillingData.dwfrm_billing_addressFields_firstName = srcBillingData.firstName;
    storefrontBillingData.dwfrm_billing_addressFields_lastName = srcBillingData.lastName;
    storefrontBillingData.dwfrm_billing_addressFields_address1 = srcBillingData.streetAddress;
    storefrontBillingData.dwfrm_billing_addressFields_address2 = srcBillingData.extendedAddress || '';
    storefrontBillingData.dwfrm_billing_addressFields_city = srcBillingData.locality;
    storefrontBillingData.dwfrm_billing_addressFields_postalCode = decodeURIComponent(srcBillingData.postalCode);
    storefrontBillingData.dwfrm_billing_addressFields_states_stateCode = srcBillingData.region;
    storefrontBillingData.dwfrm_billing_addressFields_country = srcBillingData.countryCode;
    storefrontBillingData.dwfrm_billing_contactInfoFields_email = srcBillingData.email;
    storefrontBillingData.dwfrm_billing_contactInfoFields_phone = srcBillingData.phoneNumber;

    helper.updateBillingFormValues(storefrontBillingData);
}

/**
 * Handles all behavior related to the src content tab
 * SRC list (dropdown) change - actions for new/session account showing
 * @param {bollean} isSrcTabActive Whether Src tab is active
 */
function srcContentTabHandler(isSrcTabActive) {
    var $srcAccountList = document.querySelector('#braintreeSrcAccountsList');
    var srcOptionId = $srcAccountList.value;
    var $srcButton = document.querySelector('.js_braintree_src_button');
    var $srcZeroAmountButton = document.querySelector('.braintree-billingpage-src-button-zero-amount');
    var $alertInfo = document.querySelector('.billingAddressInfoAlert');
    var $srcSessionAccount = document.querySelector('#sessionSrcAccount');

    switch (srcOptionId) {
        case 'newaccount':
            $srcButton.style.display = 'block';
            $srcZeroAmountButton.style.display = 'block';
            $alertInfo.style.display = 'block';

            if (isSrcTabActive) {
                helper.continueButtonToggle(true);
            }

            srcListHelper.setIsHideContinueButtonAttr(true);
            // Case when registered user and vaulting is enabled
            srcListHelper.handleSaveSrcAccountCheckbox($srcSessionAccount, true);
            break;
        default:
            $srcButton.style.display = 'none';
            $srcZeroAmountButton.style.display = 'none';
            $alertInfo.style.display = 'none';

            helper.continueButtonToggle(false);
            srcListHelper.setIsHideContinueButtonAttr(false);
            // Case when registered user and vaulting is enabled
            srcListHelper.handleSaveSrcAccountCheckbox($srcSessionAccount, JSON.parse($srcSessionAccount.getAttribute('data-save')));
    }
}

/**
 * Treats the behavior of the SRC button in the SRC tab based on the total cost
 * Shows/hides the required SRC button if the total value is/isn't 0
 * @param {DOMElement} totalAmountElement DOM Element that includes the total cost
 * @param {Object} errorHandlingModel ErrorHandling cart or checkout model
 */
function zeroAmountSrcButtonBehavior(totalAmountElement, errorHandlingModel) {
    var $braintreeCartZeroAmountSrcButton = document.querySelector('.braintree-cart-src-button-zero-amount');
    var $braintreeBillingPageZeroAmountSrcButton = document.querySelector('.braintree-billingpage-src-button-zero-amount');
    var totalAmount = Number.parseFloat(totalAmountElement.textContent.slice(1));

    // Add event listener for cart zero amount button
    if ($braintreeCartZeroAmountSrcButton) {
        srcButtonHelper.addZeroAmountButtonEventListenerOnCart($braintreeCartZeroAmountSrcButton, errorHandlingModel);
    }

    // Add event listener for checkout zero amount button
    if ($braintreeBillingPageZeroAmountSrcButton) {
        srcButtonHelper.addZeroAmountButtonEventListenerOnCheckout($braintreeBillingPageZeroAmountSrcButton, errorHandlingModel);
    }

    // Handles whether show or hide zero amount button
    srcButtonHelper.handleZeroAmountButtonVisibility(totalAmount);

    // Creates a mutation obserber for total amount element
    srcButtonHelper.initTotalAmountElementObserver(totalAmountElement);
}

module.exports = {
    getBraintreeSRCButtonConfigs,
    createSrcShippingAddressData,
    createSrcBillingAddress,
    createSrcFormData,
    updateStorefrontBillingData,
    srcContentTabHandler,
    createSrcCheckoutFormData,
    zeroAmountSrcButtonBehavior
};
