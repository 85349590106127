'use strict';

// For cases when basket amount changing without page reload
var dynamicBasketProductQuantity = -1;

/**
 * Hide PayPal button
 * @param {Object} $payPalButton PayPal button js container
 */
function hidePayPalButton($payPalButton) {
    $payPalButton.style.display = 'none';
}

/**
 * Show PayPal button
 * @param {Object} $payPalButton PayPal button js container
 */
function showPayPalButton($payPalButton) {
    $payPalButton.style.display = '';
}

/**
 * Check if PDP product price is zero
 * @returns {boolean} "true" in case if PDP product price is zero
 */
function isZeroPdpProductPrice() {
    var $productPriceSelector = document.querySelector('.price .sales .value');

    if ($productPriceSelector) {
        var price = parseInt($productPriceSelector.getAttribute('content'), 0);
        return !(price > 0);
    }

    return true;
}

/**
 * Check if basket is empty based on attr which comes from server and "dynamicBasketProductQuantity" which updates on "cart:update" event
 * @param {Object} $payPalButton PayPal button js container
 * @returns {boolean} "true" in case if basket is not empty
 */
function isBasketNotEmpty($payPalButton) {
    return !JSON.parse($payPalButton.getAttribute('data-is-basket-empty')) || (dynamicBasketProductQuantity > 0 && dynamicBasketProductQuantity !== -1);
}

/**
 * Is add to cart button disabled
 * @returns {boolean} "true" in case if add to cart button disabled
 */
function isAddToCartButtonDisabled() {
    var $addToCartButton = document.querySelector('.add-to-cart') || document.querySelector('.add-to-cart-global');

    return $addToCartButton.disabled;
}

/**
 * Return mini cart quantity
 * @param {Object} $miniCartQuantitySelector Mini cart quantity selector
 * @returns {Int} Quantity
 */
function getMiniCartQuantity($miniCartQuantitySelector) {
    var quantity = null;

    if ($miniCartQuantitySelector) {
        quantity = parseInt($miniCartQuantitySelector.textContent, 0);
    }

    return quantity;
}

/**
 * Check if current total basket price is zero
 * @returns {boolean} "true" in case if current total basket price is zero
 */
function isCurrentTotalBasketPriceZero() {
    var $basketTotalAmount = document.querySelector('.sub-total');

    if ($basketTotalAmount) {
        var basketTotalAmount = $basketTotalAmount.textContent.slice(1);

        return parseInt(basketTotalAmount, 10) === 0;
    }
}

/**
 * Show or hide PayPal button
 * @param {Object} paypalMinicartButton paypal button js container
 */
function paypalMinicartButtonBehavior(paypalMinicartButton) {
    if (!isCurrentTotalBasketPriceZero()) {
        showPayPalButton(paypalMinicartButton);
    } else {
        hidePayPalButton(paypalMinicartButton);
    }
}

/**
 * Init PayPal button display behavior. This code is handle both PayPal button on PDP and MiniCart pages
 * @param {Object} $payPalButton PayPal button js container
 */
function initProductPage($payPalButton) {
    var addToCartButtonDisabled = isAddToCartButtonDisabled();
    var zeroProductPrice = isZeroPdpProductPrice();
    var basketNotEmpty = isBasketNotEmpty($payPalButton);

    if (addToCartButtonDisabled || zeroProductPrice || basketNotEmpty) {
        hidePayPalButton($payPalButton);
    }

    $('body').on('product:afterAddToCart', function () {
        hidePayPalButton($payPalButton);
    });

    // Case when buyer remove product from Cart
    $('body').on('cart:update', function (_, data) {
        var $miniCartQuantityContainer = document.querySelector('.minicart-quantity');
        var miniCartQuantity = getMiniCartQuantity($miniCartQuantityContainer);
        var addToCartButtonEnabled = !isAddToCartButtonDisabled();
        zeroProductPrice = isZeroPdpProductPrice();

        if (data.basket) {
            dynamicBasketProductQuantity = data.basket.numItems;
        }

        if (miniCartQuantity === 0 && addToCartButtonEnabled && !zeroProductPrice) {
            showPayPalButton($payPalButton);
        } else {
            hidePayPalButton($payPalButton);
        }
    });

    // When buyer change collor/size of the product on PDP
    $('body').on('product:statusUpdate', function () {
        addToCartButtonDisabled = isAddToCartButtonDisabled();
        zeroProductPrice = isZeroPdpProductPrice();

        if (addToCartButtonDisabled || zeroProductPrice) {
            hidePayPalButton($payPalButton);
        } else {
            showPayPalButton($payPalButton);
        }
    });
}

module.exports = {
    initProductPage,
    paypalMinicartButtonBehavior
};
