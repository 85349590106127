'use strict';

var PayPalCheckoutModel = require('../models/payPalCheckoutModel');
var ErrorHandlingCheckoutModel = require('../errorhandler/errorHandlingCheckoutModel');
var payPalAccountList = require('./payPalList');
var requestsHelper = require('../helpers/payPalRequestsHelperGlobal');

var braintreeClientInstancePromise;
var payPalConfigs;

/**
 * Initialize Checkout PayPal button functionality
 * @param {Promise} btClientInstancePromise BT Client Instance promise
 * @param {Object} payPalConfigurations PayPal checkout configurations object
 * @param {Int} basketTotalAmount Basket total amount
 */
function initCheckout(btClientInstancePromise, payPalConfigurations, basketTotalAmount) {
    var payPalButtonSelector = '.js_braintree_paypal_billing_button';
    var $continueButton = document.querySelector('button.submit-payment');

    var isVaultMode = payPalConfigurations.options.flow !== 'checkout';
    var requestBillingAgreement = Boolean(payPalConfigurations.options.requestBillingAgreement);
    var isFraudToolsEnabled = payPalConfigurations.isFraudToolsEnabled;
    var $loaderContainer = document.querySelector('.braintreePayPalLoader');

    var ErrorHandling = new ErrorHandlingCheckoutModel(payPalConfigurations.messages);
    var PayPalCheckoutPayment = new PayPalCheckoutModel(
        ErrorHandling,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        isVaultMode,
        requestBillingAgreement,
        isFraudToolsEnabled,
        $continueButton,
        basketTotalAmount,
        $loaderContainer
    );

    // Init account list
    payPalAccountList.init();
    // Generate Fraud Data if enabled
    PayPalCheckoutPayment.generateFraudData();
    // Init PayPal button
    PayPalCheckoutPayment.initPayment();
}

/**
 * Initialize Checkout PayPal button functionality.
 * Additional code was added which is used for total amount extracting which is needed for "Change Payment Method button" feature
 * @param {Promise} btClientInstancePromise BT Client Instance promise
 */
function init(btClientInstancePromise) {
    var $payPalButton = document.querySelector('.js_braintree_paypal_billing_button');
    var $paypalTab = $('.payment-options .nav-item[data-method-id="PayPal"]');
    var sessionPaymentMethodId;
    var changePMButtonEnabled;

    payPalConfigs = JSON.parse($payPalButton.getAttribute('data-braintree-config'));
    braintreeClientInstancePromise = btClientInstancePromise;
    sessionPaymentMethodId = payPalConfigs.sessionPaymentMethodId;
    changePMButtonEnabled = payPalConfigs.changePMButtonEnabled;

    // To load PayPal button only when buyer click (or from code) on PayPal Checkout tab
    $paypalTab.click(function () {
        var selectedMethodID = $(this).data('method-id');

        if (selectedMethodID === 'PayPal' && !window.payPalCheckoutWasInited) {
            window.payPalCheckoutWasInited = true;

            if (changePMButtonEnabled) {
                var basketDataPromise = requestsHelper.getBasketDataPromise(payPalConfigs.getOrderInfoUrl);

                basketDataPromise
                    .then(function (data) {
                        initCheckout(braintreeClientInstancePromise, payPalConfigs, data.amount);
                    });
            } else {
                initCheckout(braintreeClientInstancePromise, payPalConfigs, null);
            }
        }
    });

    // Use case when PayPal button was inited and shipment price was updated
    // We need this only for Change Payment Method button feature
    if (changePMButtonEnabled) {
        $('body').on('checkout:shippingMethodSelected', function () {
            if (window.payPalCheckoutWasInited) {
                var basketDataPromise = requestsHelper.getBasketDataPromise(payPalConfigs.getOrderInfoUrl);

                basketDataPromise
                    .then(function (data) {
                        initCheckout(braintreeClientInstancePromise, payPalConfigs, data.amount);
                    });
            }
        });
    }

    // Case when buyer use PayPal Static Image button
    // After click we select PayPal PM and init the rest of PP logic
    if (sessionPaymentMethodId === 'PayPal') {
        $paypalTab.trigger('click');
    }
}

module.exports = {
    init
};
