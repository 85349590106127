'use strict';

var srcConstants = require('../constants/srcConstants');
var paymentMethodGeneral = require('../../paymentMethodGeneral');

/**
 * Gets SRC flow name(corresponding page)
 * @returns {string} Flow id(page) account/cart/billing
 */
function getSrcFlowName() {
    var srcFlowName;
    var isSrcEnabledOnAccountPage = Boolean(document.querySelector('.js_braintree_addSrcAccountForm'));
    var isSrcEnabledOnCartPage = Boolean(document.querySelector('.braintree-cart-src-button'));
    var isSrcEnabledOnBillingPage = Boolean(document.querySelector('.js_braintree_srcContent'));

    if (isSrcEnabledOnAccountPage) {
        srcFlowName = srcConstants.FLOW_NAME_ACCOUNT;
    } else if (isSrcEnabledOnCartPage) {
        srcFlowName = srcConstants.FLOW_NAME_CART_CHECKOUT;
    } else if (isSrcEnabledOnBillingPage) {
        srcFlowName = srcConstants.FLOW_NAME_BILLING_CHECKOUT;
    }

    return srcFlowName;
}

/**
 * Makes call to checkoutFrom Cart endpoint
 * @param {Object} srcCheckoutFormData SRC form data passed to server side
 * @param {string} email SRC email from response
 * @param {Object} errorHandlingCartModel Error handling cart model
 * @returns {ajax} call to checkoutFrom Cart endpoint
 */
function checkoutFromCartCall(srcCheckoutFormData, email, errorHandlingCartModel) {
    var $braintreeCartButtons = document.querySelector('.braintree-cart-buttons-wrap');
    var checkoutFromCartUrl = $braintreeCartButtons.getAttribute('data-checkout-from-cart-url');
    var placeOrderUrl = $braintreeCartButtons.getAttribute('data-checkout-placeorder-url');
    // Submit customer form with email (CheckoutServices-SubmitCustomer)
    // as we skip step "login via guest/registered user" while express checkout
    // email is set only in case of guest checkout and if email is not already set
    paymentMethodGeneral.submitCustomerForm(email);

    return $.ajax({
        type: 'POST',
        url: checkoutFromCartUrl,
        data: srcCheckoutFormData,
        contentType: false,
        processData: false
    })
        .done(function (data) {
            if (data.error) {
                errorHandlingCartModel.handleCheckoutFromCartError(data);
            }

            sessionStorage.setItem('pageState', 'cart');
            window.location.href = placeOrderUrl;
        })
        .fail(function (err) {
            if (err && err.redirectUrl) {
                window.location.href = err.redirectUrl;
            }
        });
}

module.exports = {
    getSrcFlowName,
    checkoutFromCartCall
};
