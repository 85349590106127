'use strict';

var lpmHelper = require('../helpers/lpmHelper');
var braintreeGeneral = require('../../braintreeGeneral');
var braintreeLpmModel = require('./braintreeLpmModel');

var lpmErrorMessages = require('../constants/errorMessages');

/**
 * Initialize Braintree Lpm Model. Pattern Builder is used
 * @returns {Object} Object with booleam "error" flag and "message" attribute with error message
 */
function init() {
    var braintreeLpmConfigs = lpmHelper.getBraintreeLpmConfigs();
    var clientToken;
    var btClientInstancePromise;
    var response = {
        error: false,
        message: '',
        btLpmModel: null
    };

    if (braintreeLpmConfigs) {
        clientToken = braintreeLpmConfigs.clientToken;
        btClientInstancePromise = braintreeGeneral.createClientInstance(clientToken);

        braintreeLpmModel.init(
            btClientInstancePromise
        );

        response.btLpmModel = braintreeLpmModel;
    } else {
        response.error = true;
        response.message = lpmErrorMessages.NO_LPM_BRAINTREE_CONFIGURATIONS;
    }

    return response;
}

module.exports = {
    init
};
