'use strict';

var helper = require('../../helper');

var $btSavePaypalAccountContainer = document.querySelector('#braintreeSavePaypalAccountContainer');
var $paypalContent = document.querySelector('.js_braintree_paypalContent');
var $btPayPalBillingButton = document.querySelector('.js_braintree_paypal_billing_button');

/**
 * Hides PayPal Smart Button functionality inside PayPal tab
 */
function hidePaypalSmartButtonFunctionality() {
    $btSavePaypalAccountContainer.style.display = 'none';
    $btPayPalBillingButton.style.display = 'none';
    $paypalContent.setAttribute('data-paypal-is-hide-continue-button', false);
}

/**
 * Shows PayPal Smart Button functionality inside PayPal tab
 */
function showPaypalSmartButtonFunctionality() {
    $btPayPalBillingButton.style.display = 'block';
    $paypalContent.setAttribute('data-paypal-is-hide-continue-button', true);
}

/**
 * Handles a behavior inside PayPal tab, when session PayPal account was deleted
 */
function handlePaypalTabBehavior() {
    var $payPalAccountList = document.querySelector('#braintreePaypalAccountsList');
    var $defaultOption = helper.getOptionByDataDefault('#braintreePaypalAccountsList');
    var $selectedOption = helper.getSelectedOption($payPalAccountList);
    var isSelectedPaypalAccount = $selectedOption.value !== 'newaccount';
    var paypalButtonConfigs = JSON.parse($btPayPalBillingButton.getAttribute('data-braintree-config'));
    var isChangePmButton = paypalButtonConfigs.changePMButtonEnabled;

    // Case when buyer selected another PM (CC,GP..)
    if ($defaultOption && !isSelectedPaypalAccount) {
        // Shows ChangePM button if buyer has already saved PP account and ChangePM button is enabled
        if (isChangePmButton) {
            $btSavePaypalAccountContainer.style.display = 'block';

            showPaypalSmartButtonFunctionality();
        // Shows default PayPal account
        } else {
            $defaultOption.selected = true;

            hidePaypalSmartButtonFunctionality();
        }
    // Case when buyer selected PayPal account from paypalAccountList
    } else if (isSelectedPaypalAccount) {
        hidePaypalSmartButtonFunctionality();
    // Case when buyer does not have saved PayPal account
    } else {
        var $btUsedPayPalAccount = document.querySelector('.form-group.braintree_used_paypal_account');

        $btUsedPayPalAccount.classList.remove('used-paypal-account');
        $btUsedPayPalAccount.classList.add('used-paypal-account-hide');

        showPaypalSmartButtonFunctionality();
    }

    [].forEach.call($payPalAccountList, function (el) {
        if (el.style.display === 'none') el.style.display = 'block';
    });
}

module.exports = {
    handlePaypalTabBehavior,
    hidePaypalSmartButtonFunctionality,
    showPaypalSmartButtonFunctionality
};
